import { useEffect, useState } from "react";
import banner from "./../../assets/images/images/eventimg1.png";
import EventArtistPreview from "./EventArtistPreview";
import EventSchedulePreview from "./EventSchedulePreview";
import EventSponsorsPreview from "./EventSponsorsPreview";
const EditEventPreview = ({
  currentUser,
  triggerSubmit,
  step,
  isSubmitForm,
  setIsSubmitForm,
  mainData,
}) => {
  const s3BucketUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const [schedules, setSchedules] = useState([]);
  const [artists, setArtists] = useState([]);
  const [contents, setContents] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [thumbnailContentImage, setThumbnailContentImage] = useState("");
  const [bannerImages, setBannerImages] = useState([]);
  useEffect(() => {
    if (mainData?.id) {
      setSchedules(mainData?.eventSchedules?.items);
      setArtists(mainData?.eventArtists?.items);
      let thumbnailContent = mainData?.eventContents?.items?.find(
        (content) => content?.isMain
      );
      setThumbnailContentImage(thumbnailContent);

      let bannerImagesData = mainData?.eventContents?.items?.filter(
        (content) => content?.isSlider
      );

      setBannerImages(bannerImagesData);
      setContents(mainData?.eventContents?.items);
      setSponsors(mainData?.eventSponsors?.items);
    }
  }, [mainData]);

  useEffect(() => {
    if (
      isSubmitForm &&
      mainData?.eventCategory === "featuredEvents" &&
      step === 5
    ) {
      triggerSubmit();
    }
    if (
      isSubmitForm &&
      mainData?.eventCategory === "happeningAround" &&
      step === 4
    ) {
      triggerSubmit();
    }
    setIsSubmitForm(false);
  }, [isSubmitForm]);

  return (
    <>
      <div className="form_box_area_main event_information_form oflow-hd">
        <div className="form_title oflow-hd">
          <h2>Preview and Submit for Review</h2>
        </div>
        <div className="Preview_content_wrapper oflow-hd">
          <div className="single_preview_section oflow-hd">
            <div className="single_preview_section_title oflow-hd">
              <h2>Event information</h2>
            </div>
            <div className="single_preview_event_information_wrapper oflow-hd">
              <div className="row">
                <div className="col-6">
                  <div className="single_preview_event_information_wrapper_single oflow-hd">
                    <h3>Event Title</h3>
                    <h4>{mainData?.title}</h4>
                  </div>
                </div>
                <div className="col-6">
                  <div className="single_preview_event_information_wrapper_single oflow-hd">
                    <h3>Event Organizer</h3>
                    <h4>{`${currentUser?.attributes?.given_name} ${currentUser?.attributes?.family_name}`}</h4>
                  </div>
                </div>
                <div className="col-6">
                  <div className="single_preview_event_information_wrapper_single oflow-hd">
                    <h3>Event Type</h3>
                    <h4>{mainData?.eventType}</h4>
                  </div>
                </div>
                <div className="col-6">
                  <div className="single_preview_event_information_wrapper_single oflow-hd">
                    <h3>Age Restriction</h3>
                    <h4>{mainData?.minAge}</h4>
                  </div>
                </div>
                <div className="col-6">
                  <div className="single_preview_event_information_wrapper_single oflow-hd">
                    <h3>Duration</h3>
                    <h4>{mainData?.eventSchedule?.length} Days | Random</h4>
                  </div>
                </div>
                <div className="col-6">
                  <div className="single_preview_event_information_wrapper_single oflow-hd">
                    <h3>For Different Days, Audience Will Need</h3>
                    {mainData?.isSameTicketForAllDay ? (
                      <h4>Same Ticket</h4>
                    ) : (
                      <h4>Different Ticket</h4>
                    )}
                  </div>
                </div>
                {mainData?.eventWillBe && (
                  <div className="col-6">
                    <div className="single_preview_event_information_wrapper_single oflow-hd">
                      <h3>Entry Will Be</h3>
                      <h4 className="text-capitalize">
                        {mainData?.eventWillBe}
                      </h4>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {schedules?.length > 0 && (
            <div className="single_preview_section oflow-hd">
              <div className="single_preview_section_title oflow-hd">
                <h2>Event Configuration</h2>
              </div>

              <EventSchedulePreview schedules={schedules} />
            </div>
          )}
          {artists?.length > 0 && schedules?.length > 0 && (
            <EventArtistPreview artists={artists} schedules={schedules} />
          )}
          <div className="single_preview_section oflow-hd">
            <div className="single_preview_section_title oflow-hd">
              <h2>Content</h2>
            </div>
            <div className="form_wrapper oflow-hd">
              <div className="row">
                <div className="col-12">
                  <div className="single_input_box">
                    <label htmlFor="#">Event Summary</label>
                    <div className="event_page_summary_des oflow-hd">
                      {mainData?.eventSummary && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: mainData?.eventSummary,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {thumbnailContentImage?.contentUrl && (
                  <div className="col-12">
                    <div className="single_input_box">
                      <label htmlFor="#">Event Thumbnail Image</label>
                    </div>
                    <div className="dash_preview_imgs oflow-hd">
                      <div className="row">
                        <div className="col-12">
                          <div className="dash_preview_img oflow-hd">
                            <img
                              src={`${s3BucketUrl}${thumbnailContentImage?.contentUrl}`}
                              alt="img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-12">
                  <div className="single_input_box">
                    <label htmlFor="#">Banner Images</label>
                  </div>
                  <div className="dash_preview_imgs oflow-hd">
                    <div className="row">
                      {bannerImages?.length > 0 &&
                        bannerImages?.map((item, index) => (
                          <div className="col-6" key={index}>
                            <div className="dash_preview_img oflow-hd">
                              <img
                                src={`${s3BucketUrl}${item?.contentUrl}`}
                                alt="img"
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                {/* {sponsors?.length > 0 && (
                  <EventSponsorsPreview sponsors={sponsors} />
                )} */}
              </div>
            </div>
          </div>
          {mainData?.eventPolicy && (
            <div className="single_preview_section oflow-hd">
              <div className="single_preview_section_title oflow-hd">
                <h2>Terms and Conditions</h2>
              </div>
              <div className="event_page_summary_des oflow-hd">
                <div
                  dangerouslySetInnerHTML={{ __html: mainData?.eventPolicy }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditEventPreview;

import { API } from "aws-amplify";
import {
  createCompanyBankInfo,
  createCompanyProfile,
  createCompanyRepresentativeInfo,
  createEventPreRegistration,
  createPickupDeliveryAddress,
  customDeleteArtist,
  customDeleteContent,
  customDeleteSchedule,
  customDeleteSponsor,
  deleteCompanyBankInfo,
  deleteEventPreRegistration,
  ticketSingle,
  updateCompanyBankInfo,
  updateCompanyProfile,
  updateCompanyRepresentativeInfo,
  updateEventPreRegistration,
  updateEventPreRegistrationStatus,
  updateHasReEntryByEventID,
  updateScheduleTime,
  updateUserById,
} from "customGraphQL/mutation";
import {
  customGetArtistsByEventId,
  customGetContentsByEventId,
  customGetEventById,
  customGetTicketsByEventId,
  getByEventIDTicketSaleMasterGuestTicket,
  getCompanyBankInfoByCompanyId,
  getCompanyInfoByUser,
  getOrganizerByUserType,
  getPreRegisteredByEventId,
  getPreRegisteredUserListByEventId,
  getPreRegisteredUsersByEventId,
  getUserByNotEqualType,
  getUserTypeByEmail,
  listEventNoAuth,
  listEventNoAuthOrganiser,
} from "customGraphQL/query";
import { ticketEventMutation, ticketTicketMutation } from "graphql/mutations";
import { getUser } from "graphql/queries";

export const GraphQLCreate = async (data) => {
  let payload = {
    mode: "CREATE",
    eventData: JSON.stringify(data),
  };
  return await API.graphql({
    query: ticketEventMutation,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: payload,
  });
};

function removeNullUndefined(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
}

export const GraphQLUpdate = async (data) => {
  let filtered = removeNullUndefined(data);

  let payload = {
    mode: "UPDATE",
    eventData: JSON.stringify(filtered),
  };
  return await API.graphql({
    query: ticketEventMutation,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: payload,
  });
};
export const GraphQLGetUserById = async (userId) => {
  return await API.graphql({
    query: getUser,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: { input: { id: userId } },
  });
};

export const GraphQLGetEventById = async (eventId) => {
  return await API.graphql({
    query: customGetEventById(eventId),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const GraphQLGetTicketsByEventId = async (eventId) => {
  return await API.graphql({
    query: customGetTicketsByEventId(eventId),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const GraphQLGetArtistListByEventId = async (eventId) => {
  return await API.graphql({
    query: customGetArtistsByEventId(eventId),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const GraphQLGetContentsByEventId = async (eventId) => {
  return await API.graphql({
    query: customGetContentsByEventId(eventId),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const GraphQLGetEventListWithStatus = async (status, userType, id) => {
  const query =
    userType === "Organizer" ? listEventNoAuthOrganiser : listEventNoAuth;

  const queryString =
    userType === "Organizer"
      ? `(organizerID: "${id}", filter: {eventStatus: {eq: "${status}"}})`
      : `(filter: {eventStatus: {eq: "${status}"}})`;

  return await API.graphql({
    query: query(queryString),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const GraphQLGetEventDelete = async (eventId) => {
  let payload = {
    mode: "DELETE",
    eventData: JSON.stringify({ eventID: eventId }),
  };
  return await API.graphql({
    query: ticketEventMutation,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: payload,
  });
};

export const GraphQLArtistDelete = async (artistId) => {
  return await API.graphql({
    query: customDeleteArtist(artistId),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const GraphQLSponsorDelete = async (sponsorId) => {
  return await API.graphql({
    query: customDeleteSponsor(sponsorId),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const GraphQLContentDelete = async (contentId) => {
  return await API.graphql({
    query: customDeleteContent(contentId),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const GraphQLScheduleDelete = async (scheduleId) => {
  return await API.graphql({
    query: customDeleteSchedule(scheduleId),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const ticketDetails = async (limit, eventID, userQuery, nextToken) => {
  return await API.graphql({
    query: ticketSingle(limit, eventID, userQuery, nextToken),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const userDetailsByEmail = async (emaildID) => {
  return await API.graphql({
    query: getUserTypeByEmail(emaildID),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const GraphQLGetTicketByEventId = async (eventID, userID, ticket) => {
  let payload = {
    mode: "GET",
    ticketData: JSON.stringify({
      eventID: eventID,
      userID: userID,
      ticket: ticket,
    }),
  };
  return await API.graphql({
    query: ticketTicketMutation,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: payload,
  });
};

export const updateScheduleTimeByScheduleID = async (
  scheduleID,
  openTime,
  closeTime
) => {
  return await API.graphql({
    query: updateScheduleTime(scheduleID, openTime, closeTime),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const updateEventReEntryByEventID = async (eventID, hasReEntry) => {
  return await API.graphql({
    query: updateHasReEntryByEventID(eventID, hasReEntry),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const getUserByNotEqualTypeService = async (user, organizer) => {
  return await API.graphql({
    query: getUserByNotEqualType(user, organizer),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const getOrganizerByUserTypeService = async (userType, nextToken) => {
  return await API.graphql({
    query: getOrganizerByUserType(userType, nextToken),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const updateUserByUserId = async (userId, userType, isApproved) => {
  return await API.graphql({
    query: updateUserById(userId, userType, isApproved),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const createCompanyProfileService = async (
  userId,
  companyName,
  companyImageUrl,
  companyEmail,
  companyAddress,
  companyPhoneNumber,
  companyTradeLicenseNo,
  tradeLicenseUrl,
  companyTinNo,
  tinUrl,
  companyBinNo,
  binUrl
) => {
  return await API.graphql({
    query: createCompanyProfile(
      userId,
      companyName,
      companyImageUrl,
      companyEmail,
      companyAddress,
      companyPhoneNumber,
      companyTradeLicenseNo,
      tradeLicenseUrl,
      companyTinNo,
      tinUrl,
      companyBinNo,
      binUrl
    ),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const updateCompanyProfileService = async (
  id,
  companyName,
  companyImageUrl,
  companyEmail,
  companyAddress,
  companyPhoneNumber,
  companyTradeLicenseNo,
  tradeLicenseUrl,
  companyTinNo,
  tinUrl,
  companyBinNo,
  binUrl
) => {
  return await API.graphql({
    query: updateCompanyProfile(
      id,
      companyName,
      companyImageUrl,
      companyEmail,
      companyAddress,
      companyPhoneNumber,
      companyTradeLicenseNo,
      tradeLicenseUrl,
      companyTinNo,
      tinUrl,
      companyBinNo,
      binUrl
    ),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const getCompanyInfoByUserService = async (userID) => {
  return await API.graphql({
    query: getCompanyInfoByUser(userID),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const createCompanyBankInfoService = async (
  userID,
  accountName,
  accountNumber,
  bankName,
  branchName,
  companyID,
  swiftCode
) => {
  return await API.graphql({
    query: createCompanyBankInfo(
      userID,
      accountName,
      accountNumber,
      bankName,
      branchName,
      companyID,
      swiftCode
    ),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const updateCompanyBankInfoService = async (
  id,
  accountName,
  accountNumber,
  bankName,
  branchName,
  swiftCode
) => {
  return await API.graphql({
    query: updateCompanyBankInfo(
      id,
      accountName,
      accountNumber,
      bankName,
      branchName,
      swiftCode
    ),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const createCompanyRepresentativeInfoService = async (
  userID,
  companyID,
  representativeEmail,
  representativeName,
  representativeOrganisation,
  representativePhoneNumber
) => {
  return await API.graphql({
    query: createCompanyRepresentativeInfo(
      userID,
      companyID,
      representativeEmail,
      representativeName,
      representativeOrganisation,
      representativePhoneNumber
    ),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const updateCompanyRepresentativeInfoService = async (
  id,
  representativeEmail,
  representativeName,
  representativeOrganisation,
  representativePhoneNumber
) => {
  return await API.graphql({
    query: updateCompanyRepresentativeInfo(
      id,
      representativeEmail,
      representativeName,
      representativeOrganisation,
      representativePhoneNumber
    ),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const getCompanyBankInfoByCompanyIdService = async (companyID) => {
  return await API.graphql({
    query: getCompanyBankInfoByCompanyId(companyID),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const deleteCompanyBankInfoService = async (id) => {
  return await API.graphql({
    query: deleteCompanyBankInfo(id),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const createPickupDeliveryAddressService = async (eventID, address) => {
  return await API.graphql({
    query: createPickupDeliveryAddress(eventID, address),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const getPreRegisteredUserListByEventIdService = async (eventID) => {
  return await API.graphql({
    query: getPreRegisteredUserListByEventId(eventID),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const createEventPreRegistrationService = async (
  eventID,
  audienceInfo,
  discountAmount,
  exclusiveTicket,
  exclusiveTicketDuration,
  hasDiscount,
  isPercent,
  preRegistrationEndsAt,
  preRegistrationStartAt,
  status
) => {
  return await API.graphql({
    query: createEventPreRegistration(
      eventID,
      audienceInfo,
      discountAmount,
      exclusiveTicket,
      exclusiveTicketDuration,
      hasDiscount,
      isPercent,
      preRegistrationEndsAt,
      preRegistrationStartAt,
      status
    ),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const updateEventPreRegistrationService = async (
  id,
  audienceInfo,
  discountAmount,
  exclusiveTicket,
  exclusiveTicketDuration,
  hasDiscount,
  isPercent,
  preRegistrationEndsAt,
  preRegistrationStartAt,
  status
) => {
  return await API.graphql({
    query: updateEventPreRegistration(
      id,
      audienceInfo,
      discountAmount,
      exclusiveTicket,
      exclusiveTicketDuration,
      hasDiscount,
      isPercent,
      preRegistrationEndsAt,
      preRegistrationStartAt,
      status
    ),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const updateEventPreRegistrationStatusService = async (id, status) => {
  return await API.graphql({
    query: updateEventPreRegistrationStatus(id, status),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const getByEventIDPreRegisterService = async (eventID) => {
  return await API.graphql({
    query: getPreRegisteredByEventId(eventID),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const getPreRegisteredUsersByEventIdService = async (eventID) => {
  return await API.graphql({
    query: getPreRegisteredUsersByEventId(eventID),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};
export const deleteEventPreRegistrationService = async (id) => {
  return await API.graphql({
    query: deleteEventPreRegistration(id),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

export const getByEventIDTicketSaleMasterGuestTicketService = async (
  eventID
) => {
  return await API.graphql({
    query: getByEventIDTicketSaleMasterGuestTicket(eventID),
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

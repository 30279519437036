import React from "react";
import EventArtistScheduleSingleItem from "./EventArtistScheduleSingleItem";

const EventArtistPreview = ({ artists, schedules, mainData }) => {
  return (
    <>
      <div className="single_input_box">
        <label htmlFor="#">Artists</label>
      </div>
      <div className="artst_table artst_table2 pb-5">
        {artists.length > 0 ? (
          schedules?.length > 0 &&
          schedules?.map((schedule, index) => (
            <div className="artst_table2_single" key={index}>
              <h2>Day {index + 1}</h2>
              <div className="dash_main_table_wrapper">
                <div className="dash_main_main_table oflow-hd">
                  <EventArtistScheduleSingleItem
                    schedule={schedule}
                    artists={artists}
                    mainData={mainData}
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No artist added yet</p>
        )}
      </div>
    </>
  );
};

export default EventArtistPreview;

import React, { useEffect, useState } from "react";

const EventArtistScheduleSingleItem = ({ schedule, artists, mainData }) => {
  const s3BucketUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const [scheduleArtists, setScheduleArtists] = useState([]);
  useEffect(() => {
    if (schedule.id) {
      let newArray = artists?.filter(
        (artist) => artist.eventScheduleID === schedule.id
      );
      setScheduleArtists(newArray);
    }
  }, [schedule]);
  return (
    <table>
      <thead>
        <tr>
          <th>Avatar</th>
          <th>Name</th>
          <th>Bio</th>
          <th>Profession</th>
          {/* <th>Organization</th> */}
          <th>Url</th>
        </tr>
      </thead>
      <tbody>
        {mainData?.isSameArtist == true
          ? artists.map((artist, index) => (
              <tr key={index}>
                <td>
                  <img
                    src={`${s3BucketUrl}${artist?.artistImageUrl}`}
                    alt="img"
                  />
                </td>
                <td>
                  <span>{artist?.artistName}</span>
                </td>
                <td>
                  <p>{artist?.artistBio}</p>
                </td>
                <td>{artist?.artistTitle}</td>
                <td>{artist?.artistUrl}</td>
              </tr>
            ))
          : scheduleArtists.length > 0 &&
            scheduleArtists.map((artist, index) => (
              <tr key={index}>
                <td>
                  {artist?.artistImageUrl && (
                    <img
                      src={`${s3BucketUrl}${artist?.artistImageUrl}`}
                      alt="img"
                    />
                  )}
                </td>
                <td>
                  <span>{artist?.artistName}</span>
                </td>
                <td>
                  <p>{artist?.artistBio}</p>
                </td>
                <td>{artist?.artistTitle}</td>
                <td>{artist?.artistUrl}</td>
              </tr>
            ))}
      </tbody>
    </table>
  );
};

export default EventArtistScheduleSingleItem;

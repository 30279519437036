// // third-party
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

// project import
import reducers from './slices';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

const persister = persistStore(store);

const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export {  dispatch, persister, useSelector, useDispatch };

export default store


// import { combineReducers, configureStore } from "@reduxjs/toolkit";
// // import { useDispatch } from "react-redux";
// import { persistReducer, persistStore } from 'redux-persist';
// import createWebStorage from "redux-persist/lib/storage/createWebStorage";
// import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
//
// const createNoopStorage = () => {
//   return {
//     getItem(_key) {
//       return Promise.resolve(null);
//     },
//     setItem(_key, value) {
//       return Promise.resolve(value);
//     },
//     removeItem(_key) {
//       return Promise.resolve();
//     },
//   };
// };
//
// const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();
//
// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['cumulativePrice', 'selectedTickets', 'auth']
// }
//
// const rootReducer = combineReducers({
//   // auth: authReducer,
//   // ticket: ticketReducer,
//   // event: eventReducer,
//   // eventSchedule: eventScheduleReducer,
//   // cumulativePrice: cumulativePriceReducer,
//   // selectedTickets: selectedTicketsReducer,
// })
//
// const persistedReducer = persistReducer(persistConfig, rootReducer)
//
// const store = configureStore({
//   reducer: persistedReducer
// })
//
//  const { dispatch } = store;
//  const persister = persistStore(store);
//
//  const useDispatch = () => useAppDispatch();
//  const useSelector = useAppSelector;
// export { dispatch, persister, useSelector, useDispatch };
//
// export default store;
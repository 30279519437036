import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;

const CustomMapBox = ({
  width,
  height,
  scheduleLatitude,
  scheduleLongitude,
  setScheduleLatitude,
  setScheduleLongitude,
}) => {
  const [markerPosition, setMarkerPosition] = useState([
    scheduleLatitude,
    scheduleLongitude,
  ]); // Initial marker position

  const handleMarkerDragEnd = (e) => {
    const marker = e.target;
    const position = marker.getLatLng(); // Get marker's current position
    setScheduleLatitude(position.lat);
    setScheduleLongitude(position.lng);
    setMarkerPosition([position.lat, position.lng]); // Update state with new position
  };

  return (
    <>
      <MapContainer
        center={[scheduleLatitude, scheduleLongitude]}
        zoom={10}
        style={{ height: height, width: width - 50 }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker
          position={markerPosition}
          draggable={true}
          eventHandlers={{ moveend: handleMarkerDragEnd }}
        >
          <Popup>
            Marker position: <br /> Latitude: {markerPosition[0]} <br />{" "}
            Longitude: {markerPosition[1]}
          </Popup>
        </Marker>
      </MapContainer>
    </>
  );
};

export default CustomMapBox;

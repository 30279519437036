import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import EventConfigMultipleDaySingle from "./EventConfigMultipleDaySingle";
import { GraphQLScheduleDelete } from "services/api-call";
import { sortDates } from "services/sortDate";

const EventConfigMulitpleDays = ({
  numberOfDays,
  isEditing,
  handleEditToggle,
  schedules,
  setSchedules,
  isSubmitForm,
  setIsSubmitForm,
}) => {
  const deleteSchedule = (scheduleId) => {
    if (scheduleId) {
      GraphQLScheduleDelete(scheduleId)
        .then((data) => data)
        .catch((err) => {
          console.error("delete schedule error: ", err);
        });
    }
    let newArray = schedules?.filter(
      (schedule, index) => schedule.id !== scheduleId
    );
  };
  return (
    <>
      <div className="single_input_box">
        <label htmlFor="#">Date and Time</label>
        <div className="multifieldrow_wrapper ec_daydatetimebox">
          {schedules.map((schedule, index) => (
            <div key={index} className="multifieldrow_wrapper_single">
              <div className="row multifieldrow">
                {schedules.length > 2 ? (
                  <div className="col-md-2">
                    <div className="single_input_box single_input_box_canedit">
                      <label htmlFor="#">Title</label>
                      <input
                        className={`canedit  disabled`}
                        type="text"
                        value={`Day ${index + 1}`}
                        readOnly
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4">
                    <div className="single_input_box single_input_box_canedit">
                      <label htmlFor="#">Title</label>
                      <input
                        className={`canedit  disabled`}
                        type="text"
                        value={`Day ${index + 1}`}
                        readOnly
                      />
                    </div>
                  </div>
                )}
                {schedules.length > 0 && (
                  <EventConfigMultipleDaySingle
                    schedule={schedule}
                    schedules={schedules}
                    setSchedules={setSchedules}
                    isSubmitForm={isSubmitForm}
                    setIsSubmitForm={setIsSubmitForm}
                  />
                )}
                {schedules.length > 2 && (
                  <div className="col-md-2" style={{ marginTop: "33px" }}>
                    <Link
                      to=""
                      className="btn btn-danger py-2"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteSchedule(schedule.id);
                      }}
                    >
                      Remove This
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EventConfigMulitpleDays;

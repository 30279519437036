import moment from "moment";
import React, { useEffect, useState } from "react";

const EventConfigMultipleDaySingle = ({
  schedule,
  schedules,
  setSchedules,
  isSubmitForm,
  setIsSubmitForm,
}) => {
  const [date, setDate] = useState(
    moment(schedule.eventDate).format("YYYY-MM-DD")
  );
  const [time, setTime] = useState(
    moment(schedule.eventStartTime, "HH:mm").format("HH:mm")
  );
  const handleChangeData = (schedule) => {
    let newArray = schedules.map((item) => {
      if (item.id === schedule.id) return schedule;
      else return item;
    });
    setSchedules(newArray);
  };

  return (
    <>
      <div className="col-md-4">
        <div className="single_input_box">
          <label htmlFor="#">Date</label>
          <input
            type="date"
            defaultValue={date}
            min={moment(new Date()).format("YYYY-MM-DD")}
            onChange={(event) => {
              setDate(e.target.value);
              let newData = { ...schedule, eventDate: event.target.value };
              handleChangeData(newData);
            }}
            placeholder="Date"
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="single_input_box">
          <label htmlFor="#">Start Time</label>
          <input
            type="time"
            defaultValue={time}
            min={moment(new Date()).format("hh:mm")}
            placeholder="Time"
            onChange={(event) => {
              setTime(event.target.value);
              let newData = { ...schedule, eventStartTime: event.target.value };
              handleChangeData(newData);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EventConfigMultipleDaySingle;

import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  info: null,
  bankAccountDetails: {
    list: [],
    selected: null,
  },
  companyRepresentative: null,
};

const companyInfo = createSlice({
  name: "companyInfo",
  initialState,
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
    },
    setBankAccountDetails: (state, action) => {
      state.bankAccountDetails = action.payload;
    },
    setCompanyRepresentative: (state, action) => {
      state.companyRepresentative = action.payload;
    },
  },
});

export default companyInfo.reducer;

export const { setInfo, setBankAccountDetails, setCompanyRepresentative } =
  companyInfo.actions;

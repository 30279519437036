import moment from "moment";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import ArtitstList from "./ArtitstList";
import Button from "@mui/material/Button";
import CustomModal from "components/shared/CustomModal";

const EventConfigFirstStep = ({
  setIsSubmitForm,
  isSubmitForm,
  step,
  triggerSubmit,
  schedule,
  schedules,
  setSchedules,
  mainData,
  setMainData,
  artistList,
  setArtistList,
  artistImages,
  setArtistImages,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [time, setTime] = useState(moment(new Date()).format("hh:mm"));
  const [address, setAddress] = useState(schedule.address);
  const [venue, setVenue] = useState(schedule.venue);
  const [city, setCity] = useState(schedule.eventCity);
  const [latitude, setLatitude] = useState(
    schedule?.location?.lat || 23.804193153061725
  );
  const [longitude, setLongitude] = useState(
    schedule?.location?.lon || 90.36392211914062
  );
  const [openModal, setOpenModal] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  useEffect(() => {
    if (isSubmitForm) {
      if (!address || !venue || !city) {
        setCustomErrors({
          ...customErrors,
          // latitude: !latitude ? "Latitude is required" : "",
          // longitude: !longitude ? "Longitude is required" : "",
          address: !address ? "Address is required" : "",
          venue: !venue ? "Venue is required" : "",
          city: !city ? "City is required" : "",
        });
        setIsSubmitForm(false);
      }
    }
  }, [isSubmitForm]);

  useEffect(() => {
    let newDate = moment(schedule.eventDate).format("YYYY-MM-DD");
    setDate(newDate);
    let newTime = moment(schedule.eventStartTime, "hh:mm").format("HH:mm");
    setTime(newTime);
  }, [schedule]);

  const handleChangeData = (scheduleId, dataObj) => {
    const newArray = schedules.map((item) => {
      if (item.id === scheduleId) {
        return {
          ...item,
          ...dataObj,
          location: {
            ...item.location,
            ...dataObj.location,
          },
        };
      }
      return item;
    });
    setSchedules(newArray);
  };

  useEffect(() => {
    if (latitude) {
      handleChangeData(schedule.id, {
        location: { lat: latitude },
      });
      setCustomErrors({ ...errors, latitude: null });
    }
  }, [latitude]);

  useEffect(() => {
    if (longitude) {
      handleChangeData(schedule.id, {
        location: { lon: longitude },
      });
      setCustomErrors({ ...errors, longitude: null });
    }
  }, [longitude]);
  return (
    <>
      <div className="form_box_area_main event_information_form oflow-hd">
        <div className="form_title oflow-hd">
          <h2>Event Configuration</h2>
        </div>
        <div className="form_wrapper">
          <div className="row mb-5">
            <div className="col-12">
              <div className="single_input_box">
                <label htmlFor="#">Date and Time</label>
                <div className="multifieldrow_wrapper">
                  <div className="multifieldrow_wrapper_single">
                    <div className="row multifieldrow">
                      <div className="col-md-6">
                        <div className="single_input_box">
                          <input
                            type="date"
                            value={date}
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            {...register("eventConfigDate")}
                            onChange={(event) => {
                              setDate(event.target.value);
                              handleChangeData(schedule.id, {
                                eventDate: event.target.value,
                              });
                            }}
                            placeholder="Date"
                          />
                          {errors.eventConfigDate && (
                            <p className="text-danger">
                              {errors.eventConfigDate.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single_input_box">
                          <input
                            type="time"
                            value={time}
                            min={moment(new Date()).format("hh:mm")}
                            {...register("eventConfigTime")}
                            placeholder="Time"
                            onChange={(event) => {
                              setTime(event.target.value);
                              handleChangeData(schedule.id, {
                                eventStartTime: event.target.value,
                              });
                            }}
                          />
                          {errors.eventConfigTime && (
                            <p className="text-danger">
                              {errors.eventConfigTime.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="multifieldrow_wrapper">
              <div
                className="col-12"
                style={{ padding: 0, margin: "8px auto" }}
              >
                <div className="single_input_box">
                  <label htmlFor="#">
                    Latitude and Longitude{" "}
                    <Button variant="text" onClick={() => setOpenModal(true)}>
                      Click here to get the lat, long
                    </Button>
                  </label>
                  <div className="multifieldrow_wrapper_single">
                    <div className="row multifieldrow">
                      <div className="col-md-6">
                        <div className="single_input_box">
                          <input
                            type="number"
                            value={latitude}
                            {...register("eventConfigLatitude")}
                            placeholder="Latitude"
                            onChange={(event) => {
                              setLatitude(event.target.value);
                              handleChangeData(schedule.id, {
                                // location: { lat: { N: event.target.value } },
                                location: { lat: event.target.value },
                              });
                              setCustomErrors({
                                ...customErrors,
                                latitude: "",
                              });
                            }}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single_input_box">
                          <input
                            type="number"
                            value={longitude}
                            {...register("eventConfigLongitude")}
                            onChange={(event) => {
                              setLongitude(event.target.value);
                              handleChangeData(schedule.id, {
                                // location: { lon: { N: event.target.value } },
                                location: { lon: event.target.value },
                              });
                              setCustomErrors({
                                ...customErrors,
                                longitude: "",
                              });
                            }}
                            placeholder="Longitude"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="single_input_box">
                <label htmlFor="#">Address and Venue</label>
                <div className="multifieldrow_wrapper">
                  <div className="multifieldrow_wrapper_single">
                    <div className="row multifieldrow">
                      <div className="col-md-6">
                        <div className="single_input_box">
                          <input
                            type="text"
                            value={address}
                            {...register("eventConfigAddress", {
                              required: {
                                value: true,
                                message: "Event Address is required",
                              },
                            })}
                            onChange={(event) => {
                              setAddress(event.target.value);
                              handleChangeData(schedule.id, {
                                address: event.target.value,
                              });
                              setCustomErrors({
                                ...customErrors,
                                address: "",
                              });
                            }}
                            placeholder="Address"
                          />
                          {customErrors.address && (
                            <p className="text-danger">
                              {customErrors.address}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single_input_box">
                          <input
                            type="text"
                            value={venue}
                            {...register("eventConfigVenue", {
                              required: {
                                value: true,
                                message: "Event Address is required",
                              },
                            })}
                            onChange={(event) => {
                              setVenue(event.target.value);
                              handleChangeData(schedule.id, {
                                venue: event.target.value,
                              });
                              setCustomErrors({
                                ...customErrors,
                                venue: "",
                              });
                            }}
                            placeholder="Venue"
                          />
                          {customErrors.venue && (
                            <p className="text-danger">{customErrors.venue}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="single_input_box">
                <label htmlFor="#">Event City</label>
                <div className="multifieldrow_wrapper">
                  <div className="multifieldrow_wrapper_single">
                    <div className="row multifieldrow">
                      <div className="col-md-6">
                        <div className="single_input_box">
                          <input
                            type="text"
                            value={city}
                            {...register("eventConfigCity", {
                              required: {
                                value: true,
                                message: "Event City is required",
                              },
                            })}
                            onChange={(event) => {
                              setCity(event.target.value);
                              handleChangeData(schedule.id, {
                                eventCity: event.target.value,
                              });
                              setCustomErrors({
                                ...customErrors,
                                city: "",
                              });
                            }}
                            placeholder="City"
                          />
                          {customErrors.city && (
                            <p className="text-danger">{customErrors.city}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ArtitstList
            setIsSubmitForm={setIsSubmitForm}
            schedule={schedule}
            setSchedules={setSchedules}
            mainData={mainData}
            artistList={artistList}
            setArtistList={setArtistList}
            artistImages={artistImages}
            setArtistImages={setArtistImages}
          />
        </div>
      </div>
      <CustomModal
        open={openModal}
        setOpen={setOpenModal}
        scheduleLatitude={latitude}
        scheduleLongitude={longitude}
        setScheduleLatitude={setLatitude}
        setScheduleLongitude={setLongitude}
      />
    </>
  );
};

export default EventConfigFirstStep;

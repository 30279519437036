import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AmplifyImageUpload } from "services/amplify-image-upload";
import {
  GraphQLCreate,
  GraphQLGetContentsByEventId,
  GraphQLGetEventById,
  GraphQLUpdate,
} from "services/api-call";
import EventEditConfig from "./EventEditConfig";
import EventEditInfo from "./EventEditInfo";
import EventPreview from "./EventPreview";
import EventTermsCondition from "./EventTermsCondition";
import EventEditContent from "./EventEditContent";
import EventConfig from "./EventConfig";
import EditEventPreview from "./EditEventPreview";
import EventContent from "./EventContent";
import { useDispatch, useSelector } from "react-redux";
import { setEventSchedules } from "store/slices/eventData";
import { sortDates } from "services/sortDate";

const EditEvent = () => {
  const [selectedValue, setSelectedValue] = useState("1");
  const [artistList, setArtistList] = useState([]);
  const [step, setStep] = useState(1);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [contentImageDatas, setContentImageDatas] = useState([]);
  const [contentImages, setContentImages] = useState([]);
  const [artistImages, setArtistImages] = useState([]);
  const [isSameArtistForMultSchedule, setIsSameArtistForMultSchedule] =
    useState("YesSame");
  const [thumbnailContent, setThumbnailContent] = useState({
    eventID: "",
    contentType: "image",
    contentUrl: "",
    isSlider: false,
    isMain: true,
    isActive: true,
  });
  const [eventSponsors, setEventSponsors] = useState([]);
  const [sponsorsImages, setSponsorsImages] = useState([]);
  const [sameScheduleData, setSameScheduleData] = useState({});
  const [isMultiEventVenueEmpty, setIsMultiEventVenueEmpty] = useState(false);

  const [mainData, setMainData] = useState({
    eventCategory: "",
    title: "",
    createdByID: "",
    eventType: "",
    organizerID: "",
    isUpComing: true,
    isSameTicketForAllDay: true,
    isSamePromotionForAllDay: true,
    isSameCouponForAllDay: true,
    minAge: 0,
    eventWillBe: "paid",
    eventStatus: "draft",
    eventPhoneNo: "",
    eventSummery: "",
    eventPolicy: "",
    facebookUrl: "",
    instaUrl: "",
    tweeterUrl: "",
    linkedinUrl: "",
    totalInterested: 0,
    maxTicketByUser: 0,
    maxTicketInSingleCheckout: 0,
    isActive: false,
    isLocationVenueSame: true,
    isSameArtist: true,
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(async () => {
    if (!currentUser) {
      const user = await Auth.currentAuthenticatedUser();
      setMainData((mainData) => ({
        ...mainData,
        ...{
          createdByID: user.username,
          organizerID: user.username,
          owner: user.username,
        },
      }));
      setCurrentUser(user);
    }
  }, []);

  useEffect(() => {
    if (id) {
      GraphQLGetEventById(id)
        .then((event) => {
          let eventData = event?.data?.getEvent;
          let newMainData = { ...mainData, ...eventData };
          let hasThumbnailContent = eventData?.eventContents?.items?.find(
            (item) => item.isMain === true
          );
          if (hasThumbnailContent) {
            setThumbnailContent(hasThumbnailContent);
          }
          setContentImages(eventData?.eventContents?.items);
          setMainData(newMainData);
          let newData = sortDates(newMainData?.eventSchedules?.items);
          setSchedules(newData);
          setSelectedValue(newMainData?.eventSchedules?.items.length || 1);
          if (mainData.isLocationVenueSame === true) {
            setSameScheduleData(newMainData?.eventSchedules?.items[0]);
          }
          setEventSponsors(newMainData?.eventSponsors?.items);
          setArtistList(newMainData?.eventArtists?.items);
        })
        .catch((error) => {
          // navigate("/create-draft", { replace: true });
        });
    }
  }, [id]);

  const methods = useForm();

  const callAPi = async (newData) => {
    return await GraphQLCreate(newData);
  };

  const callUpdateAPi = async (newData) => {
    return await GraphQLUpdate(newData);
  };

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
    methods.setValue("eventDuration", event.target.value);
  };

  const imageUploadHandler = async (folderName, imageName, imageData) => {
    let ImageData = imageData.find((item) => item?.name === imageName);
    if (ImageData) {
      let newImageData = await AmplifyImageUpload(
        folderName,
        imageName,
        ImageData?.file
      );
      return newImageData;
    }
  };

  let newLocation = useSelector((state) => state.eventData.singleLocation);

  const triggerSubmit = async (triggerData) => {
    await methods.handleSubmit((data) => {
      if (step === 1) {
        let newData = {
          id: mainData.id,
          title: data.eventTitle,
          eventWillBe: data.entryFee,
          minAge: parseInt(data.ageRestriction),
          eventType: data.eventType,
        };
        callUpdateAPi(newData)
          .then((response) => {
            response = JSON.parse(response?.data?.ticketEventMutation);
            if (response.statusCode === 200) {
              newData = response.body;
              setMainData((prevData) => ({ ...prevData, ...newData }));
              setStep(step + 1);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (step === 2) {
        let newSchedules = schedules;

        let isVenueEmpty = false;
        if (mainData.isLocationVenueSame && schedules?.length > 1) {
          if (
            !sameScheduleData.address ||
            !sameScheduleData.venue ||
            !sameScheduleData.eventCity
          ) {
            isVenueEmpty = true;
          } else {
            newSchedules = newSchedules?.map((schedule) => {
              schedule.address = sameScheduleData?.address;
              schedule.venue = sameScheduleData.venue;
              schedule.eventCity = sameScheduleData.eventCity;
              schedule.location = newLocation;
              return schedule;
            });
          }
        } else {
          schedules.forEach((schedule) => {
            if (!schedule.address || !schedule.venue || !schedule.eventCity) {
              isVenueEmpty = true;
            }
          });
        }
        if (isVenueEmpty) return;
        let newArtistList = artistList;
        if (mainData.isSameArtist === true) {
          newArtistList = newArtistList.map((artist) => {
            artist.eventScheduleID = schedules[0]?.id;
            return artist;
          });
        }
        let newArtistListPromises = newArtistList.map(async (artist) => {
          if (artist?.artistImageUrl?.includes("public/")) {
            return artist;
          }
          return imageUploadHandler(
            "artists",
            artist?.artistImageUrl,
            artistImages
          )
            .then((response) => {
              return { ...artist, artistImageUrl: response };
            })
            .catch((err) => console.error("err: ", err));
        });

        Promise.all(newArtistListPromises)
          .then((newArtistList) => {
            let newData = {
              id: mainData.id,
              eventSchedule: newSchedules,
              eventArtist: newArtistList,
              isLocationVenueSame: mainData.isLocationVenueSame,
              isSameArtist: mainData.isSameArtist,
            };
            callUpdateAPi(newData)
              .then((response) => {
                response = JSON.parse(response?.data?.ticketEventMutation);
                if (response.statusCode === 200) {
                  newData = { ...newData, ...response.body };
                  setMainData((prevData) => ({ ...prevData, ...newData }));
                  setArtistList(response.body.eventArtist);
                  setSchedules(response.body.eventSchedule);
                  setStep(step + 1);
                }
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => console.error("error: ", error));
      } else if (step === 3) {
        let newContentImages = contentImages?.length > 0 ? contentImages : [];
        let newThumbnailContent;
        if (thumbnailContent?.contentUrl) {
          newThumbnailContent = {
            ...thumbnailContent,
            eventID: mainData.id,
            contentType: "image",
            isMain: true,
            isSlider: false,
            isActive: true,
          };
        }
        let contentList = [...newContentImages, newThumbnailContent];
        contentList = contentList.filter((item) => item?.contentUrl);
        contentList = contentList.filter((content) => content?.contentUrl);
        let newContentListPromises = contentList?.map(async (content) => {
          return imageUploadHandler(
            "event",
            content?.contentUrl,
            contentImageDatas
          )
            .then((response) => {
              if (response) {
                let newContent = {
                  ...content,
                  contentUrl: response,
                };
                return newContent;
              }
            })
            .catch((err) => console.error("err: ", err));
        });

        Promise.all(newContentListPromises)
          .then((newContentList) => {
            let newSponsorListPromises = eventSponsors.map(async (sponsor) => {
              return imageUploadHandler(
                "sponsor",
                sponsor?.sponsorImageUrl,
                sponsorsImages
              )
                .then((response) => {
                  let newSponsor = {
                    ...sponsor,
                    sponsorImageUrl: response,
                  };
                  return newSponsor;
                })
                .catch((err) => console.error("err: ", err));
            });

            Promise.all(newSponsorListPromises)
              .then((newSponsorList) => {
                newContentList = newContentList.filter((item) => {
                  if (item?.contentUrl) return item;
                });
                let newData = {
                  id: mainData.id,
                  eventContent: newContentList,
                  eventSponsor: newSponsorList,
                  eventSummery: mainData.eventSummery,
                };
                callUpdateAPi(newData)
                  .then((response) => {
                    response = JSON.parse(response?.data?.ticketEventMutation);
                    if (response.statusCode === 200) {
                      newData = { ...newData, ...response.body };
                      setMainData((prevData) => ({ ...prevData, ...newData }));
                      GraphQLGetContentsByEventId(mainData.id).then((data) => {
                        let newContentData =
                          data?.data?.byEventIDEventContent?.items;
                        let newThumbnailImage = newContentData.find(
                          (item) => item.isMain
                        );
                        if (newThumbnailImage) {
                          setThumbnailContent(newThumbnailImage);
                        }
                        let newContentImages = newContentData.filter(
                          (item) => item.isSlider
                        );
                        setContentImages(newContentData);
                      });

                      // setContentList(response?.body?.eventContent);
                      setEventSponsors(response?.body?.eventSponsor);
                      if (mainData?.eventCategory === "featuredEvents") {
                        setStep(step + 1);
                      } else {
                        setStep(step + 2);
                      }
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              })
              .catch((error) => console.error("error: ", error));
          })
          .catch((error) => console.error("error: ", error));
      } else if (step === 4) {
        let newData = {
          id: mainData.id,
          eventPolicy: mainData.eventPolicy,
        };
        callUpdateAPi(newData)
          .then((response) => {
            response = JSON.parse(response?.data?.ticketEventMutation);
            if (response.statusCode === 200) {
              newData = { ...newData, ...response.body };
              setMainData((prevData) => ({ ...prevData, ...newData }));
              setStep(step + 1);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    })();
  };

  const updateEventToReview = () => {
    let newData = {
      id: mainData.id,
      eventStatus: "inReview",
    };
    callUpdateAPi(newData)
      .then((response) => {
        response = JSON.parse(response?.data?.ticketEventMutation);
        if (response.statusCode === 200) {
          newData = { ...newData, ...response.body };
          setMainData((prevData) => ({ ...prevData, ...newData }));
          navigate("/create-draft");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {mainData?.id && (
        <FormProvider {...methods}>
          <div className="dash_content_main oflow-hd createEvent">
            <div className="dash_content_main_head oflow-hd">
              <h2>
                Update Event on{" "}
                {mainData.eventCategory === "featuredEvents"
                  ? "Featured"
                  : "Happening Around"}
              </h2>
            </div>
            <div className="dash_content_main_center oflow-hd">
              <div className="row">
                <div className="col-md-5">
                  <div className="form_left_col oflow-hd">
                    <div className="form_left_col_des oflow-hd">
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Accumsan nulla
                        vitae et pulvinar viverra ornare vitae. Gravida aliquet
                        iaculis
                      </p>
                    </div>
                    <div className="form_left_col_progress oflow-hd">
                      <ul>
                        <li
                          className={
                            (step === 1 && "current") ||
                            (step >= 1 && "checked")
                          }
                        >
                          <Link to="">
                            <div>
                              <span>01</span>
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.82918 8.72843C3.73373 8.82445 3.60348 8.87802 3.4682 8.87802C3.33293 8.87802 3.20268 8.82445 3.10723 8.72843L0.224383 5.84511C-0.0747942 5.54593 -0.0747942 5.06079 0.224383 4.76218L0.585358 4.40111C0.88463 4.10194 1.3692 4.10194 1.66838 4.40111L3.46823 6.20103L8.33157 1.33754C8.63085 1.03836 9.1159 1.03836 9.41459 1.33754L9.77557 1.69861C10.0747 1.99779 10.0747 2.48284 9.77557 2.78154L3.82918 8.72843Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <p>Event Information</p>
                          </Link>
                        </li>
                        <li
                          className={
                            (step === 2 && "current") ||
                            (step >= 2 && "checked")
                          }
                        >
                          <Link to="">
                            <div>
                              <span>02</span>
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.82918 8.72843C3.73373 8.82445 3.60348 8.87802 3.4682 8.87802C3.33293 8.87802 3.20268 8.82445 3.10723 8.72843L0.224383 5.84511C-0.0747942 5.54593 -0.0747942 5.06079 0.224383 4.76218L0.585358 4.40111C0.88463 4.10194 1.3692 4.10194 1.66838 4.40111L3.46823 6.20103L8.33157 1.33754C8.63085 1.03836 9.1159 1.03836 9.41459 1.33754L9.77557 1.69861C10.0747 1.99779 10.0747 2.48284 9.77557 2.78154L3.82918 8.72843Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <p>Event Configuration</p>
                          </Link>
                        </li>
                        <li
                          className={
                            (step === 3 && "current") ||
                            (step >= 3 && "checked")
                          }
                        >
                          <Link to="">
                            <div>
                              <span>03</span>
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.82918 8.72843C3.73373 8.82445 3.60348 8.87802 3.4682 8.87802C3.33293 8.87802 3.20268 8.82445 3.10723 8.72843L0.224383 5.84511C-0.0747942 5.54593 -0.0747942 5.06079 0.224383 4.76218L0.585358 4.40111C0.88463 4.10194 1.3692 4.10194 1.66838 4.40111L3.46823 6.20103L8.33157 1.33754C8.63085 1.03836 9.1159 1.03836 9.41459 1.33754L9.77557 1.69861C10.0747 1.99779 10.0747 2.48284 9.77557 2.78154L3.82918 8.72843Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <p>Event Contents</p>
                          </Link>
                        </li>
                        {mainData?.eventCategory === "featuredEvents" && (
                          <li
                            className={
                              (step === 4 && "current") ||
                              (step >= 4 && "checked")
                            }
                          >
                            <Link to="">
                              <div>
                                <span>04</span>
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.82918 8.72843C3.73373 8.82445 3.60348 8.87802 3.4682 8.87802C3.33293 8.87802 3.20268 8.82445 3.10723 8.72843L0.224383 5.84511C-0.0747942 5.54593 -0.0747942 5.06079 0.224383 4.76218L0.585358 4.40111C0.88463 4.10194 1.3692 4.10194 1.66838 4.40111L3.46823 6.20103L8.33157 1.33754C8.63085 1.03836 9.1159 1.03836 9.41459 1.33754L9.77557 1.69861C10.0747 1.99779 10.0747 2.48284 9.77557 2.78154L3.82918 8.72843Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                              <p>Terms and Conditions</p>
                            </Link>
                          </li>
                        )}

                        <li className={step === 5 && "current"}>
                          <Link to="#">
                            <div>
                              {mainData?.eventCategory === "featuredEvents" ? (
                                <span>05</span>
                              ) : (
                                <span>04</span>
                              )}
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.82918 8.72843C3.73373 8.82445 3.60348 8.87802 3.4682 8.87802C3.33293 8.87802 3.20268 8.82445 3.10723 8.72843L0.224383 5.84511C-0.0747942 5.54593 -0.0747942 5.06079 0.224383 4.76218L0.585358 4.40111C0.88463 4.10194 1.3692 4.10194 1.66838 4.40111L3.46823 6.20103L8.33157 1.33754C8.63085 1.03836 9.1159 1.03836 9.41459 1.33754L9.77557 1.69861C10.0747 1.99779 10.0747 2.48284 9.77557 2.78154L3.82918 8.72843Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <p>Preview and Submit for Review</p>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  {step === 1 && (
                    <EventEditInfo
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      handleDropdownChange={handleDropdownChange}
                      triggerSubmit={triggerSubmit}
                      step={step}
                      isSubmitForm={isSubmitForm}
                      setStep={setStep}
                      setIsSubmitForm={setIsSubmitForm}
                      mainData={mainData}
                      setMainData={setMainData}
                      currentUser={currentUser}
                      schedules={schedules}
                    />
                  )}
                  {step === 2 && (
                    <EventConfig
                      triggerSubmit={triggerSubmit}
                      step={step}
                      isSubmitForm={isSubmitForm}
                      setStep={setStep}
                      setIsSubmitForm={setIsSubmitForm}
                      mainData={mainData}
                      setMainData={setMainData}
                      currentUser={currentUser}
                      eventDuration={selectedValue}
                      artistList={artistList}
                      setArtistList={setArtistList}
                      schedules={schedules}
                      setSchedules={setSchedules}
                      artistImages={artistImages}
                      setArtistImages={setArtistImages}
                      sameScheduleData={sameScheduleData}
                      setSameScheduleData={setSameScheduleData}
                      isMultiEventVenueEmpty={isMultiEventVenueEmpty}
                      setIsMultiEventVenueEmpty={setIsMultiEventVenueEmpty}
                    />
                  )}
                  {/* {step === 2 && (
                    <EventEditConfig
                      triggerSubmit={triggerSubmit}
                      step={step}
                      isSubmitForm={isSubmitForm}
                      setStep={setStep}
                      setIsSubmitForm={setIsSubmitForm}
                      mainData={mainData}
                      setMainData={setMainData}
                      currentUser={currentUser}
                      eventDuration={selectedValue}
                      artistList={artistList}
                      setArtistList={setArtistList}
                      schedules={schedules}
                      setSchedules={setSchedules}
                      artistImages={artistImages}
                      setArtistImages={setArtistImages}
                      isSameArtistForMultSchedule={isSameArtistForMultSchedule}
                      setIsSameArtistForMultSchedule={
                        setIsSameArtistForMultSchedule
                      }
                    />
                  )} */}
                  {step === 3 && (
                    <EventContent
                      triggerSubmit={triggerSubmit}
                      step={step}
                      isSubmitForm={isSubmitForm}
                      setStep={setStep}
                      setIsSubmitForm={setIsSubmitForm}
                      mainData={mainData}
                      setMainData={setMainData}
                      currentUser={currentUser}
                      eventDuration={selectedValue}
                      artistList={artistList}
                      setArtistList={setArtistList}
                      schedules={schedules}
                      setSchedules={setSchedules}
                      thumbnailContent={thumbnailContent}
                      setThumbnailContent={setThumbnailContent}
                      contentImageDatas={contentImageDatas}
                      setContentImageDatas={setContentImageDatas}
                      contentImages={contentImages}
                      setContentImages={setContentImages}
                      eventSponsors={eventSponsors}
                      setEventSponsors={setEventSponsors}
                      sponsorsImages={sponsorsImages}
                      setSponsorsImages={setSponsorsImages}
                    />
                  )}
                  {step === 4 && (
                    <EventTermsCondition
                      triggerSubmit={triggerSubmit}
                      step={step}
                      isSubmitForm={isSubmitForm}
                      setIsSubmitForm={setIsSubmitForm}
                      mainData={mainData}
                      setMainData={setMainData}
                    />
                  )}
                  {step === 5 && (
                    <EventPreview
                      triggerSubmit={triggerSubmit}
                      step={step}
                      isSubmitForm={isSubmitForm}
                      setIsSubmitForm={setIsSubmitForm}
                      mainData={mainData}
                      thumbnailContent={thumbnailContent}
                      currentUser={currentUser}
                      setMainData={setMainData}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="dash_content_main_bottom_process oflow-hd">
              {step > 1 && (
                <Link
                  to="#"
                  type="button"
                  className="float-left back"
                  onClick={() => setStep(step - 1)}
                >
                  Back
                </Link>
              )}
              {step < 5 ? (
                <Link
                  to=""
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setIsSubmitForm(true);
                  }}
                >
                  Proceed
                </Link>
              ) : (
                <Link
                  to=""
                  type="submit"
                  onClick={(event) => {
                    event.preventDefault();
                    updateEventToReview();
                  }}
                >
                  Submit For Review
                </Link>
              )}
            </div>
          </div>
        </FormProvider>
      )}
    </>
  );
};

export default EditEvent;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import DefaultImage from "./../../assets/images/images/defaultImage.png";
import EventSponsorsCompSingle from "./EventSponsorsCompSingle";
import CustomButton from "components/shared/Button";
import { GraphQLSponsorDelete } from "services/api-call";
const EventSponsorsComp = ({
  mainData,
  eventSponsors,
  setEventSponsors,
  sponsorsImages,
  setSponsorsImages,
}) => {
  const [addSponsor, setAddSponsor] = useState(true);
  const [customErrors, setCustomErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState({
    name: "",
    data: "",
  });
  const [isEdit, setIsEdit] = useState({
    state: false,
    index: "",
  });
  const [currentSponsor, setCurrentSponsor] = useState({
    eventID: mainData.id,
    sponsorName: "",
    sponsorType: "",
    sponsorBio: "",
    sponsorUrl: "",
    sponsorImageUrl: "",
    isActive: true,
  });

  const s3Bucket = process.env.REACT_APP_S3_BUCKET_URL;
  const addSponsorToList = (event) => {
    event.preventDefault();
    if (!currentSponsor.sponsorName || !currentSponsor.sponsorType) {
      setCustomErrors({
        ...customErrors,
        sponsorName: !currentSponsor.sponsorName
          ? "Sponsor Name is required"
          : "",
        sponsorType: !currentSponsor.sponsorType
          ? "Sponsor Type is required"
          : "",
      });
      return;
    } else {
      setCustomErrors({});
    }
    let newSponsor = currentSponsor;
    newSponsor.sponsorImageUrl = selectedImage?.name;
    setEventSponsors((prevSponsorList) => [...prevSponsorList, newSponsor]);
    setSponsorsImages((images) => [...images, selectedImage]);
    setCurrentSponsor({
      eventID: mainData.id,
      sponsorName: "",
      sponsorType: "",
      sponsorBio: "",
      sponsorUrl: "",
      sponsorImageUrl: "",
      isActive: true,
    });
    clearFileInput();
    setSelectedImage(null);

    setAddSponsor(false);
  };

  const updateSponsorList = () => {
    eventSponsors[isEdit.index] = currentSponsor;
    setEventSponsors(eventSponsors);
    setIsEdit({
      state: false,
      index: "",
    });
    setSponsorsImages((images) => [...images, selectedImage]);
    setCurrentSponsor({
      eventID: mainData.id,
      sponsorName: "",
      sponsorType: "",
      sponsorBio: "",
      sponsorUrl: "",
      sponsorImageUrl: "",
      isActive: true,
    });
    clearFileInput();
    setSelectedImage(null);
  };

  const clearFileInput = () => {
    const fileInput = document.getElementById("sponsorAvatar");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      let fileName = file.name.split(".")[0];
      let ext = file.name.split(".")[1];
      fileName = v4();

      reader.onload = () => {
        const imageData = reader.result;
        setSelectedImage({
          name: `${fileName}.${ext}`,
          data: imageData,
          file: file,
        });
        let newCurrentSponsor = currentSponsor;
        newCurrentSponsor.sponsorImageUrl = `${fileName}.${ext}`;
        setCurrentSponsor(newCurrentSponsor);
      };

      reader.readAsDataURL(file);
    } else {
      console.error("Invalid file type. Please select an image.");
    }
  };

  const getImageData = (sponsorImageUrl) => {
    const imageData = sponsorsImages?.find(
      (image) => image?.name === sponsorImageUrl
    );
    if (imageData) {
      return imageData?.data;
    } else {
      if (sponsorImageUrl) {
        return `${s3Bucket}${sponsorImageUrl}`;
      } else {
        return "";
      }
    }
  };

  const removeThis = (deleteIndex) => {
    let selectedSponsor = eventSponsors[deleteIndex];
    if (selectedSponsor.id) {
      GraphQLSponsorDelete(selectedSponsor.id)
        .then((data) =>data)
        .catch((err) => {
          console.error("delete sponsor error: ", err);
        });
    }
    let newArray = eventSponsors?.filter(
      (banner, index) => index !== deleteIndex
    );
    setEventSponsors(newArray);
  };
  return (
    <>
      <div className="col-12">
        <div className="single_input_box econ_sponsor_table">
          <label htmlFor="#">Sponsors</label>
          <div className="dash_main_table_wrapper">
            {eventSponsors.length > 0 && (
              <div className="dash_main_main_table oflow-hd">
                <table>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {eventSponsors.map((eventSponsor, index) => (
                      <EventSponsorsCompSingle
                        key={index}
                        eventSponsor={eventSponsor}
                        getImageData={getImageData}
                        removeThis={removeThis}
                        index={index}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        currentSponsor={currentSponsor}
                        setCurrentSponsor={setCurrentSponsor}
                        customErrors={customErrors}
                        setCustomErrors={setCustomErrors}
                        handleDrop={handleDrop}
                        addSponsorToList={addSponsorToList}
                        selectedImage={selectedImage}
                        updateSponsorList={updateSponsorList}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {eventSponsors.length === 0 && (
              <div className="dash_main_main_table_empty">
                No sponsor added yet
              </div>
            )}

            <div className="dash_main_main_table_add oflow-hd">
              {!addSponsor && (
                <ul>
                  <li>
                    <Link
                      className="addMoreArtist"
                      to=""
                      onClick={() => setAddSponsor(true)}
                    >
                      Add Sponser +
                    </Link>
                  </li>
                </ul>
              )}
              {addSponsor && !isEdit.state && (
                <div className="dash_main_main_table_form oflow-hd">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="single_input_box">
                        <label htmlFor="">Sponsor Name</label>
                        <input
                          type="text"
                          value={currentSponsor.sponsorName}
                          placeholder="Name"
                          onChange={(e) => {
                            setCurrentSponsor({
                              ...currentSponsor,
                              sponsorName: e.target.value,
                            });
                            setCustomErrors({
                              ...customErrors,
                              sponsorName: "",
                            });
                          }}
                        />
                        {customErrors?.sponsorName && (
                          <p className="text-danger">
                            {customErrors.sponsorName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single_input_box">
                        <label htmlFor="">Sponsor Type</label>
                        <input
                          type="text"
                          value={currentSponsor.sponsorType}
                          placeholder="Sponsor Type"
                          onChange={(e) => {
                            setCurrentSponsor({
                              ...currentSponsor,
                              sponsorType: e.target.value,
                            });
                            setCustomErrors({
                              ...customErrors,
                              sponsorType: "",
                            });
                          }}
                        />
                        {customErrors?.sponsorType && (
                          <p className="text-danger">
                            {customErrors.sponsorType}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="dash_main_main_table_form_up single_input_box">
                        <label htmlFor="">Sponsor Logo</label>
                        <input
                          type="file"
                          id="sponsorAvatar"
                          name="cf_photo"
                          onDrop={handleDrop}
                          onDragOver={handleDrop}
                          onChange={handleDrop}
                          accept="image/*"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="dash_main_main_table_form_action">
                        <ul>
                          <li>
                            <CustomButton
                              variant="outlined"
                              customVariant="darkOutline"
                              onClick={() => {
                                setCurrentSponsor({
                                  eventID: mainData?.id,
                                  sponsorName: "",
                                  sponsorType: "",
                                  sponsorBio: "",
                                  sponsorUrl: "",
                                  sponsorImageUrl: "",
                                  isActive: true,
                                });

                                setAddSponsor(false);
                                setCustomErrors({
                                  sponsorName: "",
                                  sponsorType: "",
                                });
                              }}
                            >
                              Cancel
                            </CustomButton>
                          </li>
                          <li>
                            <CustomButton
                              customVariant="primaryContained"
                              onClick={addSponsorToList}
                              disable={!selectedImage?.name}
                            >
                              Add
                            </CustomButton>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventSponsorsComp;

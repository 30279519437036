import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EventSummery = ({ mainData, setMainData }) => {
  const [eventSummery, setEventSummery] = useState(mainData?.eventSummery);
  return (
    <>
      {mainData && (
        <ReactQuill
          value={eventSummery}
          onChange={(content) => {
            setEventSummery(content);
            setMainData({
              ...mainData,
              eventSummery: content,
            });
          }}
          placeholder="Write here...."
          theme="snow"
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, 3, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ["link", "image"],
                ["clean"],
              ],
            },
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "color",
            "background",
            "align",
            "link",
            "image",
          ]}
        />
      )}
    </>
  );
};

export default EventSummery;

import { Outlet } from 'react-router-dom';

const ComponentsLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default ComponentsLayout;

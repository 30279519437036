import React from "react";

const EventSponsorsPreview = ({ sponsors }) => {
  const s3BucketUrl = process.env.REACT_APP_S3_BUCKET_URL;
  return (
    <>
      <div className="col-12">
        <div className="single_input_box econ_sponsor_table">
          <label htmlFor="#">Sponsors</label>
          <div className="dash_main_table_wrapper">
            <div className="dash_main_main_table oflow-hd">
              <table>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Type</th>
                </tr>
                {sponsors.length > 0 &&
                  sponsors.map((sponsor, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={`${s3BucketUrl}${sponsor?.sponsorImageUrl}`}
                          alt="img"
                        />
                      </td>
                      <td>{sponsor?.sponsorName}</td>
                      <td>{sponsor?.sponsorType}</td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventSponsorsPreview;

import AWS from "aws-sdk";
// import { Amplify } from "aws-amplify";

export const AmplifyImageUpload = async (folder, pathname, file) => {
  if (pathname?.includes("public/")) return pathname;

  let user = "";
  const S3_BUCKET_NAME = "www.cdntickettomorrow.com";
  const REGION = "ap-southeast-1";
  const ACCESS_KEY = "AKIAU3Q4ZJYYBUVV22OY";
  const SECRET_KEY = "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";

  AWS.config.update({
    region: REGION,
    credentials: new AWS.Credentials(ACCESS_KEY, SECRET_KEY),
  });

  const params = {
    Bucket: S3_BUCKET_NAME,
    Key: `public/${folder}/${pathname}`,
    Body: file,
    CacheControl: "no-cache",
  };

  try {
    const S3 = new AWS.S3();
    const data = await S3.upload(params).promise();
    return data.key;
  } catch (e) {
    console.error("image error", e);
    return "";
  }
};

import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

const CustomButton = ({ children, customVariant, ...props }) => {
  const theme = useTheme();
  let sxCustomValue = {
    fontFamily: "Gilroy",
    fontWeight: "500",
    fontSize: "14px",
  };
  if (customVariant === "darkOutline") {
    sxCustomValue = {
      ...sxCustomValue,
      color: grey[900],
      border: `1px solid ${grey[900]}`,
      padding: "6px 8px",
      "&:hover": {
        color: grey[900],
        border: `1px solid ${grey[900]}`,
        backgroundColor: grey[100],
      },
    };
  } else if (customVariant === "primaryContained") {
    sxCustomValue = {
      ...sxCustomValue,
      color: "#ffffff",
      border: `1px solid #ffffff`,
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      backgroundColor: `${props.disabled ? "gray" : "#0b79bf"}`,
      "&:hover": {
        color: "#ffffff",
        border: `1px solid #ffffff`,
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        backgroundColor: "#0b79bf",
        opacity: 0.7,
      },
    };
  } else if (customVariant === "successContained") {
    sxCustomValue = {
      ...sxCustomValue,
      color: "#ffffff",
      border: `1px solid #ffffff`,
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      backgroundColor: `${props.disabled ? "gray" : "#34beb1"}`,
      "&:hover": {
        color: "#ffffff",
        border: `1px solid #ffffff`,
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        backgroundColor: "#34beb1",
        opacity: 0.7,
      },
    };
  } else if (customVariant === "dangerContained") {
    sxCustomValue = {
      ...sxCustomValue,
      color: "#ffffff",
      border: `1px solid #ffffff`,
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      backgroundColor: `${props.disabled ? "gray" : "#ff0e0e"}`,
      "&:hover": {
        color: "#ffffff",
        border: `1px solid #ffffff`,
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        backgroundColor: "#ff0e0e",
        opacity: 0.7,
      },
    };
  }
  return (
    <Button sx={sxCustomValue} {...props}>
      {children}
    </Button>
  );
};

export default CustomButton;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { sortDates } from "services/sortDate";

const EventSchedulePreview = ({ schedules }) => {
  const [updatedSchedules, setUpdatedSchedules] = useState(null);

  useEffect(() => {
    let newData = schedules;
    newData = sortDates(newData, "asc");
    setUpdatedSchedules(newData);
  }, []);
  return (
    <>
      <div className="preview_timedate_location_wrapper oflow-hd">
        <div className="preview_timedate_location_single oflow-hd">
          <div className="single_input_box">
            <label htmlFor="#">Date and Time</label>
          </div>
          <table>
            {updatedSchedules?.length > 0 &&
              updatedSchedules?.map((schedule, index) => (
                <tr key={index}>
                  <td>Day {index + 1}</td>
                  <td>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.8 1H13.3V2.5C13.3 2.8 13.05 3 12.8 3C12.55 3 12.3 2.8 12.3 2.5V1H4.3V2.5C4.3 2.8 4.05 3 3.8 3C3.55 3 3.3 2.8 3.3 2.5V1H1.8C1.05 1 0.5 1.65 0.5 2.5V4.3H16.5V2.5C16.5 1.65 15.6 1 14.8 1ZM0.5 5.35V14.5C0.5 15.4 1.05 16 1.85 16H14.85C15.65 16 16.55 15.35 16.55 14.5V5.35H0.5ZM4.95 13.75H3.75C3.55 13.75 3.35 13.6 3.35 13.35V12.1C3.35 11.9 3.5 11.7 3.75 11.7H5C5.2 11.7 5.4 11.85 5.4 12.1V13.35C5.35 13.6 5.2 13.75 4.95 13.75ZM4.95 9.25H3.75C3.55 9.25 3.35 9.1 3.35 8.85V7.6C3.35 7.4 3.5 7.2 3.75 7.2H5C5.2 7.2 5.4 7.35 5.4 7.6V8.85C5.35 9.1 5.2 9.25 4.95 9.25ZM8.95 13.75H7.7C7.5 13.75 7.3 13.6 7.3 13.35V12.1C7.3 11.9 7.45 11.7 7.7 11.7H8.95C9.15 11.7 9.35 11.85 9.35 12.1V13.35C9.35 13.6 9.2 13.75 8.95 13.75ZM8.95 9.25H7.7C7.5 9.25 7.3 9.1 7.3 8.85V7.6C7.3 7.4 7.45 7.2 7.7 7.2H8.95C9.15 7.2 9.35 7.35 9.35 7.6V8.85C9.35 9.1 9.2 9.25 8.95 9.25ZM12.95 13.75H11.7C11.5 13.75 11.3 13.6 11.3 13.35V12.1C11.3 11.9 11.45 11.7 11.7 11.7H12.95C13.15 11.7 13.35 11.85 13.35 12.1V13.35C13.35 13.6 13.2 13.75 12.95 13.75ZM12.95 9.25H11.7C11.5 9.25 11.3 9.1 11.3 8.85V7.6C11.3 7.4 11.45 7.2 11.7 7.2H12.95C13.15 7.2 13.35 7.35 13.35 7.6V8.85C13.35 9.1 13.2 9.25 12.95 9.25Z"
                        fill="#CCCCCC"
                      />
                    </svg>
                    <span>
                      {moment(schedule.eventDate).format("DD MMM YYYY")}
                    </span>
                  </td>
                  {schedule?.eventStartTime && (
                    <td>
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_632_202)">
                          <path
                            d="M8.5 0C3.81291 0 0 3.81291 0 8.5C0 13.1871 3.81291 17 8.5 17C13.1871 17 17 13.1871 17 8.5C17 3.81291 13.1871 0 8.5 0ZM12.5425 12.8966C12.4768 12.9624 12.3987 13.0147 12.3128 13.0503C12.2269 13.086 12.1347 13.1043 12.0417 13.1042C11.9487 13.1043 11.8565 13.086 11.7706 13.0503C11.6847 13.0147 11.6066 12.9624 11.5409 12.8966L7.99923 9.35498C7.93332 9.28933 7.88104 9.21128 7.84543 9.12533C7.80981 9.03939 7.79156 8.94724 7.79171 8.85421V4.25C7.79171 3.8583 8.10897 3.54171 8.5 3.54171C8.89103 3.54171 9.20829 3.8583 9.20829 4.25V8.56096L12.5425 11.895C12.8194 12.1721 12.8194 12.6196 12.5425 12.8966Z"
                            fill="#CCCCCC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_632_202">
                            <rect width="17" height="17" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <span>
                        {moment(schedule.eventStartTime, "HH:mm").format(
                          "hh:mm a"
                        )}
                      </span>
                    </td>
                  )}
                </tr>
              ))}
          </table>
        </div>

        <div className="preview_timedate_location_single oflow-hd">
          <div className="single_input_box">
            <label htmlFor="#">Location and Venues</label>
          </div>
          <table>
            {schedules.map((schedule, index) => (
              <tr key={index}>
                <td>Day {index + 1}</td>
                <td>{schedule.eventCity}</td>
                <td>{schedule.venue}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  );
};

export default EventSchedulePreview;

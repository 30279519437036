export const customGetEvent = (id) => {
  return `query MyQuery {
        getEvent(id: "${id}") {
          createdAt
          eventPhoneNo
          eventPolicy
          maxTicketByUser
          maxTicketInSingleCheckout
          ticketActivationDate
          userRequiredInfo
          totalInterested
          hasReEntry
          isUserInfoRequiredForEach
          eventSchedules {
            items {
              address
              createdAt
              eventCity
              eventDate
              eventDuration
              eventID
              gateCloseTime
              eventStartTime
              gateOpenTime
              id
              isActive
              owner
              scheduleTitle
              updatedAt
              venue
              eventAgeGroup
              tickets {
                items {
                  createdAt
                  eventID
                  eventScheduleID
                  id
                  owner
                  ticketPrice
                  ticketType
                  totalSold
                  totalTicket
                  updatedAt
                  ticketSales {
                    items {
                      createdAt
                      eventID
                      eventScheduleID
                      id
                      isActive
                      owner
                      ticketID
                      ticketSaleMasterID
                      totalPrice
                      totalTicket
                      updatedAt
                      userID
                    }
                    nextToken
                  }
                }
            nextToken
          }
            }
            nextToken
          }
          eventStatus
          eventSummery
          eventType
          eventWillBe
          facebookUrl
          id
          instaUrl
          isActive
          isSameCouponForAllDay
          isSamePromotionForAllDay
          isSameTicketForAllDay
          isUpComing
          linkedinUrl
          minAge
          organizerID
          owner
          title
          tweeterUrl
          updatedAt
          eventCategory
          tickets {
            items {
              id
              createdAt
              eventID
              eventScheduleID
              id
              owner
              ticketPrice
              ticketType
              totalSold
              totalTicket
              updatedAt
              ticketSales {
                items {
                  createdAt
                  eventID
                  eventScheduleID
                  id
                  isActive
                  owner
                  ticketID
                  ticketSaleMasterID
                  totalPrice
                  totalTicket
                  updatedAt
                  userID
                }
                nextToken
              }
            }
            nextToken
          }
        }
      }`;
};

export const getCustomSchedule = (id) => {
  return `query MyQuery {
    getEventSchedule(id: "${id}") {
      id
      eventID
      tickets {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      eventDate
      scheduleTitle
      eventAgeGroup
      eventDuration
      eventStartTime
      gateOpenTime
      gateCloseTime
      eventCity
      location {
        lat
        lon
        __typename
      }
      address
      venue
      isActive
      createdAt
      updatedAt
      owner
      __typename
        }
      }`;
};

export const listEventsCustom = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const customGetEventForManageEvent = (id) => {
  return `query MyEventQuery {
    getEvent(id: "${id}") {
      id
      createdByID
      eventCategory
      organizerID
      organizer {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      eventSchedules {
        items {
          address
          createdAt
          eventCity
          eventDate
          eventDuration
          eventID
          gateCloseTime
          eventStartTime
          gateOpenTime
          id
          isActive
          owner
          scheduleTitle
          updatedAt
          venue
          eventAgeGroup
        }
        nextToken
        __typename
      }
      eventContents {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      tickets {
          items {
                  id
                  eventID
                  eventScheduleID
                  ticketType
                  ticketSales {
                          nextToken
                          __typename
                          }
                  ticketPrice
                  totalTicket
                  totalSold
                  createdAt
                  updatedAt
                  owner
                  __typename
          }
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      title
      eventType
      isUpComing
      isSameTicketForAllDay
      isSamePromotionForAllDay
      isSameCouponForAllDay
      minAge
      eventWillBe
      eventStatus
      isActive
      eventPhoneNo
      eventSummery
      eventPolicy
      facebookUrl
      instaUrl
      tweeterUrl
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      hasReEntry
      isTicketActive
      ticketActivationDate
      ticketDeActivationDate
      isUserInfoRequiredForEach
      userRequiredInfo
      createdAt
      updatedAt
      owner
      __typename
    }}`;
};

export const listEventNoAuth = (filter) => {
  return `query MyQuery {
  listEvents ${filter} {
    items {
      createdAt
      createdByID
      eventCategory
      eventPhoneNo
      eventPolicy
      eventStatus
      eventSummery
      eventType
      eventWillBe
      facebookUrl
      hasReEntry
      id
      instaUrl
      isActive
      isSameCouponForAllDay
      isSamePromotionForAllDay
      isTicketActive
      isSameTicketForAllDay
      isUpComing
      isUserInfoRequiredForEach
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      minAge
      organizerID
      owner
      ticketActivationDate
      ticketDeActivationDate
      title
      totalInterested
      tweeterUrl
      userRequiredInfo
      eventArtists {
        items {
          artistBio
          artistImageUrl
          artistName
          artistTitle
          artistUrl
          createdAt
          eventID
          eventScheduleID
          id
          isActive
          owner
        }
      }
      eventCategory
      eventContents {
        items {
          contentType
          contentUrl
          createdAt
          eventID
          id
          isActive
          isMain
          isSlider
          owner
        }
      }
      eventPhoneNo
      eventPolicy
      eventSchedules {
        items {
          address
          createdAt
          eventAgeGroup
          eventArtists {
            items {
              artistBio
              artistImageUrl
              artistName
              artistTitle
              artistUrl
              createdAt
              eventID
              eventScheduleID
              id
              isActive
              owner
            }
          }
          eventCity
          eventDate
          eventDuration
          eventID
          eventStartTime
          gateCloseTime
          gateOpenTime
          hasReEntry
          id
          isActive
          owner
          scheduleTitle
          ticketCoupons {
            items {
              activationDate
              couponCode
              createdAt
              deActivationDate
              discountAmount
              eventID
              eventScheduleID
              id
              isActive
              ispersent
              maxUsedInTotal
              maxUsedbyUser
              owner
              totalUsed
              updatedAt
            }
          }
          ticketPromotions {
            items {
              activationDate
              createdAt
              deActivationDate
              discountAmount
              eventID
              eventScheduleID
              id
              isActive
              ispersent
              owner
            }
          }
          tickets {
            items {
              createdAt
              eventID
              eventScheduleID
              owner
              id
              ticketPrice
              ticketSales {
                items {
                  createdAt
                  eventID
                  eventScheduleID
                  id
                  isActive
                  owner
                  ticketID
                  ticketSaleMasterID
                  ticketSaleSingles {
                    items {
                      createdAt
                      eventID
                      id
                      isActive
                      isEntryDone
                      owner
                      ticketCode
                      ticketID
                      ticketPrice
                      ticketSaleID
                      ticketUser
                      userID
                      isCombo
                    }
                  }
                  totalPrice
                  totalTicket
                  userID
                  ticketSaleMaster {
                    createdAt
                    deliveryAddress
                    deliveryOption
                    discountAmount
                    discountMethod
                    eventID
                    eventScheduleID
                    id
                    isActive
                    ispersent
                    owner
                    paymentData
                    paymentMethod
                    totalDiscountAmount
                    totalPrice
                    totalPriceBeforeDiscount
                    totalTicket
                    user {
                      createdAt
                      dob
                      email
                      firstName
                      fullName
                      gender
                      id
                      imageUrl
                      isApproved
                      isPhoneVerified
                      lastName
                      nid
                      organizerName
                      otpValidTillDate
                      owner
                      phoneNo
                      phoneVerificationOtp
                      userName
                      userType
                    }
                  }
                }
              }
              ticketType
              totalSold
              totalTicket
            }
          }
          venue
        }
      }
      eventSponsors {
        items {
          createdAt
          eventID
          id
          isActive
          owner
          sponsorBio
          sponsorImageUrl
          sponsorName
          sponsorType
          sponsorUrl
        }
      }
      eventStatus
      eventSummery
      eventType
      eventWillBe
      facebookUrl
      hasReEntry
      id
      instaUrl
      isActive
      isSameCouponForAllDay
      isSamePromotionForAllDay
      isSameTicketForAllDay
      isTicketActive
      isUpComing
      isUserInfoRequiredForEach
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      minAge
      organizer {
        createdAt
        dob
        email
        firstName
        fullName
        gender
        id
        imageUrl
        owner
        nid
        lastName
        phoneNo
        userName
        userType
      }
      ticketCoupons {
        items {
          activationDate
          couponCode
          createdAt
          deActivationDate
          discountAmount
          eventID
          eventScheduleID
          id
          isActive
          ispersent
          maxUsedInTotal
          maxUsedbyUser
          owner
          totalUsed
       
        }
        nextToken
      }
      ticketPromotions {
        items {
          activationDate
          createdAt
          deActivationDate
          discountAmount
          eventID
          eventScheduleID
          id
          isActive
          ispersent
          owner
  
        }
        nextToken
      }
      tickets {
        items {
          createdAt
          eventID
          eventScheduleID
          owner
          id
          ticketPrice
          ticketSales {
            items {
              createdAt
              eventID
              eventScheduleID
              id
              isActive
              owner
              ticketID
              ticketSaleMasterID
              ticketSaleSingles {
                items {
                  createdAt
                  eventID
                  id
                  isActive
                  isEntryDone
                  owner
                  ticketCode
                  ticketID
                  ticketPrice
                  ticketSaleID
                  ticketUser
                  userID
                  isCombo
                }
              }
              totalPrice
              totalTicket
              userID
              ticketSaleMaster {
                createdAt
                deliveryAddress
                deliveryOption
                discountAmount
                discountMethod
                eventID
                eventScheduleID
                id
                isActive
                ispersent
                owner
                paymentData
                paymentMethod
                totalDiscountAmount
                totalPrice
                totalPriceBeforeDiscount
                totalTicket
                user {
                  createdAt
                  dob
                  email
                  firstName
                  fullName
                  gender
                  id
                  imageUrl
                  isApproved
                  isPhoneVerified
                  lastName
                  nid
                  organizerName
                  otpValidTillDate
                  owner
                  phoneNo
                  phoneVerificationOtp
                  userName
                  userType
                }
              }
            }
          }
          ticketType
          totalSold
          totalTicket
        }
      }
    }
    nextToken
  }
}
`;
};

export const listEventNoAuthOrganiser = (parameters) => {
  return `query MyQuery {
    byOrganizerIDEvent ${parameters} {
    items {
      createdAt
      createdByID
      eventCategory
      eventPhoneNo
      eventPolicy
      eventStatus
      eventSummery
      eventType
      eventWillBe
      facebookUrl
      hasReEntry
      id
      instaUrl
      isActive
      isSameCouponForAllDay
      isSamePromotionForAllDay
      isTicketActive
      isSameTicketForAllDay
      isUpComing
      isUserInfoRequiredForEach
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      minAge
      organizerID
      owner
      ticketActivationDate
      ticketDeActivationDate
      title
      totalInterested
      tweeterUrl
      userRequiredInfo
      eventArtists {
        items {
          artistBio
          artistImageUrl
          artistName
          artistTitle
          artistUrl
          createdAt
          eventID
          eventScheduleID
          id
          isActive
          owner
        }
      }
      eventCategory
      eventContents {
        items {
          contentType
          contentUrl
          createdAt
          eventID
          id
          isActive
          isMain
          isSlider
          owner
        }
      }
      eventPhoneNo
      eventPolicy
      eventSchedules {
        items {
          address
          createdAt
          eventAgeGroup
          eventArtists {
            items {
              artistBio
              artistImageUrl
              artistName
              artistTitle
              artistUrl
              createdAt
              eventID
              eventScheduleID
              id
              isActive
              owner
            }
          }
          eventCity
          eventDate
          eventDuration
          eventID
          eventStartTime
          gateCloseTime
          gateOpenTime
          hasReEntry
          id
          isActive
          owner
          scheduleTitle
          ticketCoupons {
            items {
              activationDate
              couponCode
              createdAt
              deActivationDate
              discountAmount
              eventID
              eventScheduleID
              id
              isActive
              ispersent
              maxUsedInTotal
              maxUsedbyUser
              owner
              totalUsed
              updatedAt
            }
          }
          ticketPromotions {
            items {
              activationDate
              createdAt
              deActivationDate
              discountAmount
              eventID
              eventScheduleID
              id
              isActive
              ispersent
              owner
            }
          }
          tickets {
            items {
              createdAt
              eventID
              eventScheduleID
              owner
              id
              ticketPrice
              ticketSales {
                items {
                  createdAt
                  eventID
                  eventScheduleID
                  id
                  isActive
                  owner
                  ticketID
                  ticketSaleMasterID
                  ticketSaleSingles {
                    items {
                      createdAt
                      eventID
                      id
                      isActive
                      isEntryDone
                      owner
                      ticketCode
                      ticketID
                      ticketPrice
                      ticketSaleID
                      ticketUser
                      userID
                      isCombo
                    }
                  }
                  totalPrice
                  totalTicket
                  userID
                  ticketSaleMaster {
                    createdAt
                    deliveryAddress
                    deliveryOption
                    discountAmount
                    discountMethod
                    eventID
                    eventScheduleID
                    id
                    isActive
                    ispersent
                    owner
                    paymentData
                    paymentMethod
                    totalDiscountAmount
                    totalPrice
                    totalPriceBeforeDiscount
                    totalTicket
                    user {
                      createdAt
                      dob
                      email
                      firstName
                      fullName
                      gender
                      id
                      imageUrl
                      isApproved
                      isPhoneVerified
                      lastName
                      nid
                      organizerName
                      otpValidTillDate
                      owner
                      phoneNo
                      phoneVerificationOtp
                      userName
                      userType
                    }
                  }
                }
              }
              ticketType
              totalSold
              totalTicket
            }
          }
          venue
        }
      }
      eventSponsors {
        items {
          createdAt
          eventID
          id
          isActive
          owner
          sponsorBio
          sponsorImageUrl
          sponsorName
          sponsorType
          sponsorUrl
        }
      }
      eventStatus
      eventSummery
      eventType
      eventWillBe
      facebookUrl
      hasReEntry
      id
      instaUrl
      isActive
      isSameCouponForAllDay
      isSamePromotionForAllDay
      isSameTicketForAllDay
      isTicketActive
      isUpComing
      isUserInfoRequiredForEach
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      minAge
      organizer {
        createdAt
        dob
        email
        firstName
        fullName
        gender
        id
        imageUrl
        owner
        nid
        lastName
        phoneNo
        userName
        userType
      }
      ticketCoupons {
        items {
          activationDate
          couponCode
          createdAt
          deActivationDate
          discountAmount
          eventID
          eventScheduleID
          id
          isActive
          ispersent
          maxUsedInTotal
          maxUsedbyUser
          owner
          totalUsed
       
        }
        nextToken
      }
      ticketPromotions {
        items {
          activationDate
          createdAt
          deActivationDate
          discountAmount
          eventID
          eventScheduleID
          id
          isActive
          ispersent
          owner
  
        }
        nextToken
      }
      tickets {
        items {
          createdAt
          eventID
          eventScheduleID
          owner
          id
          ticketPrice
          ticketSales {
            items {
              createdAt
              eventID
              eventScheduleID
              id
              isActive
              owner
              ticketID
              ticketSaleMasterID
              ticketSaleSingles {
                items {
                  createdAt
                  eventID
                  id
                  isActive
                  isEntryDone
                  owner
                  ticketCode
                  ticketID
                  ticketPrice
                  ticketSaleID
                  ticketUser
                  userID
                  isCombo
                }
              }
              totalPrice
              totalTicket
              userID
              ticketSaleMaster {
                createdAt
                deliveryAddress
                deliveryOption
                discountAmount
                discountMethod
         
                eventID
                eventScheduleID
                id
                isActive
                ispersent
                owner
                paymentData
                paymentMethod
                totalDiscountAmount
                totalPrice
                totalPriceBeforeDiscount
                totalTicket
                user {
                  createdAt
                  dob
                  email
                  firstName
                  fullName
                  gender
                  id
                  imageUrl
                  isApproved
                  isPhoneVerified
                  lastName
                  nid
                  organizerName
                  otpValidTillDate
                  owner
                  phoneNo
                  phoneVerificationOtp
                  userName
                  userType
                }
              }
            }
          }
          ticketType
          totalSold
          totalTicket
        }
      }
    }
    nextToken
  }
}
`;
};

export const customGetEventById = (eventId) => {
  return `query MyQuery {
    getEvent(id: "${eventId}") {
      createdAt
      createdByID
      eventArtists {
        items {
          artistBio
          artistImageUrl
          artistName
          artistTitle
          artistUrl
          eventID
          eventScheduleID
          id
          isActive
          owner
        }
      }
      eventCategory
      eventContents {
        items {
          contentType
          contentUrl
          createdAt
          eventID
          id
          isActive
          isMain
          isSlider
          owner
        }
      }
      eventPhoneNo
      eventPolicy
      eventSchedules {
        items {
          address
          createdAt
          eventAgeGroup
          eventArtists {
            items {
              artistBio
              artistImageUrl
              artistName
              artistTitle
              artistUrl
              createdAt
              eventID
              eventScheduleID
              id
              isActive
              owner
            }
          }
          eventCity
          eventDate
          eventDuration
          eventID
          eventStartTime
          gateCloseTime
          gateOpenTime
          hasReEntry
          id
          isActive
          owner
          scheduleTitle
          ticketCoupons {
            items {
              activationDate
              couponCode
              createdAt
              deActivationDate
              discountAmount
              eventID
              eventScheduleID
              id
              isActive
              ispersent
              maxUsedInTotal
              maxUsedbyUser
              owner
              totalUsed
              updatedAt
            }
          }
          ticketPromotions {
            items {
              activationDate
              createdAt
              deActivationDate
              discountAmount
              eventID
              eventScheduleID
              id
              isActive
              ispersent
              owner
            }
          }
          tickets {
            items {
              createdAt
              eventID
              eventScheduleID
              owner
              id
              ticketPrice
              ticketSales {
                items {
                  createdAt
                  eventID
                  eventScheduleID
                  id
                  isActive
                  owner
                  ticketID
                  ticketSaleMasterID
                  ticketSaleSingles {
                    items {
                      createdAt
                      eventID
                      id
                      isActive
                      isEntryDone
                      owner
                      ticketCode
                      ticketID
                      ticketPrice
                      ticketSaleID
                      ticketUser
                      userID
                    }
                  }
                  totalPrice
                  totalTicket
                  userID
                }
              }
              ticketType
              totalSold
              totalTicket
            }
          }
          venue
          location {
            lat
            lon
          }
        }
      }
      eventSponsors {
        items {
          createdAt
          eventID
          id
          isActive
          owner
          sponsorBio
          sponsorImageUrl
          sponsorName
          sponsorType
          sponsorUrl
        }
      }
      eventStatus
      eventSummery
      eventType
      eventWillBe
      facebookUrl
      hasReEntry
      id
      instaUrl
      isActive
      isSameCouponForAllDay
      isSamePromotionForAllDay
      isSameTicketForAllDay
      isTicketActive
      isUpComing
      isUserInfoRequiredForEach
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      minAge
      organizer {
        createdAt
        dob
        organizerName
        email
        firstName
        fullName
        gender
        id
        imageUrl
        lastName
        nid
        owner
        phoneNo
        updatedAt
        userName
        userType
      }
      organizerID
      owner
      ticketActivationDate
      title
      totalInterested
      tweeterUrl
      updatedAt
      isLocationVenueSame
      isSameArtist
    }
  }
  `;
};

export const customGetTicketsByEventId = (eventId) => {
  return `query MyQuery {
    getEvent(id: "${eventId}") {
      createdByID
      createdAt
      eventCategory
      eventDeactiveDate
      eventPhoneNo
      eventPolicy
      eventStatus
      eventSummery
      eventType
      eventWillBe
      facebookUrl
      hasReEntry
      id
      instaUrl
      isActive
      isLocationVenueSame
      isSameArtist
      isSameCouponForAllDay
      isSamePromotionForAllDay
      isSameTicketForAllDay
      isTicketActive
      isUpComing
      isUserInfoRequiredForEach
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      minAge
      organizerID
      owner
      ticketActivationDate
      ticketDeActivationDate
      title
      totalEntry
      totalInterested
      tweeterUrl
      userRequiredInfo
      tickets {
        items {
          createdAt
          eventID
          eventScheduleID
          id
          owner
          ticketPrice
          ticketType
          totalSold
          totalTicket
          ticketSales {
            items {
              createdAt
              eventScheduleID
              eventID
              id
              isActive
              owner
              ticketID
              ticketSaleMasterID
              totalPrice
              totalTicket
              updatedAt
              ticketSaleSingles {
                items {
                  createdAt
                  entryDate
                  eventID
                  eventScheduleID
                  id
                  isActive
                  isEntryDone
                  owner
                  ticketCode
                  ticketID
                  ticketPrice
                  ticketSaleID
                  ticketUser
                  userID
                }
              }
            }
          }
        }
      }
      eventSchedules {
        items {
          address
          createdAt
          eventAgeGroup
          eventCity
          eventDate
          eventDuration
          eventID
          eventStartTime
          gateCloseTime
          gateOpenTime
          hasReEntry
          id
          isActive
          owner
          scheduleTitle
          totalEntry
          venue
          tickets {
            items {
              createdAt
              eventID
              eventScheduleID
              id
              owner
              ticketPrice
              ticketSales {
                items {
                  createdAt
                  eventID
                  eventScheduleID
                  id
                  isActive
                  owner
                  ticketID
                  ticketSaleMasterID
                  totalPrice
                  totalTicket
                  userID
                  ticketSaleSingles {
                    items {
                      createdAt
                      entryDate
                      eventID
                      eventScheduleID
                      id
                      isActive
                      isEntryDone
                      owner
                      ticketCode
                      ticketID
                      ticketPrice
                      ticketSaleID
                      ticketUser
                      userID
                    }
                  }
                }
              }
              ticketType
              totalSold
              totalTicket
            }
          }
        }
      }
    }
  }
  `;
};

export const customGetArtistsByEventId = (eventId) => {
  return `query MyQuery {
    byEventIDSortOrderArtists(eventID: "${eventId}") {
      items {
        id
        isActive
        eventScheduleID
        eventID
        artistUrl
        artistTitle
        shortOrder
        artistName
        artistImageUrl
        artistBio
      }
    }
  }`;
};
export const customGetContentsByEventId = (eventId) => {
  return `query MyQuery {
    byEventIDEventContent(eventID: "${eventId}") {
      items {
        contentType
        contentUrl
        createdAt
        eventID
        id
        isActive
        isMain
        isSlider
      }
    }
  }`;
};

export const getUserTypeByEmail = (email) => {
  return `query MyQuery2 {
        byEmailUser(email: "${email}") {
          items {
            id
            userType
            isApproved
          }
        }
      }`;
};

export const getUserByEmail = (email) => {
  return `query MyQuery {
    byEmailUser(email: "${email}") {
      items {
        createdAt
        dob
        email
        firstName
        fullName
        gender
        id
        imageUrl
        isApproved
        isPhoneVerified
        lastName
        nid
        owner
        otpValidTillDate
        organizerName
        phoneNo
        phoneVerificationOtp
        userName
        userType
      }
    }
  }
  `;
};

export const getUserByNotEqualType = (user, organizer) => {
  return `query MyQuery {
    listUsers(filter: {userType: {ne: ${user}}, or: {userType: {ne: ${organizer}}}}) {
      items {
        id
        userType
        userName
        phoneVerificationOtp
        phoneNo
        owner
        otpValidTillDate
        organizerName
        lastName
        isPhoneVerified
        isApproved
        imageUrl
        gender
        fullName
        firstName
        email
        dob
        createdAt
      }
    }
  }
  
  `;
};
export const getCompanyInfoByUser = (userId) => {
  return `query MyQuery {
    getUser(id: "${userId}") {
      company {
        items {
          UserID
          binUrl
          companyAddress
          companyBinNo
          companyEmail
          companyImageUrl
          companyName
          companyPhoneNumber
          companyTinNo
          companyTradeLicenseNo
          createdAt
          id
          tinUrl
          owner
          tradeLicenseUrl
          updatedAt
        }
      }
    }
  }  
  `;
};
export const getCompanyBankInfoByCompanyId = (companyID) => {
  return `query MyQuery {
    byCompanyProfileCompanyBankInfo(companyID: "${companyID}") {
      items {
        UserID
        accountName
        accountNumber
        bankName
        branchName
        companyID
        id
        swiftCode
      }
    }
  }  
  `;
}

export const getOrganizerByUserType = (userType, nextToken) => {
  let query = nextToken
    ? `filter: {userType: {eq: ${userType}}}, nextToken: "${nextToken}"`
    : `filter: {userType: {eq: ${userType}}}`;
  return `query MyQuery {
    listUsers(${query}) {
      items {
        id
        userType
        userName
        phoneVerificationOtp
        phoneNo
        owner
        otpValidTillDate
        organizerName
        lastName
        isPhoneVerified
        isApproved
        imageUrl
        gender
        fullName
        firstName
        email
        dob
        createdAt
        adminUserStatus
      },
      nextToken
    }
  }  
  `;
};
export const getPreRegisteredUserListByEventId = (eventId) => {
  return `query MyQuery {
    byeventIDPreRegisterUser(eventID: "${eventId}") {
      items {
        createdAt
        eventID
        id
        owner
        preRegistrationID
        userID
        user {
          adminUserStatus
          authProvider
          createdAt
          dob
          email
          firstName
          fullName
          gender
          id
          imageUrl
          isApproved
          isPhoneVerified
          lastName
          nid
          otpValidTillDate
          organizerName
          phoneNo
          owner
          phoneVerificationOtp
          userName
          userType
        }
      }
    }
  }
   
  `;
};
export const getPreRegisteredByEventId = (eventId) => {
  return `query MyQuery {
    byEventIDPreRegister(eventID: "${eventId}") {
      items {
        AudienceInfo
        discountAmount
        eventID
        exclusiveTicket
        exclusiveTicketDuration
        hasDiscount
        isActive
        isPercent
        owner
        preRegistrationEndsAt
        preRegistrationStartAt
        status
        id
      }
    }
  }
  
  `;
};
export const getPreRegisteredUsersByEventId = (eventId) => {
  return `query MyQuery {
    byeventIDPreRegisterUser(eventID: "${eventId}") {
      items {
        eventID
        id
        createdAt
        owner
        preRegistrationID
        user {
          email
          fullName
          gender
          phoneNo
          userType
        }
        userData
        userID
      }
    }
  }
  `;
};
export const getByEventIDTicketSaleMasterGuestTicket = (eventId) => {
  return `query MyQuery {
    byEventIDTicketSaleMaster(eventID: "${eventId}", filter: {isGuestTicket: {eq: true}}) {
      items {
        isDelivered
        isActive
        id
        createdAt
        eventScheduleID
        eventID
        discountMethod
        discountAmount
        deliveryOption
        deliveryAddress
        owner
        isGuestTicket
        ispersent
        paymentMethod
        paymentData
        user {
          adminUserStatus
          authMode
          authProvider
          dob
          email
          firstName
          fullName
          gender
          id
          imageUrl
          isApproved
          isPhoneVerified
          lastName
          nid
          organizerName
          otpValidTillDate
          owner
          phoneNo
          phoneVerificationOtp
          userName
          userType
        }
        trid
        totalTicket
        totalPriceBeforeDiscount
        totalPrice
        totalDiscountAmount
        pickupAddressID
        ticketSales {
          items {
            eventID
            eventScheduleID
            id
            isActive
            owner
            ticketSaleMasterID
            totalPrice
            totalTicket
            userID
            ticketInfo {
              createdAt
              eventID
              eventScheduleID
              id
              isCombo
              owner
              ticketPrice
              ticketType
              totalSold
              totalTicket
            }
            ticketID
            ticketSaleSingles {
              items {
                ticketUser
                id
              }
            }
          }
        }
        
      }
    }
  }  
  `;
};

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userName
      owner
      phoneNo
      events {
        nextToken
        __typename
      }
      tickets {
        nextToken
        __typename
      }
       isApproved
      userType
      firstName
      lastName
      fullName
      userType
      email
      imageUrl
      gender
      nid
      dob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMainSlider = /* GraphQL */ `
  query GetMainSlider($id: ID!) {
    getMainSlider(id: $id) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      sliderName
      buttonName
      buttonUrl
      imageURL
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMainSliders = /* GraphQL */ `
  query ListMainSliders(
    $filter: ModelMainSliderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMainSliders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        sliderName
        buttonName
        buttonUrl
        imageURL
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      createdByID
      eventCategory
      organizerID
      organizer {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      eventSchedules {
        nextToken
        __typename
      }
      eventContents {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      eventSponsors {
        nextToken
        __typename
      }
      userInteresteStatus {
        nextToken
        __typename
      }
      ticketSaleMaster {
        nextToken
        __typename
      }
      title
      eventType
      isLocationVenueSame
      isSameArtist
      isUpComing
      isSameTicketForAllDay
      isSamePromotionForAllDay
      isSameCouponForAllDay
      minAge
      totalInterested
      eventWillBe
      eventStatus
      isActive
      eventPhoneNo
      eventSummery
      eventPolicy
      facebookUrl
      instaUrl
      tweeterUrl
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      hasReEntry
      isTicketActive
      ticketActivationDate
      ticketDeActivationDate
      isUserInfoRequiredForEach
      userRequiredInfo
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventCategoryEvent = /* GraphQL */ `
  query ByEventCategoryEvent(
    $eventCategory: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventCategoryEvent(
      eventCategory: $eventCategory
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byOrganizerIDEvent = /* GraphQL */ `
  query ByOrganizerIDEvent(
    $organizerID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byOrganizerIDEvent(
      organizerID: $organizerID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventContent = /* GraphQL */ `
  query GetEventContent($id: ID!) {
    getEventContent(id: $id) {
      id
      eventID
      contentType
      contentUrl
      isSlider
      isMain
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEventContents = /* GraphQL */ `
  query ListEventContents(
    $filter: ModelEventContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        contentType
        contentUrl
        isSlider
        isMain
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDEventContent = /* GraphQL */ `
  query ByEventIDEventContent(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDEventContent(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        contentType
        contentUrl
        isSlider
        isMain
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventInterest = /* GraphQL */ `
  query GetEventInterest($id: ID!) {
    getEventInterest(id: $id) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      UserID
      user {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEventInterests = /* GraphQL */ `
  query ListEventInterests(
    $filter: ModelEventInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        UserID
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDEventInterest = /* GraphQL */ `
  query ByEventIDEventInterest(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDEventInterest(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        UserID
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDUserIDEventInterest = /* GraphQL */ `
  query ByEventIDUserIDEventInterest(
    $eventID: ID!
    $UserID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDUserIDEventInterest(
      eventID: $eventID
      UserID: $UserID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        UserID
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byUserIDEventInterest = /* GraphQL */ `
  query ByUserIDEventInterest(
    $UserID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserIDEventInterest(
      UserID: $UserID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        UserID
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventSchedule = /* GraphQL */ `
  query GetEventSchedule($id: ID!) {
    getEventSchedule(id: $id) {
      id
      eventID
      tickets {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      eventDate
      scheduleTitle
      eventAgeGroup
      eventDuration
      hasReEntry
      eventStartTime
      gateOpenTime
      gateCloseTime
      eventCity
      location {
        lat
        lon
        __typename
      }
      address
      venue
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEventSchedules = /* GraphQL */ `
  query ListEventSchedules(
    $filter: ModelEventScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        eventDate
        scheduleTitle
        eventAgeGroup
        eventDuration
        hasReEntry
        eventStartTime
        gateOpenTime
        gateCloseTime
        eventCity
        address
        venue
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDEventSchedule = /* GraphQL */ `
  query ByEventIDEventSchedule(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDEventSchedule(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventDate
        scheduleTitle
        eventAgeGroup
        eventDuration
        hasReEntry
        eventStartTime
        gateOpenTime
        gateCloseTime
        eventCity
        address
        venue
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventArtist = /* GraphQL */ `
  query GetEventArtist($id: ID!) {
    getEventArtist(id: $id) {
      id
      eventID
      eventScheduleID
      artistName
      artistTitle
      artistBio
      artistUrl
      artistImageUrl
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEventArtists = /* GraphQL */ `
  query ListEventArtists(
    $filter: ModelEventArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventArtists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        eventScheduleID
        artistName
        artistTitle
        artistBio
        artistUrl
        artistImageUrl
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDEventArtist = /* GraphQL */ `
  query ByEventIDEventArtist(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDEventArtist(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        artistName
        artistTitle
        artistBio
        artistUrl
        artistImageUrl
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventScheduleIDEventArtist = /* GraphQL */ `
  query ByEventScheduleIDEventArtist(
    $eventScheduleID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventScheduleIDEventArtist(
      eventScheduleID: $eventScheduleID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        artistName
        artistTitle
        artistBio
        artistUrl
        artistImageUrl
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventSponsor = /* GraphQL */ `
  query GetEventSponsor($id: ID!) {
    getEventSponsor(id: $id) {
      id
      eventID
      sponsorName
      sponsorType
      sponsorBio
      sponsorUrl
      sponsorImageUrl
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEventSponsors = /* GraphQL */ `
  query ListEventSponsors(
    $filter: ModelEventSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        sponsorName
        sponsorType
        sponsorBio
        sponsorUrl
        sponsorImageUrl
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byeventIDEventSponsor = /* GraphQL */ `
  query ByeventIDEventSponsor(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byeventIDEventSponsor(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        sponsorName
        sponsorType
        sponsorBio
        sponsorUrl
        sponsorImageUrl
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      eventID
      eventScheduleID
      ticketType
      ticketSales {
        nextToken
        __typename
      }
      ticketPrice
      totalTicket
      totalSold
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        eventScheduleID
        ticketType
        ticketPrice
        totalTicket
        totalSold
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDTicket = /* GraphQL */ `
  query ByEventIDTicket(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDTicket(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        ticketType
        ticketPrice
        totalTicket
        totalSold
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventScheduleIDTicket = /* GraphQL */ `
  query ByEventScheduleIDTicket(
    $eventScheduleID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventScheduleIDTicket(
      eventScheduleID: $eventScheduleID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        ticketType
        ticketPrice
        totalTicket
        totalSold
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicketPromotion = /* GraphQL */ `
  query GetTicketPromotion($id: ID!) {
    getTicketPromotion(id: $id) {
      id
      eventID
      eventScheduleID
      discountAmount
      ispersent
      isActive
      activationDate
      deActivationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTicketPromotions = /* GraphQL */ `
  query ListTicketPromotions(
    $filter: ModelTicketPromotionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketPromotions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        discountAmount
        ispersent
        isActive
        activationDate
        deActivationDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDTicketPromotion = /* GraphQL */ `
  query ByEventIDTicketPromotion(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketPromotionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDTicketPromotion(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        discountAmount
        ispersent
        isActive
        activationDate
        deActivationDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byventScheduleIDTicketPromotion = /* GraphQL */ `
  query ByventScheduleIDTicketPromotion(
    $eventScheduleID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketPromotionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byventScheduleIDTicketPromotion(
      eventScheduleID: $eventScheduleID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        discountAmount
        ispersent
        isActive
        activationDate
        deActivationDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicketCoupon = /* GraphQL */ `
  query GetTicketCoupon($id: ID!) {
    getTicketCoupon(id: $id) {
      id
      eventID
      eventScheduleID
      discountAmount
      ispersent
      couponCode
      maxUsedbyUser
      totalUsed
      maxUsedInTotal
      isActive
      activationDate
      deActivationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTicketCoupons = /* GraphQL */ `
  query ListTicketCoupons(
    $filter: ModelTicketCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        eventScheduleID
        discountAmount
        ispersent
        couponCode
        maxUsedbyUser
        totalUsed
        maxUsedInTotal
        isActive
        activationDate
        deActivationDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDTicketCoupon = /* GraphQL */ `
  query ByEventIDTicketCoupon(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDTicketCoupon(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        discountAmount
        ispersent
        couponCode
        maxUsedbyUser
        totalUsed
        maxUsedInTotal
        isActive
        activationDate
        deActivationDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byventScheduleIDTicketCoupon = /* GraphQL */ `
  query ByventScheduleIDTicketCoupon(
    $eventScheduleID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byventScheduleIDTicketCoupon(
      eventScheduleID: $eventScheduleID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        discountAmount
        ispersent
        couponCode
        maxUsedbyUser
        totalUsed
        maxUsedInTotal
        isActive
        activationDate
        deActivationDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicketUsedCoupon = /* GraphQL */ `
  query GetTicketUsedCoupon($id: ID!) {
    getTicketUsedCoupon(id: $id) {
      id
      eventID
      eventScheduleID
      userID
      couponID
      ticketID
      ticketSaleID
      ticketSaleSingleID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTicketUsedCoupons = /* GraphQL */ `
  query ListTicketUsedCoupons(
    $filter: ModelTicketUsedCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketUsedCoupons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        userID
        couponID
        ticketID
        ticketSaleID
        ticketSaleSingleID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDTicketUsedCoupon = /* GraphQL */ `
  query ByEventIDTicketUsedCoupon(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketUsedCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDTicketUsedCoupon(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        userID
        couponID
        ticketID
        ticketSaleID
        ticketSaleSingleID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicketSaleMaster = /* GraphQL */ `
  query GetTicketSaleMaster($id: ID!) {
    getTicketSaleMaster(id: $id) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      eventScheduleID
      ticketSales {
        nextToken
        __typename
      }
      userID
      user {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      totalTicket
      totalPrice
      eventDate
      paymentMethod
      paymentData
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTicketSaleMasters = /* GraphQL */ `
  query ListTicketSaleMasters(
    $filter: ModelTicketSaleMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSaleMasters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        userID
        totalTicket
        totalPrice
        eventDate
        paymentMethod
        paymentData
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDTicketSaleMaster = /* GraphQL */ `
  query ByEventIDTicketSaleMaster(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDTicketSaleMaster(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        userID
        totalTicket
        totalPrice
        eventDate
        paymentMethod
        paymentData
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byOrganizerIDTickets = /* GraphQL */ `
  query ByOrganizerIDTickets(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byOrganizerIDTickets(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        userID
        totalTicket
        totalPrice
        eventDate
        paymentMethod
        paymentData
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byOrganizerIDEventDate = /* GraphQL */ `
  query ByOrganizerIDEventDate(
    $eventDate: AWSDateTime!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byOrganizerIDEventDate(
      eventDate: $eventDate
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        userID
        totalTicket
        totalPrice
        eventDate
        paymentMethod
        paymentData
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicketSale = /* GraphQL */ `
  query GetTicketSale($id: ID!) {
    getTicketSale(id: $id) {
      id
      eventID
      eventScheduleID
      ticketSaleMasterID
      ticketSaleSingles {
        nextToken
        __typename
      }
      ticketID
      userID
      totalTicket
      totalPrice
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTicketSales = /* GraphQL */ `
  query ListTicketSales(
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        eventScheduleID
        ticketSaleMasterID
        ticketID
        userID
        totalTicket
        totalPrice
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byTicketSaleMasterIDTicketSale = /* GraphQL */ `
  query ByTicketSaleMasterIDTicketSale(
    $ticketSaleMasterID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTicketSaleMasterIDTicketSale(
      ticketSaleMasterID: $ticketSaleMasterID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        ticketSaleMasterID
        ticketID
        userID
        totalTicket
        totalPrice
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byTicketIDTicketSale = /* GraphQL */ `
  query ByTicketIDTicketSale(
    $ticketID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTicketIDTicketSale(
      ticketID: $ticketID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventScheduleID
        ticketSaleMasterID
        ticketID
        userID
        totalTicket
        totalPrice
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicketSaleSingle = /* GraphQL */ `
  query GetTicketSaleSingle($id: ID!) {
    getTicketSaleSingle(id: $id) {
      id
      eventID
      ticketID
      userID
      ticketSaleID
      ticketCode
      ticketUser
      ticketPrice
      isEntryDone
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTicketSaleSingles = /* GraphQL */ `
  query ListTicketSaleSingles(
    $filter: ModelTicketSaleSingleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSaleSingles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        ticketID
        userID
        ticketSaleID
        ticketCode
        ticketUser
        ticketPrice
        isEntryDone
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEventIDUserIDTicketSaleSingle = /* GraphQL */ `
  query ByEventIDUserIDTicketSaleSingle(
    $eventID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleSingleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEventIDUserIDTicketSaleSingle(
      eventID: $eventID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        ticketID
        userID
        ticketSaleID
        ticketCode
        ticketUser
        ticketPrice
        isEntryDone
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byTicketSaleIDTicketSaleSingle = /* GraphQL */ `
  query ByTicketSaleIDTicketSaleSingle(
    $ticketSaleID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleSingleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTicketSaleIDTicketSaleSingle(
      ticketSaleID: $ticketSaleID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        ticketID
        userID
        ticketSaleID
        ticketCode
        ticketUser
        ticketPrice
        isEntryDone
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byTicketCodeTicketSaleSingle = /* GraphQL */ `
  query ByTicketCodeTicketSaleSingle(
    $ticketCode: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleSingleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTicketCodeTicketSaleSingle(
      ticketCode: $ticketCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        ticketID
        userID
        ticketSaleID
        ticketCode
        ticketUser
        ticketPrice
        isEntryDone
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import React, { useEffect, useState } from "react";
import { v4 } from "uuid";

const EventBannerSingleItem = ({
  index,
  currentImage,
  mainData,
  deleteCurrentBanner,
  contentImageDatas,
  setContentImageDatas,
  contentImages,
  setContentImages,
}) => {
  const [addArtist, setAddArtist] = useState(true);
  const [addImageToState, setAddImageToState] = useState(true);
  const s3bucketurl = process.env.REACT_APP_S3_BUCKET_URL;
  const [selectedImage, setSelectedImage] = useState({
    name: "",
    data: "",
  });
  const s3BucketUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const [bannerSingleContent, setBannerSingleContent] = useState({
    eventID: "",
    contentType: "image",
    contentUrl: "",
    isSlider: false,
    isMain: true,
    isActive: true,
  });
  const clearFileInput = () => {
    const fileInput = document.getElementById("artistAvatar");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      let fileName = file.name.split(".")[0];
      let ext = file.name.split(".")[1];
      fileName = v4();

      reader.onload = () => {
        const imageData = reader.result;
        let newCurrentImage = {
          ...currentImage,
          contentUrl: `${fileName}.${ext}`,
        };
        setContentImages([...contentImages, newCurrentImage]);
        setSelectedImage({
          name: `${fileName}.${ext}`,
          data: imageData,
          file: file,
        });
      };

      reader.readAsDataURL(file);
    } else {
      console.error("Invalid file type. Please select an image.");
    }
  };

  const getImageData = (artistImageUrl) => {
    const imageData = contentImageDatas.find(
      (image) => image.name === artistImageUrl
    );
    return imageData.data;
  };

  useEffect(() => {
    if (selectedImage?.name) {
      setContentImageDatas((prevData) => [...prevData, selectedImage]);
      setBannerSingleContent((prevData) => ({
        ...prevData,
        contentUrl: selectedImage?.name,
        eventID: mainData.id,
      }));
      setAddImageToState(false);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (addImageToState && bannerSingleContent.contentUrl) {
      setContentImageDatas((preveData) => [...preveData, bannerSingleContent]);
      setAddImageToState(false);
    }
  }, [addImageToState]);
  return (
    <>
      {currentImage?.id ? (
        <div className="dash_main_main_table_form_up h-5">
          <img src={`${s3bucketurl}${currentImage.contentUrl}`} />
        </div>
      ) : (
        <input
          type="file"
          id="artistAvatar"
          name="cf_photo"
          onDrop={handleDrop}
          onDragOver={handleDrop}
          onChange={handleDrop}
          accept="image/*"
        />
      )}
    </>
  );
};

export default EventBannerSingleItem;

import { Button } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GraphQLArtistDelete } from "services/api-call";

const ArtistListSingle = ({
  artist,
  index,
  isEditArtist,
  setIsEditArtist,
  getImageData,
  customErrors,
  setCustomErrors,
  currentArtist,
  setCurrentArtist,
  handleDrop,
  addArtistToList,
  selectedImage,
  handleDelete,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const s3_BucketUrl = process.env.REACT_APP_S3_BUCKET_URL;

  const deleteArtist = (artistId) => {
    GraphQLArtistDelete(artistId)
      .then((data) => data)
      .catch((err) => {
        console.error("delete artist error: ", err);
      });
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "center", horizontal: "center" }} // This sets the Snackbar to appear in the center of the page
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity="info"
          style={{ fontSize: "1.2rem", padding: "20px" }}
          action={
            <div>
              <Button
                color="error"
                size="large" // Adjusted size to "large"
                onClick={() => {
                  handleDelete(index);
                  deleteArtist(artist?.id);
                  setOpenSnackbar(false);
                }}
                style={{ fontSize: "1.2rem" }} // Adjusted font size
              >
                Yes
              </Button>
              <Button
                color="primary"
                size="large"
                style={{ fontSize: "1.2rem" }} // Adjusted font size
                onClick={() => setOpenSnackbar(false)}
              >
                {" "}
                {/* Adjusted size to "large" */}
                No
              </Button>
            </div>
          }
        >
          Are you sure you want to delete this event?
        </MuiAlert>
      </Snackbar>
      {isEditArtist.index !== index ? (
        <tr key={index}>
          <td>{artist?.shortOrder}</td>
          <td>
            {artist.artistImageUrl &&
              (artist?.artistImageUrl?.includes("public/") ? (
                <img src={`${s3_BucketUrl}${artist.artistImageUrl}`} />
              ) : (
                <img src={`${getImageData(artist.artistImageUrl)}`} />
              ))}
          </td>
          <td>{artist.artistName}</td>
          <td>{artist.artistTitle}</td>
          <td>{artist.artistBio}</td>
          <td>{artist.artistUrl}</td>
          <td>
            <div className="d-flex align-items-center justify-content-end">
              <Link
                to=""
                className="btn btn-primary p-2"
                onClick={(e) =>
                  setIsEditArtist({
                    state: true,
                    index: index,
                  })
                }
              >
                Edit
              </Link>
              <Link
                to=""
                className="btn btn-danger p-2"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenSnackbar(true);
                }}
              >
                Delete
              </Link>
            </div>
          </td>
        </tr>
      ) : isEditArtist.state === false ? (
        <tr key={index}>
          <td>{artist?.shortOrder}</td>
          <td>
            {artist.artistImageUrl && (
              <img src={getImageData(artist?.artistImageUrl)} />
            )}
          </td>
          <td>{artist.artistName}</td>
          <td>{artist.artistTitle}</td>
          <td>{artist.artistBio}</td>
          <td>{artist.artistUrl}</td>
          <td>
            <div className="d-flex align-items-center justify-content-end">
              <Link
                to=""
                className="btn btn-primary p-2"
                onClick={(e) =>
                  setIsEditArtist({
                    state: true,
                    index: index,
                  })
                }
              >
                Edit
              </Link>
              <Link
                to=""
                className="btn btn-danger p-2"
                onClick={(e) => {
                  setDeleteIndex(`${index}`);
                }}
              >
                Remove
              </Link>
            </div>
          </td>
        </tr>
      ) : (
        <tr>
          <td colSpan="7">
            <div className="dash_main_main_table_form oflow-hd">
              <div className="row">
                <div className="col-md-6">
                  <div className="single_input_box">
                    <label htmlFor="#" style={{ textAlign: "start" }}>
                      Name
                    </label>
                    <input
                      type="text"
                      value={currentArtist?.artistName}
                      onChange={(e) => {
                        setCurrentArtist({
                          ...currentArtist,
                          artistName: e.target.value,
                        });
                        setCustomErrors({
                          ...customErrors,
                          artistName: "",
                        });
                      }}
                      placeholder="Name"
                    />
                    {customErrors?.artistName && (
                      <p className="text-danger">{customErrors.artistName}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single_input_box">
                    <label htmlFor="#" style={{ textAlign: "start" }}>
                      Url
                    </label>
                    <input
                      type="text"
                      value={currentArtist?.artistUrl}
                      onChange={(e) => {
                        setCurrentArtist({
                          ...currentArtist,
                          artistUrl: e.target.value,
                        });
                      }}
                      placeholder="URL"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single_input_box">
                    <label htmlFor="#" style={{ textAlign: "start" }}>
                      Title
                    </label>
                    <input
                      type="text"
                      value={currentArtist?.artistTitle}
                      onChange={(e) => {
                        setCurrentArtist({
                          ...currentArtist,
                          artistTitle: e.target.value,
                        });
                        setCustomErrors({
                          ...customErrors,
                          artistTitle: "",
                        });
                      }}
                      placeholder="Title"
                    />
                    {customErrors?.artistTitle && (
                      <p className="text-danger">{customErrors.artistTitle}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="single_input_box">
                    <label htmlFor="#">Sort Order</label>
                    <input
                      type="number"
                      value={currentArtist?.shortOrder}
                      onChange={(e) => {
                        setCurrentArtist({
                          ...currentArtist,
                          shortOrder:
                            typeof e.target.value === "number"
                              ? e.target.value
                              : parseInt(e.target.value),
                        });
                        setCustomErrors({
                          ...customErrors,
                          shortOrder: "",
                        });
                      }}
                      placeholder="Sort Order"
                    />
                    {customErrors?.shortOrder && (
                      <p className="text-danger">{customErrors.shortOrder}</p>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="single_input_box">
                    <label htmlFor="#" style={{ textAlign: "start" }}>
                      Bio
                    </label>
                    <textarea
                      value={currentArtist?.artistBio}
                      onChange={(e) =>
                        setCurrentArtist({
                          ...currentArtist,
                          artistBio: e.target.value,
                        })
                      }
                      placeholder="Bio"
                    ></textarea>
                  </div>
                  <div
                    className="dash_main_main_tab le_form_up d-flex mt-2 single_input_box"
                    style={{ flexDirection: "column", alignItems: "start" }}
                  >
                    <label htmlFor="#">Image</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        {selectedImage && selectedImage?.name ? (
                          <>
                            <img src={`${selectedImage?.data}`} />
                          </>
                        ) : (
                          currentArtist.artistImageUrl &&
                          (currentArtist?.artistImageUrl?.includes(
                            "public/"
                          ) ? (
                            <>
                              <img
                                src={`${s3_BucketUrl}${currentArtist.artistImageUrl}`}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={`${getImageData(
                                  currentArtist.artistImageUrl
                                )}`}
                              />
                            </>
                          ))
                        )}
                      </div>
                      <input
                        type="file"
                        id="artistAvatar"
                        name="cf_photo"
                        onDrop={handleDrop}
                        onDragOver={handleDrop}
                        onChange={handleDrop}
                        accept="image/*"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="dash_main_main_table_form_action">
                    <ul>
                      <ul>
                        <li>
                          <Link
                            to=""
                            onClick={() => {
                              setIsEditArtist({ state: false, index: "" });
                            }}
                          >
                            Cancel
                          </Link>
                        </li>
                        <li>
                          <button
                            onClick={addArtistToList}
                            // disable={!selectedImage?.name}
                          >
                            Save
                          </button>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default ArtistListSingle;

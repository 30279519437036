import ArtitstListMultiScheduleDiff from "./ArtitstListMultiScheduleDiff";

const EventMultiScheduleDifferentArtist = ({
  setIsSubmitForm,
  isSubmitForm,
  step,
  triggerSubmit,
  schedules,
  setSchedules,
  mainData,
  setMainData,
  artistList,
  setArtistList,
  artistImages,
  setArtistImages,
}) => {
  return (
    <>
      <div className="artst_table artst_table2">
        {schedules.map((schedule, index) => (
          <div className="artst_table2_single" key={index}>
            <h2>Day {index + 1}</h2>
            <ArtitstListMultiScheduleDiff
              setIsSubmitForm={setIsSubmitForm}
              index={index}
              schedule={schedule}
              setSchedules={setSchedules}
              mainData={mainData}
              artistList={artistList}
              setArtistList={setArtistList}
              artistImages={artistImages}
              setArtistImages={setArtistImages}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default EventMultiScheduleDifferentArtist;

import { Card, CircularProgress } from "@mui/material";
import CustomButton from "components/shared/Button";
import moment from "moment";
import React, { useState } from "react";
import { S3BucketDeleteFile } from "services/s3BucketDeleteFIle";
const S3Bucket =
  "https://tickettomorrowstaticbucket192143-test.s3.ap-southeast-1.amazonaws.com/ticketPDF";

const SinglePDFItem = ({ pdf, selectedEvent, getPDFList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  let pdfInfo = pdf.Key.split("/")[2];
  let ticketType = pdfInfo.split("#")[2].split(".")[0];
  let ticketPurchaseTime = moment(pdfInfo.split("#")[1]).format(
    "Do MMM, YYYY hh:mm a"
  );

  let encodedUrl = `${S3Bucket}/${selectedEvent.id}/${encodeURIComponent(
    pdfInfo
  )}`;

  const deletePdf = (key) => {
    setIsDeleting(true);
    S3BucketDeleteFile(key).then((data) => {
      setIsDeleting(false);
      getPDFList();
    });
  };
  return (
    <Card variant="outlined" style={{ marginBottom: "5px", padding: "10px" }}>
      <div>PDF Generate Time: {ticketPurchaseTime}</div>
      <div>Ticket Type: {ticketType}</div>
      <div>
        <CustomButton
          customVariant="successContained"
          href={encodedUrl}
          //   disabled={isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress size={15} /> Downloading...
            </>
          ) : (
            <>Download PDF</>
          )}
        </CustomButton>
        <CustomButton
          customVariant="dangerContained"
          onClick={() => deletePdf(pdf.Key)}
          disabled={isDeleting}
        >
          {isDeleting ? (
            <>
              <CircularProgress size={15} /> Deleting...
            </>
          ) : (
            <>Delete PDF</>
          )}
        </CustomButton>
      </div>
    </Card>
  );
};

export default SinglePDFItem;

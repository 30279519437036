export const initialState = {
  info: null,
};

import { createSlice } from "@reduxjs/toolkit";

export const preRegister = createSlice({
  name: "preRegister",
  initialState: initialState,
  reducers: {
    setPreRegisterInfo: (state, action) => {
      state.info = action.payload;
    },
    clearPreRegisterInfo: (state, action) => {
      state.info = null;
    },
  },
});

export const { setPreRegisterInfo, clearPreRegisterInfo } = preRegister.actions;

export default preRegister.reducer;

import CustomModal from "components/shared/CustomModal";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { setSingleLocation } from "store/slices/eventData";

const EventMultiScheduleSingleVenue = ({
  isSubmitForm,
  setIsSubmitForm,
  schedules,
  setSchedules,
  sameScheduleData,
  setSameScheduleData,
  isMultiEventVenueEmpty,
  setIsMultiEventVenueEmpty,
}) => {
  const [scheduleLatitude, setScheduleLatitude] = useState(
    schedules[0]?.location?.lat || 23.804193153061725
  );
  const [scheduleLongitude, setScheduleLongitude] = useState(
    schedules[0]?.location?.lon || 90.36392211914062
  );
  const [scheduleAddress, setScheduleAddress] = useState(schedules[0]?.address);
  const [scheduleVenue, setScheduleVenue] = useState(schedules[0]?.venue);
  const [scheduleCity, setScheduleCity] = useState(schedules[0]?.eventCity);
  const [errors, setErrors] = useState(null);
  const [isLoadScheduleData, setisLoadScheduleData] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (schedules?.length > 0 && isLoadScheduleData) {
  //     let newSchedulesData = schedules?.map((schedule) => {
  //       schedule.address = scheduleAddress;
  //       schedule.venue = scheduleVenue;
  //       schedule.eventCity = scheduleCity;

  //       return schedule;
  //     });
  //     setSchedules(newSchedulesData);
  //     setisLoadScheduleData(false);
  //   }
  // }, [schedules[0]]);

  const handleChangeData = (dataObj) => {
    const newArray = schedules.map((item) => ({
      ...item,
      ...dataObj,
    }));
    setSchedules(newArray);
  };

  useEffect(() => {
    if (scheduleLatitude && scheduleLongitude) {
      let newArray = schedules.map((item) => ({
        ...item,
        location: {
          ...item.location,
          lat: scheduleLatitude,
          lon: scheduleLongitude,
        },
      }));
      dispatch(
        setSingleLocation({
          lat: scheduleLatitude,
          lon: scheduleLongitude,
        })
      );
      setSchedules(newArray);
      setErrors({ ...errors, scheduleLatitude: null, scheduleLongitude: null });
      setIsMultiEventVenueEmpty(false);
    }
  }, [scheduleLatitude, scheduleLongitude]);

  useEffect(() => {
    if (scheduleAddress) {
      let newArray = schedules.map((item) => ({
        ...item,
        address: scheduleAddress,
      }));
      setSchedules(newArray);
    }
  }, [scheduleAddress]);

  useEffect(() => {
    if (scheduleVenue) {
      let newArray = schedules.map((item) => ({
        ...item,
        venue: scheduleVenue,
      }));
      setSchedules(newArray);
    }
  }, [scheduleVenue]);

  useEffect(() => {
    if (scheduleCity) {
      let newArray = schedules.map((item) => ({
        ...item,
        eventCity: scheduleCity,
      }));
      setSchedules(newArray);
    }
  }, [scheduleCity]);

  useEffect(() => {
    if (isSubmitForm) {
      let updatedErrors = { ...errors };

      // if (!scheduleLatitude) {
      //   updatedErrors.scheduleLatitude = "Latitude is required";
      // }

      // if (!scheduleLongitude) {
      //   updatedErrors.scheduleLongitude = "Longitude is required";
      // }

      if (!scheduleAddress) {
        updatedErrors.scheduleAddress = "Address is required";
      }

      if (!scheduleVenue) {
        updatedErrors.scheduleVenue = "Venue is required";
      }

      if (!scheduleCity) {
        updatedErrors.scheduleCity = "City is required";
      }
      if (!scheduleAddress || !scheduleVenue || !scheduleCity) {
        setIsMultiEventVenueEmpty(true);
        setIsSubmitForm(false);
      } else {
        setIsMultiEventVenueEmpty(false);
      }
      setErrors(updatedErrors);
    }
  }, [isSubmitForm]);
  return (
    <>
      <div className="multifieldrow_wrapper ec_location_venue ec_location_venue1">
        <div className="multifieldrow_wrapper_single">
          <div className="row multifieldrow">
            <div className="col-12" style={{ padding: 0, margin: "8px auto" }}>
              <div className="single_input_box">
                <label htmlFor="#">
                  Latitude and Longitude{" "}
                  <Button variant="text" onClick={() => setOpenModal(true)}>
                    Click here to get the lat, long
                  </Button>
                </label>
                <div className="multifieldrow_wrapper">
                  <div className="multifieldrow_wrapper_single">
                    <div className="row multifieldrow">
                      <div className="col-md-6">
                        <div className="single_input_box">
                          {/* <label htmlFor="#">Latitude</label> */}
                          <input
                            type="number"
                            value={scheduleLatitude}
                            placeholder="Latitude"
                            readOnly
                          />
                          {errors?.scheduleLatitude && (
                            <p className="text-danger">
                              {errors?.scheduleLatitude}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single_input_box">
                          {/* <label htmlFor="#">Longitude</label> */}
                          <input
                            type="number"
                            value={scheduleLongitude}
                            placeholder="Longitude"
                            readOnly
                          />
                          {errors?.scheduleLongitude && (
                            <p className="text-danger">
                              {errors?.scheduleLongitude}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single_input_box">
                <label htmlFor="#">Address</label>
                <input
                  type="text"
                  value={scheduleAddress}
                  onChange={(e) => {
                    setScheduleAddress(e.target.value);
                    setSameScheduleData({
                      ...sameScheduleData,
                      address: e.target.value,
                    });
                    setErrors({ ...errors, scheduleAddress: null });
                    setIsMultiEventVenueEmpty(false);
                  }}
                  placeholder="Address"
                />
                {errors?.scheduleAddress && (
                  <p className="text-danger">{errors?.scheduleAddress}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="single_input_box">
                <label htmlFor="#">Venue</label>
                <input
                  type="text"
                  value={scheduleVenue}
                  onChange={(e) => {
                    setScheduleVenue(e.target.value);
                    setSameScheduleData({
                      ...sameScheduleData,
                      venue: e.target.value,
                    });
                    setErrors({ ...errors, scheduleVenue: null });
                    setIsMultiEventVenueEmpty(false);
                  }}
                  placeholder="Venue"
                />
                {errors?.scheduleVenue && (
                  <p className="text-danger">{errors?.scheduleVenue}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="single_input_box">
                <label htmlFor="#">City</label>
                <input
                  type="text"
                  value={scheduleCity}
                  onChange={(e) => {
                    setScheduleCity(e.target.value);
                    setSameScheduleData({
                      ...sameScheduleData,
                      eventCity: e.target.value,
                    });
                    setErrors({ ...errors, scheduleCity: null });
                    setIsMultiEventVenueEmpty(false);
                  }}
                  placeholder="City"
                />
                {errors?.scheduleCity && (
                  <p className="text-danger">{errors?.scheduleCity}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={openModal}
        setOpen={setOpenModal}
        scheduleLatitude={scheduleLatitude}
        scheduleLongitude={scheduleLongitude}
        setScheduleLatitude={setScheduleLatitude}
        setScheduleLongitude={setScheduleLongitude}
      />
    </>
  );
};

export default EventMultiScheduleSingleVenue;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import CustomModal from "components/shared/CustomModal";

const EventMultiScheduleMultiVenueItem = ({
  index,
  schedule,
  isSubmitForm,
  setIsSubmitForm,
  handleChangeData,
  isMultiEventVenueEmpty,
  setIsMultiEventVenueEmpty,
}) => {
  const [scheduleLatitude, setScheduleLatitude] = useState(
    schedule?.location?.lat || 23.804193153061725
  );
  const [scheduleLongitude, setScheduleLongitude] = useState(
    schedule?.location?.lon || 90.36392211914062
  );
  const [scheduleAddress, setScheduleAddress] = useState(schedule?.address);
  const [scheduleVenue, setScheduleVenue] = useState(schedule?.venue);
  const [scheduleCity, setScheduleCity] = useState(schedule?.eventCity);
  const [errors, setErrors] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSubmitForm) {
      let updatedErrors = { ...errors };

      if (!scheduleLatitude) {
        updatedErrors.scheduleLatitude = "Latitude is required";
      }

      if (!scheduleLongitude) {
        updatedErrors.scheduleLongitude = "Longitude is required";
      }

      if (!scheduleAddress) {
        updatedErrors.scheduleAddress = "Address is required";
      }

      if (!scheduleVenue) {
        updatedErrors.scheduleVenue = "Venue is required";
      }

      if (!scheduleCity) {
        updatedErrors.scheduleCity = "City is required";
      }
      if (!scheduleAddress || !scheduleVenue || !scheduleCity) {
        setIsMultiEventVenueEmpty(true);
        setIsSubmitForm(false);
      } else {
        setIsMultiEventVenueEmpty(false);
      }
      setErrors(updatedErrors);
    }
  }, [isSubmitForm]);

  useEffect(() => {
    if (scheduleLatitude) {
      handleChangeData(schedule.id, {
        location: { lat: scheduleLatitude },
      });
      setErrors({ ...errors, scheduleLatitude: null });
      setIsMultiEventVenueEmpty(false);
    }
  }, [scheduleLatitude]);

  useEffect(() => {
    if (scheduleLatitude) {
      handleChangeData(schedule.id, {
        location: { lon: scheduleLongitude },
      });
      setErrors({ ...errors, scheduleLongitude: null });
      setIsMultiEventVenueEmpty(false);
    }
  }, [scheduleLongitude]);

  return (
    <>
      <div className="multifieldrow_wrapper_single mb-5">
        <h5 className="pb-2">{`Day ${index + 1}`}</h5>

        <div className="multifieldrow_wrapper">
          <div className="col-12" style={{ padding: 0, margin: "8px auto" }}>
            <div className="single_input_box">
              <label htmlFor="#">
                Latitude and Longitude{" "}
                <Button variant="text" onClick={() => setOpenModal(true)}>
                  Click here to get the lat, long
                </Button>
              </label>
              <div className="multifieldrow_wrapper_single">
                <div className="row multifieldrow">
                  <div className="col-md-6">
                    <div className="single_input_box">
                      <input
                        type="number"
                        value={scheduleLatitude}
                        onChange={(e) => {
                          handleChangeData(schedule.id, {
                            location: { lat: e.target.value },
                          });
                          setErrors({ ...errors, scheduleLatitude: null });
                          setIsMultiEventVenueEmpty(false);
                        }}
                        placeholder="Latitude"
                        readOnly
                      />
                      {errors?.scheduleLatitude && (
                        <p className="text-danger">
                          {errors?.scheduleLatitude}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single_input_box">
                      <input
                        type="number"
                        value={scheduleLongitude}
                        onChange={(e) => {
                          handleChangeData(schedule.id, {
                            location: { lon: e.target.value },
                          });
                          setErrors({ ...errors, scheduleLongitude: null });
                          setIsMultiEventVenueEmpty(false);
                        }}
                        placeholder="Longitude"
                        readOnly
                      />
                      {errors?.scheduleLongitude && (
                        <p className="text-danger">
                          {errors?.scheduleLongitude}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row multifieldrow">
          <div className="col-md-6">
            <div className="single_input_box">
              <label htmlFor="#">Address</label>
              <input
                type="text"
                value={scheduleAddress}
                onChange={(e) => {
                  setScheduleAddress(e.target.value);
                  handleChangeData(schedule.id, {
                    address: e.target.value,
                  });
                  setErrors({ ...errors, scheduleAddress: null });
                  setIsMultiEventVenueEmpty(false);
                }}
                placeholder="Address"
              />
              {errors?.scheduleAddress && (
                <p className="text-danger">{errors?.scheduleAddress}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="single_input_box">
              <label htmlFor="#">Venue</label>
              <input
                type="text"
                value={scheduleVenue}
                onChange={(e) => {
                  setScheduleVenue(e.target.value);
                  handleChangeData(schedule.id, {
                    venue: e.target.value,
                  });
                  setErrors({ ...errors, scheduleVenue: null });
                  setIsMultiEventVenueEmpty(false);
                }}
                placeholder="Venue"
              />
              {errors?.scheduleVenue && (
                <p className="text-danger">{errors?.scheduleVenue}</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="single_input_box">
              <label htmlFor="#">City</label>
              <input
                type="text"
                value={scheduleCity}
                onChange={(e) => {
                  setScheduleCity(e.target.value);
                  handleChangeData(schedule.id, {
                    eventCity: e.target.value,
                  });
                  setErrors({ ...errors, scheduleCity: null });
                  setIsMultiEventVenueEmpty(false);
                }}
                placeholder="City"
              />
              {errors?.scheduleCity && (
                <p className="text-danger">{errors?.scheduleCity}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={openModal}
        setOpen={setOpenModal}
        scheduleLatitude={scheduleLatitude}
        scheduleLongitude={scheduleLongitude}
        setScheduleLatitude={setScheduleLatitude}
        setScheduleLongitude={setScheduleLongitude}
      />
    </>
  );
};

export default EventMultiScheduleMultiVenueItem;

import HeaderTitle from "components/common/headerTitle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { GraphQLGetTicketsByEventId } from "services/api-call";
import SingleTicket from "./SingleTicket";

const TicketReportSingle = () => {
  const { id } = useParams();
  const slug = useLocation();
  const pathname = slug.pathname.split("/")[2];
  const [currentEvent, setCurrentEvent] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [totalCollections, setTotalCollections] = useState(0);

  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    GraphQLGetTicketsByEventId(id).then((r) => {
      setCurrentEvent(r?.data?.getEvent);
      let newTotalSales = 0;
      r?.data?.getEvent?.tickets?.items?.forEach((item) => {
        item?.ticketSales?.items?.forEach((ticketSale) => {
          newTotalSales = newTotalSales + ticketSale.totalPrice;
        });
      });

      setTotalCollections(newTotalSales);
      setSchedules(r?.data?.getEvent?.eventSchedules?.items);
    });
  }, []);

  return (
    <>
      <div className="dash_content_main oflow-hd">
        <HeaderTitle id={id} />
        <div className="dash_content_main_center">
          <div className="dash_content_main_center_with_padding">
            <div className="row">
              <div className="col-md-6 mx-auto">
                <div className="form_left_col_ticket_wrapper oflow-hd">
                  <div className="form_left_col_ticket_wrapper_title oflow-hd">
                    <h2>Total Ticket Sell</h2>
                    <Link to={`/tickets-details/${id}`}>
                      Show Ticketing Details
                    </Link>
                  </div>
                  <div className="form_left_col_ticket_wrapper_days oflow-hd">
                    {currentEvent.isSameTicketForAllDay
                      ? currentEvent?.tickets?.items?.length > 0 && (
                          <SingleTicket
                            schedule={schedules[0]}
                            index={0}
                            tickets={currentEvent?.tickets?.items}
                            event={currentEvent}
                          />
                        )
                      : schedules?.length > 0 &&
                        schedules?.map((schedule, index) => (
                          <>
                            <SingleTicket
                              key={index}
                              schedule={schedule}
                              index={index}
                              tickets={schedule?.tickets?.items}
                              event={currentEvent}
                            />
                          </>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketReportSingle;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-1",
  aws_cloud_logic_custom: [
    {
      name: "paymentwebhook",
      endpoint:
        "https://qssxr522ig.execute-api.ap-southeast-1.amazonaws.com/test",
      region: "ap-southeast-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://z7zsovvukvcafomyyh4evbehs4.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-southeast-1",
  aws_appsync_authenticationType: "AWS_IAM",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:acc33ecc-f257-458d-ac3d-53206a6bdf0d",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_JZmvfiNPr",
  aws_user_pools_web_client_id: "cfivg29i9l2mbrdlah0nacakg",
  oauth: {
    domain:
      "tickettomorrow2096478f-2096478f-test.auth.ap-southeast-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "http://localhost:3000/",
    redirectSignOut: "http://localhost:3000/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  aws_cognito_username_attributes: ["EMAIL", "PHONE_NUMBER"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "tickettomorrowstaticbucket192143-test",
  aws_user_files_s3_bucket_region: "ap-southeast-1",
};

export default awsmobile;

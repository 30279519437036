export const initialState = {
  eventInfo: null,
  eventConfig: null,
  eventSchedules: null,
  eventContent: null,
  eventTermsCondition: null,
  isMultipleVenueEmpty: false,
  singleLocation: null,
};

import { createSlice } from "@reduxjs/toolkit";

export const eventData = createSlice({
  name: "eventData",
  initialState: initialState,
  reducers: {
    setEventInfo: (state, action) => {
      state.eventInfo = action.payload;
    },
    setEventConfig: (state, action) => {
      state.eventConfig = action.payload;
    },
    setEventSchedules: (state, action) => {
      let newData = action.payload;
      newData = newData?.sort((a, b) => {
        return new Date(a?.eventDate) - new Date(b?.eventDate);
      });
      state.eventSchedules = newData;
    },
    setEventContent: (state, action) => {
      state.eventContent = action.payload;
    },
    setEventTermsCondition: (state, action) => {
      state.eventTermsCondition = action.payload;
    },

    setIsMultipleVenueEmpty: (state, action) => {
      state.isMultipleVenueEmpty = action.payload;
    },
    setSingleLocation: (state, action) => {
      state.singleLocation = action.payload;
    },
  },
});

export const {
  setEventInfo,
  setEventConfig,
  setEventSchedules,
  setEventContent,
  setEventTermsCondition,
  setIsMultipleVenueEmpty,
  setSingleLocation,
} = eventData.actions;

export default eventData.reducer;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArtistList from "./ArtitstList";
import ArtitstListMultiSchedule from "./ArtitstListMultiSchedule";

const EventMultiScheduleSameArtist = ({
  setIsSubmitForm,
  isSubmitForm,
  step,
  triggerSubmit,
  schedules,
  setSchedules,
  mainData,
  setMainData,
  artistList,
  setArtistList,
  artistImages,
  setArtistImages,
}) => {
  return (
    <>
      <ArtitstListMultiSchedule
        setIsSubmitForm={setIsSubmitForm}
        iseSubmitForm={isSubmitForm}
        schedules={schedules}
        setSchedules={setSchedules}
        mainData={mainData}
        artistList={artistList}
        setArtistList={setArtistList}
        artistImages={artistImages}
        setArtistImages={setArtistImages}
      />
    </>
  );
};

export default EventMultiScheduleSameArtist;

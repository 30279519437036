import { Link } from "react-router-dom";

const ManageSettingButtons = ({ id, baseUrl }) => {
  return (
    <div className="dash_content_main_event_head_action oflow-hd">
      <ul>
        <li>
          {/* <Link to="/create-event">Edit Event Details</Link> */}
          <Link to={`/edit-event/${id}`}>Edit Event Details</Link>
        </li>
        <li>
          <Link to={`/single-event-settings/${id}`}>Event Settings</Link>
        </li>
        <li>
          <a
            href={`${baseUrl}event/${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Public Preview
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ManageSettingButtons;

import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { API } from "aws-amplify";
import {
  listEventNoAuth,
  listEventNoAuthOrganiser,
  listEventsCustom,
} from "customGraphQL/query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SingleTicket from "./SingleTicket";
import CustomButton from "components/shared/Button";
import moment from "moment";
import PrintTicketContainer from "./PrintTicketContainer";
import { targetedEventList } from "utils/utility";

function sortByDate(array, direction) {
  const newArray = array.slice();

  // Define a comparison function based on the direction
  const compare = (a, b) => {
    const dateA = new Date(a.eventDate);
    const dateB = new Date(b.eventDate);

    if (direction === "asc") {
      return dateA - dateB;
    } else if (direction === "desc") {
      return dateB - dateA;
    } else {
      throw new Error(
        'Invalid direction parameter. Please use "asc" or "desc".'
      );
    }
  };

  // Sort the cloned array using the comparison function
  newArray.sort(compare);

  return newArray;
}

const PrintTicket = () => {
  const slug = useLocation();
  const pathname = slug.pathname.split("/")[2];
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [isProceedClicked, setIsProceedClicked] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedScheduleID, setSelectedScheduleID] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [selectedTicketID, setSelectedTicketID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [displayTickets, setDisplayTickets] = useState(false);
  const [isLoadedData, setIsLoadedData] = useState(true);

  const user = useSelector((state) => state.auth?.user);
  const allEvents = async () => {
    const query =
      user.userType === "Organizer"
        ? listEventNoAuthOrganiser
        : listEventNoAuth;

    const queryString =
      user.userType === "Organizer"
        ? `(organizerID: "${user.owner}", filter: {eventCategory: {eq: "featuredEvents"}, and: [{eventStatus: {ne: "rejected"}},{eventStatus: {ne: "finished"}}]})`
        : `(filter: {eventCategory: {eq: "featuredEvents"}, and: [{eventStatus: {ne: "rejected"}},{eventStatus: {ne: "finished"}}]})`;

    try {
      let res = await API.graphql({
        query: query(queryString),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return res;
    } catch (error) {
      console.error("Error fetching events:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (isLoadedData) {
      allEvents().then((r) => {
        const property =
          user.userType === "Organizer" ? "byOrganizerIDEvent" : "listEvents";
        let eventData = r?.data[property]?.items;
        setEventList(eventData);
        if (eventData.length > 0) {
          setSelectedEventId(eventData[0]?.id);
          setSelectedEvent(eventData[0]);
          setSelectedScheduleID(eventData[0]?.eventSchedules?.items[0]?.id);
          setSelectedSchedule(eventData[0]?.eventSchedules?.items[0]);
          setSchedules(eventData[0]?.eventSchedules?.items);
          if (eventData[0].isSameTicketForAllDay) {
            setTickets(eventData[0]?.tickets?.items);
            setSelectedTicket(eventData[0]?.tickets?.items[0]);
            setSelectedTicketID(eventData[0]?.tickets?.items[0]?.id);
          } else {
            setTickets(eventData[0]?.eventSchedules?.items[0]?.tickets?.items);
            setSelectedTicket(
              eventData[0]?.eventSchedules?.items[0]?.tickets?.items[0]
            );
            setSelectedTicketID(
              eventData[0]?.eventSchedules?.items[0]?.tickets?.items[0]?.id
            );
          }
          setIsLoading(false);
        }
      });

      setIsLoadedData(false);
    }
  }, [user]);

  const handleProceed = () => {
    setDisplayTickets(true);
    // window.location.href = `/ticket-details/${selectedEventId}`;
  };

  const handleChangeSchedule = (e) => {
    if (e.target.value === "combo") {
      setSelectedScheduleID("combo");
      let tickets = selectedEvent.tickets.items.filter((ticket) =>
        ticket.ticketType.includes("Combo")
      );
      setTickets(tickets);
      setSelectedTicket(tickets[0]);
      setSelectedTicketID(tickets[0]?.id);
    } else {
      let currentSchedule = schedules.find(
        (schedule) => schedule.id === e.target.value
      );
      setSelectedScheduleID(currentSchedule.id);
      setSelectedSchedule(currentSchedule);
      if (!selectedEvent.isSameTicketForAllDay) {
        setTickets(currentSchedule?.tickets?.items);
        setSelectedTicket(currentSchedule?.tickets?.items[0]);
        setSelectedTicketID(currentSchedule?.tickets?.items[0]?.id);
      }
    }
  };
  const handleChangeTicket = (e) => {
    let currentTicket = tickets.find((ticket) => ticket.id === e.target.value);
    setSelectedTicket(currentTicket);
    setSelectedTicketID(currentTicket?.id);
  };

  const handleChangeEvent = (e) => {
    let newCurrentEvent = eventList.find(
      (event) => event.id === e.target.value
    );
    setSelectedEventId(e.target.value);
    setSelectedEvent(newCurrentEvent);
    let newSchedules = sortByDate(
      newCurrentEvent?.eventSchedules?.items,
      "asc"
    );
    setSchedules(newSchedules);
    setSelectedSchedule(newSchedules[0]);
    setSelectedScheduleID(newSchedules[0].id);
    if (newCurrentEvent.isSameTicketForAllDay) {
      setTickets(newCurrentEvent?.tickets?.items);
      setSelectedTicket(newCurrentEvent?.tickets?.items[0]);
      setSelectedTicketID(newCurrentEvent?.tickets?.items[0]?.id);
    } else {
      setTickets(newSchedules[0]?.tickets?.items);
      setSelectedTicket(newSchedules[0]?.tickets?.items[0]);
      setSelectedTicketID(newSchedules[0]?.tickets?.items[0]?.id);
    }
  };

  return (
    <>
      <div className="printTicketContainer dash_content_main oflow-hd">
        {!displayTickets ? (
          <>
            <div className="dash_content_main_head oflow-hd">
              <h2>Ticket Report</h2>
            </div>
            <div className="dash_content_main_center">
              <div className="dash_content_main_center_with_padding">
                <div
                // className="select_event_wrapper oflow-hd"
                >
                  <div className="row">
                    <div className="col-12">
                      <form>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <div className="single_input_box">
                            <label htmlFor="#">Select Event</label>
                            <select
                              name=""
                              value={selectedEventId}
                              onChange={(e) => handleChangeEvent(e)}
                            >
                              {eventList.length !== 0 &&
                                eventList.map((event, index) => (
                                  <option key={index} value={event.id}>
                                    {event.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="single_input_box">
                            <label htmlFor="#">Select Schedule</label>
                            <select
                              name=""
                              value={selectedScheduleID}
                              onChange={(e) => handleChangeSchedule(e)}
                            >
                              {schedules?.length !== 0 &&
                                schedules?.map((schedule, index) => (
                                  <option key={index} value={schedule?.id}>
                                    {`${
                                      schedule.scheduleTitle === "Combo"
                                        ? schedule.scheduleTitle
                                        : `Day ${index + 1}`
                                    } - ${moment(schedule?.eventDate).format(
                                      "DD MMM, YYYY"
                                    )}`}
                                  </option>
                                ))}
                              {targetedEventList.find(
                                (id) => id === selectedEventId
                              ) && <option value="combo">Combo</option>}
                            </select>
                          </div>
                          <div className="single_input_box">
                            <label htmlFor="#">Select Ticket Type</label>
                            <select
                              name=""
                              value={selectedTicketID}
                              onChange={(e) => handleChangeTicket(e)}
                            >
                              {tickets?.length !== 0 &&
                                tickets?.map((ticket, index) => (
                                  <option
                                    key={index}
                                    value={ticket?.id}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {ticket?.ticketType}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div
                          className="single_input_box_proceed"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <CustomButton
                            disabled={isLoading}
                            customVariant="primaryContained"
                            onClick={handleProceed}
                          >
                            Proceed
                          </CustomButton>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <PrintTicketContainer
            selectedEvent={selectedEvent}
            selectedSchedule={selectedSchedule}
            selectedTicket={selectedTicket}
            setDisplayTickets={setDisplayTickets}
          />
        )}
      </div>
    </>
  );
};

export default PrintTicket;

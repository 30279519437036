import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import DefaultImage from "./../../assets/images/images/defaultImage.png";
import { Button } from "@mui/material";
import CustomButton from "components/shared/Button";
const EventSponsorsCompSingle = ({
  mainData,
  eventSponsor,
  getImageData,
  removeThis,
  index,
  isEdit,
  setIsEdit,
  currentSponsor,
  setCurrentSponsor,
  customErrors,
  setCustomErrors,
  handleDrop,
  addSponsorToList,
  selectedImage,
  updateSponsorList,
}) => {
  const [isLoadErrors, setIsLoadErrors] = useState(true);
  useEffect(() => {
    setCustomErrors({});
    setIsLoadErrors(false);
  }, [isLoadErrors]);
  return (
    <>
      {isEdit.state && isEdit.index === index ? (
        <tr>
          <td colSpan={4}>
            <div className="dash_main_main_table_form oflow-hd">
              <div className="row">
                <div className="col-md-6">
                  <div className="single_input_box">
                    <label htmlFor="" style={{ textAlign: "start" }}>
                      Sponsor Name
                    </label>
                    <input
                      type="text"
                      value={currentSponsor.sponsorName}
                      placeholder="Name"
                      onChange={(e) => {
                        setCurrentSponsor({
                          ...currentSponsor,
                          sponsorName: e.target.value,
                        });
                        setCustomErrors({
                          ...customErrors,
                          sponsorName: "",
                        });
                      }}
                    />
                    {customErrors?.sponsorName && (
                      <p className="text-danger">{customErrors?.sponsorName}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single_input_box">
                    <label htmlFor="" style={{ textAlign: "start" }}>
                      Sponsor Type
                    </label>
                    <input
                      type="text"
                      value={currentSponsor.sponsorType}
                      placeholder="Sponsor Type"
                      onChange={(e) => {
                        setCurrentSponsor({
                          ...currentSponsor,
                          sponsorType: e.target.value,
                        });
                        setCustomErrors({
                          ...customErrors,
                          sponsorType: "",
                        });
                      }}
                    />
                    {customErrors?.sponsorType && (
                      <p className="text-danger">{customErrors?.sponsorType}</p>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="dash_main_main_table_form_up single_input_box">
                    <label htmlFor="" style={{ textAlign: "start" }}>
                      Sponsor Logo
                    </label>
                    <input
                      type="file"
                      id="sponsorAvatar"
                      name="cf_photo"
                      onDrop={handleDrop}
                      onDragOver={handleDrop}
                      onChange={handleDrop}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="dash_main_main_table_form_action">
                    <ul>
                      <li>
                        <CustomButton
                          variant="outlined"
                          sx={{ color: "black" }}
                          onClick={() => {
                            setIsEdit({
                              state: false,
                              index: "",
                            });
                            setCurrentSponsor({
                              eventID: mainData?.id,
                              sponsorName: "",
                              sponsorType: "",
                              sponsorBio: "",
                              sponsorUrl: "",
                              sponsorImageUrl: "",
                              isActive: true,
                            });
                          }}
                        >
                          Cancel
                        </CustomButton>
                      </li>
                      <li>
                        <CustomButton
                          customVariant="primaryContained"
                          onClick={updateSponsorList}
                          disable={!selectedImage?.name}
                        >
                          Save
                        </CustomButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      ) : (
        <tr key={index}>
          <td>
            <img
              src={
                eventSponsor.sponsorImageUrl
                  ? getImageData(eventSponsor.sponsorImageUrl)
                  : DefaultImage
              }
              alt="img"
            />
          </td>
          <td>{eventSponsor.sponsorName}</td>
          <td>{eventSponsor.sponsorType}</td>
          <td>
            <Link
              to=""
              onClick={(e) => {
                e.preventDefault();
                setCurrentSponsor(eventSponsor);
                setIsEdit({
                  state: true,
                  index: index,
                });
              }}
            >
              Edit
            </Link>
            <Link className="remove" to="" onClick={() => removeThis(index)}>
              Delete
            </Link>
          </td>
        </tr>
      )}
    </>
  );
};

export default EventSponsorsCompSingle;

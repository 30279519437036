import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { API } from "aws-amplify";
import {
  listEventNoAuth,
  listEventNoAuthOrganiser,
  listEventsCustom,
} from "customGraphQL/query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SingleTicket from "./SingleTicket";

const TicketReport = () => {
  const slug = useLocation();
  const pathname = slug.pathname.split("/")[2];
  const [selectedEvent, setSelectedEvent] = useState("");
  const [eventList, setEventList] = useState([]);
  const [isProceedClicked, setIsProceedClicked] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [schedules, setSchedules] = useState([]);

  const user = useSelector((state) => state.auth?.user);
  const allEvents = async () => {
    const query =
      user.userType === "Organizer"
        ? listEventNoAuthOrganiser
        : listEventNoAuth;

    const queryString =
      user.userType === "Organizer"
        ? `(organizerID: "${user.owner}", filter: {eventCategory: {eq: "featuredEvents"}, and: [{eventStatus: {ne: "rejected"}},{eventStatus: {ne: "finished"}}]})`
        : `(filter: {eventCategory: {eq: "featuredEvents"}, and: [{eventStatus: {ne: "rejected"}},{eventStatus: {ne: "finished"}}]})`;

    try {
      let res = await API.graphql({
        query: query(queryString),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return res;
    } catch (error) {
      console.error("Error fetching events:", error);
      throw error;
    }
  };

  useEffect(() => {
    allEvents().then((r) => {
      const property =
        user.userType === "Organizer" ? "byOrganizerIDEvent" : "listEvents";
      let eventData = r?.data[property]?.items;
      setEventList(eventData);
      if (r.data[property].items.length > 0) {
        setSelectedEvent(r.data[property].items[0].id);
      }
    });
  }, []);

  const handleProceed = (e) => {
    e.preventDefault();
    window.location.href = `/ticket-report/${selectedEvent}`;
  };

  return (
    <>
      <div className="dash_content_main oflow-hd">
        <div className="dash_content_main_head oflow-hd">
          <h2>Ticket Report</h2>
        </div>
        <div className="dash_content_main_center">
          <div className="dash_content_main_center_with_padding">
            <div className="select_event_wrapper oflow-hd">
              <div className="row">
                <form className="col-12">
                  <div className="single_input_box">
                    <label htmlFor="#">Select Event</label>
                    <select
                      name=""
                      value={selectedEvent}
                      onChange={(e) => setSelectedEvent(e.target.value)}
                    >
                      {eventList.length !== 0 &&
                        eventList.map((event, index) => (
                          <option key={index} value={event.id}>
                            {event.title}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="single_input_box_proceed">
                    <input
                      type="submit"
                      value="Proceed"
                      onClick={handleProceed}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketReport;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ArtistListSingle from "./ArtitstListSingle";

const ArtitstListMultiSchedule = ({
  setIsSubmitForm,
  isSubmitForm,
  schedules,
  mainData,
  artistList,
  setArtistList,
  artistImages,
  setArtistImages,
}) => {
  const [addArtist, setAddArtist] = useState(true);
  const [customErrors, setCustomErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState({
    name: "",
    data: "",
  });
  const [isEditArtist, setIsEditArtist] = useState({
    state: false,
    index: "",
  });
  const s3Bucket = process.env.REACT_APP_S3_BUCKET_URL;
  const [currentArtist, setCurrentArtist] = useState({
    eventID: mainData.id,
    eventScheduleID: "",
    artistName: "",
    artistUrl: "",
    shortOrder: 0,
    artistTitle: "",
    artistBio: "",
    artistImageUrl: "",
    artistOrganization: "",
    isActive: true,
  });

  const [isLoadArtistList, setIsLoadArtistList] = useState(true);

  const addArtistToList = (event) => {
    event.preventDefault();
    if (
      !currentArtist?.artistName ||
      !currentArtist?.artistTitle ||
      currentArtist?.shortOrder < 1
    ) {
      setCustomErrors({
        ...customErrors,
        artistName: currentArtist ? "Artist Name is required" : "",
        artistTitle: currentArtist ? "Artist Title is required" : "",
        shortOrder:
          currentArtist?.shortOrder < 1
            ? "Artist Sort Order is required and greater than 0"
            : "",
      });
      return;
    } else {
      setCustomErrors({});
    }
    let newArtist = currentArtist;
    if (isEditArtist.state) {
      let newArtistArray = artistList;
      if (selectedImage?.name) {
        currentArtist.artistImageUrl = selectedImage?.name;
      }
      newArtistArray[isEditArtist.index] = currentArtist;
      newArtistArray = newArtistArray.sort((a, b) => {
        if (a.shortOrder < b.shortOrder) return -1;
        if (a.shortOrder > b.shortOrder) return 1;
        return 0;
      });
      setArtistList(newArtistArray);
      setIsEditArtist(false);
    } else {
      newArtist.artistImageUrl = selectedImage?.name;
      let newArtistList = [...artistList, newArtist];
      newArtistList = newArtistList.sort((a, b) => {
        if (a.shortOrder < b.shortOrder) return -1;
        if (a.shortOrder > b.shortOrder) return 1;
        return 0;
      });
      setArtistList(newArtistList);
    }
    setArtistImages((images) => [...images, selectedImage]);
    setCurrentArtist({
      eventID: mainData.id,
      eventScheduleID: "",
      artistName: "",
      artistUrl: "",
      artistTitle: "",
      shortOrder: 0,
      artistBio: "",
      artistImageUrl: "",
      isActive: true,
    });
    clearFileInput();
    setSelectedImage(null);
  };

  const clearFileInput = () => {
    const fileInput = document.getElementById("artistAvatar");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      let fileName = file.name.split(".")[0];
      let ext = file.name.split(".")[1];
      fileName = uuidv4();

      reader.onload = () => {
        const imageData = reader.result;
        setSelectedImage({
          name: `${fileName}.${ext}`,
          data: imageData,
          file: file,
        });
        setArtistImages((prevData) => [
          ...prevData,
          { name: `${fileName}.${ext}`, data: imageData, file: file },
        ]);
      };

      reader.readAsDataURL(file);
    } else {
      console.error("Invalid file type. Please select an image.");
    }
  };

  useEffect(() => {
    if (isEditArtist.state) {
      let selectedArtist = artistList[parseInt(isEditArtist.index, 10)];
      setCurrentArtist(selectedArtist);
      if (
        selectedImage &&
        selectedArtist?.artistImageUrl !== selectedImage?.name
      ) {
        let newArtistImage = artistImages?.find(
          (item) => item?.name === selectedArtist.artistImageUrl
        );
        setSelectedImage(newArtistImage);
        let newArtistImageRemove = artistImages.filter(
          (item) => item?.name !== selectedArtist?.artistImageUrl && item?.file
        );
        setArtistImages(newArtistImageRemove);
      }
    } else {
      setCurrentArtist({
        eventID: mainData.id,
        eventScheduleID: "",
        artistName: "",
        artistUrl: "",
        artistTitle: "",
        shortOrder: 0,
        artistBio: "",
        artistImageUrl: "",
        isActive: true,
      });
    }
  }, [isEditArtist]);

  const handleDelete = (deleteIndex) => {
    let newSelectedArtist = artistList[parseInt(deleteIndex, 10)];
    let newArtistList = artistList.filter(
      (data, i) => data.id !== newSelectedArtist.id
    );
    setArtistList(newArtistList);
  };

  const getImageData = (artistImageUrl) => {
    const imageData = artistImages?.find(
      (image) => image?.name === artistImageUrl
    );
    if (imageData) {
      return imageData?.data;
    } else {
      if (artistImageUrl) {
        return `${s3Bucket}${artistImageUrl}`;
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    if (currentArtist.shortOrder === 0) {
      if (artistList.length > 1) {
        let newArtist = artistList;
        newArtist = newArtist.sort((a, b) => {
          if (a.shortOrder < b.shortOrder) return -1;
          if (a.shortOrder > b.shortOrder) return 1;
          return 0;
        });
        setArtistList(newArtist);
      }
    }
  }, [currentArtist]);

  return (
    <>
      <div className="col-12 mt-2">
        <div className="single_input_box artist_table">
          <label htmlFor="#" className="text-start">
            Artist
          </label>
          <div className="dash_main_table_wrapper">
            <div className="dash_main_main_table oflow-hd">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Avatar</th>
                    <th>Name</th>
                    <th>Title</th>
                    <th>Bio</th>
                    <th>Url</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {artistList?.length > 0 &&
                    artistList?.map((artist, index) => (
                      <ArtistListSingle
                        key={index}
                        artist={artist}
                        index={index}
                        isEditArtist={isEditArtist}
                        setIsEditArtist={setIsEditArtist}
                        customErrors={customErrors}
                        setCustomErrors={setCustomErrors}
                        getImageData={getImageData}
                        currentArtist={currentArtist}
                        setCurrentArtist={setCurrentArtist}
                        handleDrop={handleDrop}
                        addArtistToList={addArtistToList}
                        selectedImage={selectedImage}
                        handleDelete={handleDelete}
                      />
                    ))}
                </tbody>
              </table>
              {!artistList.length === 0 && (
                <div className="dash_main_main_table_empty">
                  No artist added yet
                </div>
              )}
            </div>

            <div className="dash_main_main_table_add oflow-hd">
              {!addArtist && (
                <ul>
                  <li>
                    <Link
                      className="addMoreArtist"
                      to=""
                      onClick={() => setAddArtist(true)}
                    >
                      Add Artist +
                    </Link>
                  </li>
                </ul>
              )}
              {addArtist && !isEditArtist.state && (
                <div className="dash_main_main_table_form oflow-hd">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="single_input_box">
                        <label htmlFor="#">Name</label>
                        <input
                          type="text"
                          value={currentArtist?.artistName}
                          onChange={(e) => {
                            setCurrentArtist({
                              ...currentArtist,
                              artistName: e.target.value,
                            });
                            setCustomErrors({
                              ...customErrors,
                              artistName: "",
                            });
                          }}
                          placeholder="Name"
                        />
                        {customErrors?.artistName && (
                          <p className="text-danger">
                            {customErrors.artistName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single_input_box">
                        <label htmlFor="#">Url</label>
                        <input
                          type="text"
                          value={currentArtist?.artistUrl}
                          onChange={(e) => {
                            setCurrentArtist({
                              ...currentArtist,
                              artistUrl: e.target.value,
                            });
                          }}
                          placeholder="URL"
                        />
                        {/* {customErrors?.artistUrl && (
                          <p className="text-danger">
                            {customErrors.artistUrl}
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single_input_box">
                        <label htmlFor="#">Title</label>
                        <input
                          type="text"
                          value={currentArtist?.artistTitle}
                          onChange={(e) => {
                            setCurrentArtist({
                              ...currentArtist,
                              artistTitle: e.target.value,
                            });
                            setCustomErrors({
                              ...customErrors,
                              artistTitle: "",
                            });
                          }}
                          placeholder="Title"
                        />
                        {customErrors?.artistTitle && (
                          <p className="text-danger">
                            {customErrors.artistTitle}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single_input_box">
                        <label htmlFor="#">Sort Order</label>
                        <input
                          type="number"
                          value={currentArtist?.shortOrder}
                          onChange={(e) => {
                            setCurrentArtist({
                              ...currentArtist,
                              shortOrder:
                                typeof e.target.value === "number"
                                  ? e.target.value
                                  : parseInt(e.target.value),
                            });
                            setCustomErrors({
                              ...customErrors,
                              shortOrder: "",
                            });
                          }}
                          placeholder="Sort Order"
                        />
                        {customErrors?.shortOrder && (
                          <p className="text-danger">
                            {customErrors.shortOrder}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single_input_box">
                        <label htmlFor="#">Bio</label>
                        <textarea
                          value={currentArtist?.artistBio}
                          onChange={(e) =>
                            setCurrentArtist({
                              ...currentArtist,
                              artistBio: e.target.value,
                            })
                          }
                          placeholder="Bio"
                        ></textarea>
                      </div>
                      <div className="dash_main_main_tab le_form_up single_input_box">
                        <label htmlFor="#">Image</label>
                        <input
                          type="file"
                          id="artistAvatar"
                          name="cf_photo"
                          onDrop={handleDrop}
                          onDragOver={handleDrop}
                          onChange={handleDrop}
                          accept="image/*"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="dash_main_main_table_form_action">
                        <ul>
                          <ul>
                            <li>
                              <Link to="#!" onClick={() => setAddArtist(false)}>
                                Cancel
                              </Link>
                            </li>
                            <li>
                              <button
                                onClick={addArtistToList}
                                disable={!selectedImage?.name}
                              >
                                Add
                              </button>
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtitstListMultiSchedule;

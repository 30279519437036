import { Link, useParams } from "react-router-dom";
import banner from "./../../assets/images/default_image.png";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import {
  listEventNoAuth,
  listEventNoAuthOrganiser,
  listEventsCustom,
} from "customGraphQL/query";
import { useSelector } from "react-redux";
import { baseUrl } from "../../utils/utility";

const ManageEventHappeningAround = () => {
  const [eventList, setEventList] = useState([]);

  const user = useSelector((state) => state.auth?.user);

  const allEvents = async () => {
    const query =
      user.userType === "Organizer"
        ? listEventNoAuthOrganiser
        : listEventNoAuth;

    const queryString =
      user.userType === "Organizer"
        ? `(organizerID: "${user.owner}", filter: {eventCategory: {eq: "happeningAround"}, isUpComing: {eq: true}, isActive: {eq: true}})`
        : `(filter: {eventCategory: {eq: "happeningAround"}, isUpComing: {eq: true}, isActive: {eq: true}})`;

    try {
      let res = await API.graphql({
        query: query(queryString),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return res;
    } catch (error) {
      console.error("Error fetching events:", error);
      throw error;
    }
  };

  useEffect(() => {
    allEvents().then((r) => {
      const property =
        user.userType === "Organizer" ? "byOrganizerIDEvent" : "listEvents";
      setEventList(r.data[property].items);
    });
  }, []);

  let s3BucketUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const getContentUrl = (imageArray) => {
    let newImage = imageArray?.find((image) => image.isMain);
    return newImage ? `${s3BucketUrl}${newImage?.contentUrl}` : banner;
  };

  return (
    <>
      <div className="dash_content_main oflow-hd">
        <div className="dash_content_main_head oflow-hd">
          <h2 className="text-capitalize">Manage Events on happening around</h2>
        </div>
        <div className="dash_content_main_center">
          <div className="dash_content_main_center_with_padding">
            <div className="dash_events_list_wrapper">
              <div className="row">
                {eventList.length > 0 ? (
                  eventList.map((event, index) => (
                    <div key={index} className="col-md-6 col-lg-4">
                      <div className="dash_events_list_single oflow-hd">
                        <div className="dash_events_list_single_img oflow-hd">
                          <img
                            style={{ height: "30vh", objectFit: "cover" }}
                            src={
                              event?.eventContents?.items.length !== 0
                                ? getContentUrl(event?.eventContents?.items)
                                : banner
                            }
                            alt="img"
                          />
                        </div>
                        <div className="dash_events_list_single_details oflow-hd">
                          <div className="dash_events_list_single_details_title oflow-hd">
                            <h2>{event.title}</h2>
                          </div>
                          <div className="dash_events_list_single_details_center_multi oflow-hd">
                            <div className="row">
                              <div className="col-6">
                                <div className="single_event_manage_details_box oflow-hd">
                                  <div className="single_event_manage_details oflow-hd">
                                    <span>Event Type:</span>{" "}
                                    <span className="text-capitalize">
                                      {event.eventType}
                                    </span>
                                  </div>
                                  <div className="single_event_manage_details oflow-hd">
                                    <span>Venue:</span>{" "}
                                    <span
                                      style={{
                                        font: "16px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {event.eventSchedules?.items[0]?.venue}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="single_event_manage_details_box single_event_manage_details_box_last oflow-hd">
                                  <div className="single_event_manage_details oflow-hd">
                                    <span>Date:</span>{" "}
                                    <span
                                      style={{
                                        font: "16px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {
                                        event.eventSchedules?.items[0]
                                          ?.eventDate
                                      }
                                    </span>
                                  </div>
                                  <div className="single_event_manage_details oflow-hd">
                                    <span>Location:</span>{" "}
                                    <span
                                      style={{
                                        font: "16px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {
                                        event.eventSchedules?.items[0]
                                          ?.eventCity
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="dash_events_list_single_details_action dash_events_list_single_details_action_ManageEvent oflow-hd">
                            <ul>
                              <li>
                                <a
                                  href={`${baseUrl}/event/${event?.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Public Preview
                                </a>
                              </li>
                              <li>
                                <Link
                                  to={`/single-event-on-happening-around/${event.id}`}
                                >
                                  View
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h4 style={{ marginLeft: "15px" }}>
                    You don't have any created event..!
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageEventHappeningAround;

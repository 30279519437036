// action - state management
import { REGISTER, LOGIN, LOGOUT } from "./actions";

// initial state
export const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  loginMail: "",
  loginPass: "",
  user: null,
};

// ==============================|| AUTH REDUCER ||============================== //

import { createSlice } from "@reduxjs/toolkit";

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginReducer: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.isInitialized = action.payload.isInitialized;
      state.user = action.payload.user;
      state.role = action.payload.user.userType;
    },
    directLoginReducer: (state, action) => {
      state.loginMail = action.payload;
      state.loginPass = action.payload;
    },
    eraseDirectLoginReducer: (state, action) => {
      state.loginMail = "";
      state.loginPass = "";
    },
    logoutReducer: (state, action) => {
      state.isLoggedIn = false;
      state.isInitialized = true;
      state.user = null;
    },
  },
});
//
// // Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions
//
// export default counterSlice.reducer

// const auth = (state = initialState, action) => {
//   switch (action.type) {
//     case REGISTER: {
//       const { user } = action.payload;
//       return {
//         ...state,
//         user
//       };
//     }
//     case LOGIN: {
//       const { user } = action.payload;
//       return {
//         ...state,
//         isLoggedIn: true,
//         isInitialized: true,
//         user: user
//       };
//     }
//     case LOGOUT: {
//       return {
//         ...state,
//         isInitialized: true,
//         isLoggedIn: false,
//         user: null
//       };
//     }
//     default: {
//       return { ...state };
//     }
//   }
// };
export const { loginReducer, logoutReducer } = auth.actions;
export default auth.reducer;

import AWS from "aws-sdk";

export const S3BucketDeleteFile = async (key) => {
  const S3_BUCKET_NAME = "tickettomorrowstaticbucket192143-test";
  const REGION = "ap-southeast-1";
  const ACCESS_KEY = "AKIAU3Q4ZJYYBUVV22OY";
  const SECRET_KEY = "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";
  AWS.config.update({
    region: REGION,
    credentials: new AWS.Credentials(ACCESS_KEY, SECRET_KEY),
  });

  const params = {
    Bucket: S3_BUCKET_NAME,
    Key: key,
  };

  const S3 = new AWS.S3();
  try {
    let data = await S3.deleteObject(params).promise();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

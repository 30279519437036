import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import EventConfigMulitpleDays from "./EventConfigMulitpleDays";
import moment from "moment";
import { v4 } from "uuid";
import EventMultiScheduleSingleVenue from "./EventMultiScheduleSingleVenue";
import EventMultiScheduleMultiVenue from "./EventMultiScheduleMultiVenue";
import EventMultiScheduleSameArtist from "./EventMultiScheduleSameArtist";
import EventMultiScheduleDifferentArtist from "./EventMultiScheduleDifferentArtist";
import { sortDates } from "services/sortDate";

const EventConfigSecondStep = ({
  setIsSubmitForm,
  isSubmitForm,
  step,
  triggerSubmit,
  schedules,
  setSchedules,
  mainData,
  setMainData,
  artistList,
  setArtistList,
  artistImages,
  setArtistImages,
  multiDaysErrors,
  setMultiDaysErrors,
  isSameArtistSwitchable,
  sameScheduleData,
  setSameScheduleData,
  isMultiEventVenueEmpty,
  setIsMultiEventVenueEmpty,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [venue, setVenue] = useState(
    !mainData.isLocationVenueSame || mainData.isLocationVenueSame === true
      ? "SingleVenue"
      : "MultipleVenue"
  );
  const [isSameArtistForMultSchedule, setIsSameArtistForMultSchedule] =
    useState(
      !mainData.isSameArtist || mainData.isSameArtist === true
        ? "YesSame"
        : "NoDifferent"
    );
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [numberOfDays, setNumberOfDays] = useState([1]);

  const handleEditToggle = () => {
    setIsEditing((prevIsEditing) => !prevIsEditing);
  };

  useEffect(() => {
    window.localStorage.setItem("numberOfDays", numberOfDays);
  }, [numberOfDays]);

  const addNewSchedule = (event) => {
    event.preventDefault();
    let newSchedule = {
      id: v4(),
      eventID: mainData.id,
      eventDate: moment(new Date())
        .add(schedules.length + 1, "days")
        .format("YYYY-MM-DD"),
      scheduleTitle: `Day ${schedules.length + 1}`,
      eventAgeGroup: "",
      eventDuration: `${schedules.length + 1}`,
      eventStartTime: moment(new Date()).format("HH:mm"),
      gateOpenTime: "",
      gateCloseTime: "",
      eventCity: "",
      location: {
        lat: 23.804193153061725,
        lon: 90.36392211914062,
      },
      address: "",
      venue: "",
      isActive: true,
    };
    let prevSchedules = schedules.map((schedule) => {
      schedule.eventDuration = `${schedules.length}`;
      return schedule;
    });
    setSchedules(() => [...prevSchedules, newSchedule]);
  };

  return (
    <>
      <form className="form_box_area_main event_information_form oflow-hd">
        <div className="form_title oflow-hd">
          <h2>Event Configuration</h2>
        </div>
        <div className="form_wrapper">
          <div className="row">
            <div className="col-12">
              <EventConfigMulitpleDays
                numberOfDays={numberOfDays}
                isEditing={isEditing}
                handleEditToggle={handleEditToggle}
                setSchedules={setSchedules}
                schedules={schedules}
                isSubmitForm={isSubmitForm}
                setIsSubmitForm={setIsSubmitForm}
              />
              <div className="dash_main_main_table_add oflow-hd">
                <Link
                  className="addDays"
                  to=""
                  onClick={(e) => addNewSchedule(e)}
                >
                  Add Days +
                </Link>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div className="single_input_box sameticketordifferent">
                <label htmlFor="#">Location and Venue will be same?</label>
                <div className="single_input_box_rado ec_locationvenusameornot">
                  <ul>
                    <li>
                      <input
                        type="radio"
                        name="ei_locationwilbbesame"
                        id="SingleVenue"
                        value="SingleVenue"
                        onClick={(e) => {
                          setVenue(e.target.value);
                          setMainData({
                            ...mainData,
                            isLocationVenueSame: true,
                          });
                          setSameScheduleData(schedules[0]);
                        }}
                        checked={mainData?.isLocationVenueSame === true}
                      />
                      <label htmlFor="SingleVenue">
                        Single Venue
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.82918 8.72855C3.73373 8.82458 3.60348 8.87814 3.4682 8.87814C3.33293 8.87814 3.20268 8.82458 3.10723 8.72855L0.224383 5.84523C-0.0747943 5.54605 -0.0747943 5.06091 0.224383 4.76231L0.585358 4.40124C0.88463 4.10206 1.3692 4.10206 1.66838 4.40124L3.46823 6.20115L8.33157 1.33766C8.63085 1.03849 9.1159 1.03849 9.41459 1.33766L9.77557 1.69873C10.0747 1.99791 10.0747 2.48296 9.77557 2.78166L3.82918 8.72855Z"
                            fill="white"
                          />
                        </svg>
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="ei_locationwilbbesame"
                        id="MultipleVenue"
                        value="MultipleVenue"
                        onClick={(e) => {
                          setVenue(e.target.value);
                          setMainData({
                            ...mainData,
                            isLocationVenueSame: false,
                          });
                        }}
                        checked={mainData?.isLocationVenueSame === false}
                      />
                      <label htmlFor="MultipleVenue">
                        Multiple Venue
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.82918 8.72855C3.73373 8.82458 3.60348 8.87814 3.4682 8.87814C3.33293 8.87814 3.20268 8.82458 3.10723 8.72855L0.224383 5.84523C-0.0747943 5.54605 -0.0747943 5.06091 0.224383 4.76231L0.585358 4.40124C0.88463 4.10206 1.3692 4.10206 1.66838 4.40124L3.46823 6.20115L8.33157 1.33766C8.63085 1.03849 9.1159 1.03849 9.41459 1.33766L9.77557 1.69873C10.0747 1.99791 10.0747 2.48296 9.77557 2.78166L3.82918 8.72855Z"
                            fill="white"
                          />
                        </svg>
                      </label>
                    </li>
                  </ul>
                </div>
                {mainData?.isLocationVenueSame === true && (
                  <EventMultiScheduleSingleVenue
                    schedules={schedules}
                    setSchedules={setSchedules}
                    isSubmitForm={isSubmitForm}
                    sameScheduleData={sameScheduleData}
                    setSameScheduleData={setSameScheduleData}
                    isMultiEventVenueEmpty={isMultiEventVenueEmpty}
                    setIsMultiEventVenueEmpty={setIsMultiEventVenueEmpty}
                    setIsSubmitForm={setIsSubmitForm}
                  />
                )}
                {mainData?.isLocationVenueSame === false && (
                  <EventMultiScheduleMultiVenue
                    schedules={schedules}
                    setSchedules={setSchedules}
                    isSubmitForm={isSubmitForm}
                    isMultiEventVenueEmpty={isMultiEventVenueEmpty}
                    setIsMultiEventVenueEmpty={setIsMultiEventVenueEmpty}
                    setIsSubmitForm={setIsSubmitForm}
                  />
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="single_input_box artist_table sameticketordifferent">
                <label htmlFor="#">Same Artist For All days?</label>
                <div className="single_input_box_rado ec_sameartisitornot mb-5">
                  <ul>
                    <li
                      onClick={(e) => {
                        if (isSameArtistSwitchable === true) {
                          setIsSameArtistForMultSchedule("YesSame");
                          setMainData({
                            ...mainData,
                            isSameArtist: true,
                          });
                        }
                      }}
                    >
                      <input
                        type="radio"
                        name="ec_sameartisitornot"
                        id="YesSame"
                        checked={mainData.isSameArtist === true}
                      />
                      <label htmlFor="YesSame">
                        Yes, Same
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.82918 8.72855C3.73373 8.82458 3.60348 8.87814 3.4682 8.87814C3.33293 8.87814 3.20268 8.82458 3.10723 8.72855L0.224383 5.84523C-0.0747943 5.54605 -0.0747943 5.06091 0.224383 4.76231L0.585358 4.40124C0.88463 4.10206 1.3692 4.10206 1.66838 4.40124L3.46823 6.20115L8.33157 1.33766C8.63085 1.03849 9.1159 1.03849 9.41459 1.33766L9.77557 1.69873C10.0747 1.99791 10.0747 2.48296 9.77557 2.78166L3.82918 8.72855Z"
                            fill="white"
                          />
                        </svg>
                      </label>
                    </li>
                    <li
                      onClick={(e) => {
                        if (isSameArtistSwitchable) {
                          setIsSameArtistForMultSchedule("NoDifferent");
                          setMainData({
                            ...mainData,
                            isSameArtist: false,
                          });
                        }
                      }}
                    >
                      <input
                        type="radio"
                        name="ec_sameartisitornot"
                        id="NoDifferent"
                        // onClick={(e) =>
                        //   setIsSameArtistForMultSchedule(e.target.value)
                        // }
                        checked={mainData.isSameArtist === false}
                      />
                      <label htmlFor="NoDifferent">
                        No, Different
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.82918 8.72855C3.73373 8.82458 3.60348 8.87814 3.4682 8.87814C3.33293 8.87814 3.20268 8.82458 3.10723 8.72855L0.224383 5.84523C-0.0747943 5.54605 -0.0747943 5.06091 0.224383 4.76231L0.585358 4.40124C0.88463 4.10206 1.3692 4.10206 1.66838 4.40124L3.46823 6.20115L8.33157 1.33766C8.63085 1.03849 9.1159 1.03849 9.41459 1.33766L9.77557 1.69873C10.0747 1.99791 10.0747 2.48296 9.77557 2.78166L3.82918 8.72855Z"
                            fill="white"
                          />
                        </svg>
                      </label>
                    </li>
                  </ul>
                </div>
                {mainData.isSameArtist === true && (
                  <EventMultiScheduleSameArtist
                    setIsSubmitForm={setIsSubmitForm}
                    schedules={schedules}
                    setSchedules={setSchedules}
                    mainData={mainData}
                    artistList={artistList}
                    setArtistList={setArtistList}
                    artistImages={artistImages}
                    setArtistImages={setArtistImages}
                  />
                )}
                {mainData.isSameArtist === false && (
                  <EventMultiScheduleDifferentArtist
                    setIsSubmitForm={setIsSubmitForm}
                    schedules={schedules}
                    setSchedules={setSchedules}
                    mainData={mainData}
                    artistList={artistList}
                    setArtistList={setArtistList}
                    artistImages={artistImages}
                    setArtistImages={setArtistImages}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EventConfigSecondStep;

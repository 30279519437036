import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import CustomMapBox from "./CustomMapBox";

const width = "900px";
const height = "700px";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: width,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CustomModal = ({
  open,
  setOpen,
  scheduleLatitude,
  scheduleLongitude,
  setScheduleLatitude,
  setScheduleLongitude,
}) => {
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <Box sx={style}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            top: "3px",
            right: "12px",
          }}
        >
          <CloseIcon />
        </IconButton>
        <CustomMapBox
          width={width}
          height={height}
          scheduleLatitude={scheduleLatitude}
          setScheduleLatitude={setScheduleLatitude}
          scheduleLongitude={scheduleLongitude}
          setScheduleLongitude={setScheduleLongitude}
        />
      </Box>
    </Modal>
  );
};

export default CustomModal;

export const updateEventOne = (data) => {
  return `mutation MyMutation {updateEvent(input: {hasReEntry: ${data.hasReEntry},preferredTicketDelivery:"${data.preferredTicketDelivery}",maxTicketByUser: ${data.maxTicketByUser}, maxTicketInSingleCheckout: ${data.maxTicketInSingleCheckout}, ticketActivationDate: "${data.activationDate}", id: "${data.id}"}){
    id
    hasReEntry
    maxTicketByUser
    maxTicketInSingleCheckout
    ticketActivationDate
  }}`;
};

export const updateEventTwo = (data) => {
  return `mutation MyMutation {updateEvent(input: {userRequiredInfo: ${JSON.stringify(
    data.userRequiredInfo
  )}, isUserInfoRequiredForEach: ${data.isUserInfoRequiredForEach}, id: "${
    data.id
  }"}){id}}`;
};

export const createTicketCustom = (data) => {
  let eventSchedule = data.eventScheduleID
    ? ` eventScheduleID: "${data.eventScheduleID}"`
    : "";
  return `mutation MyMutation {createTicket(input: {eventID: "${data.eventID}", ${eventSchedule}, ticketPrice: ${data.ticketPrice}, ticketType: "${data.ticketType}", totalTicket: ${data.totalTicket}, totalSold: ${data.totalSold}}){
    id
    createdAt}}`;
};

export const updateTicketCustom = (ticket) => {
  return `mutation MyMutation {
    updateTicket(input: {id: "${ticket.id}", ticketPrice: ${ticket.ticketPrice}, ticketType: "${ticket.ticketType}", totalTicket: ${ticket.totalTicket}}) {
      id
      ticketPrice
      owner
      ticketType
      totalSold
      totalTicket
      updatedAt
    }
  }`;
};

export const deleteTicketCustom = (ticketId) => {
  return `mutation MyMutation {
    deleteTicket(input: {id: "${ticketId}"}) {
      id
    }
  }
  `;
};

export const customUpdateEvent = (data) => {
  return `mutation MyMutation {
    updateEvent(input: {id: "${data.id}", isSameTicketForAllDay: ${data.isSameTicketForAllDay}}) {
      id
      updatedAt
      isSameTicketForAllDay
    }
  }`;
};

export const customDeleteArtist = (artistId) => {
  return `mutation MyMutation {
    deleteEventArtist(input: {id: "${artistId}"}) {
      id
    }
  }`;
};

export const customDeleteSponsor = (sponsorId) => {
  return `mutation MyMutation {
    deleteEventSponsor(input: {id: "${sponsorId}"}) {
      id
    }
  }`;
};

export const customDeleteContent = (contentId) => {
  return `mutation MyMutation {
    deleteEventContent(input: {id: "${contentId}"}) {
      id
    }
  }`;
};

export const customDeleteSchedule = (scheduleId) => {
  return `mutation MyMutation {
    deleteEventSchedule(input: {id: "${scheduleId}"}) {
      id
    }
  }`;
};

export const ticketSingle = (limit, event, userID, nextToken) => {
  limit = limit || 0;
  let userQuery = userID ? ` userID: {eq: "${userID}"}` : "";
  let query = nextToken
    ? `eventID: "${event}, nextToken: "${nextToken}",${userQuery}, limit: ${limit}`
    : `eventID: "${event}", ${userQuery}`;
  return `query MyQuery {
  byEventIDUserIDTicketSaleSingle(${query}) {
    items {
      createdAt
      entryDate
      eventID
      eventScheduleID
      id
      isActive
      isEntryDone
      owner
      ticketCode
      ticketID
      ticketPrice
      ticketSaleID
      ticketUser
      updatedAt
      userID
      user {
        fullName
        nid
        gender
        email
        dob
        phoneNo
      }
      ticketSale {
        ticketSaleMaster {
          createdAt
          discountAmount
          discountMethod
          eventID
          eventScheduleID
          id
          isActive
          ispersent
          owner
          paymentData
          paymentMethod
          totalDiscountAmount
          totalPrice
          totalPriceBeforeDiscount
          totalTicket
          updatedAt
          userID
          deliveryOption
          deliveryAddress
          event {
            eventSchedules {
              items {
                address
                eventCity
                eventDate
                eventStartTime
                totalEntry
                venue
              }
            }
          }
        }
        userID
        updatedAt
        totalTicket
        totalPrice
        createdAt
        eventID
        eventScheduleID
        id
        isActive
        owner
        ticketID
        ticketSaleMasterID
        ticketInfo {
          createdAt
          eventID
          eventScheduleID
          id
          owner
          ticketPrice
          ticketType
          totalSold
          totalTicket
        }
      }
    }
    nextToken
  }
}
`;
};

export const updateScheduleTime = (scheduleId, openTime, closeTime) => {
  return `mutation MyMutation {
    updateEventSchedule(input: {id: "${scheduleId}", gateOpenTime: "${openTime}", gateCloseTime: "${closeTime}"}) {
      id
      gateOpenTime
      gateCloseTime
    }
  }`;
};
export const updateHasReEntryByEventID = (eventID, hasReEntry) => {
  return `mutation MyMutation {
    updateEvent(input: {id: "${eventID}", hasReEntry: ${hasReEntry}}) {
      id
      hasReEntry
    }
  }`;
};
export const updateUserById = (userId, userType, isApproved) => {
  return `mutation MyMutation {
    updateUser(input: {id: "${userId}", userType: ${userType}, isApproved: ${isApproved}, adminUserStatus: "${isApproved ? "Approved": "NoStatus"}" }) {
      dob
      createdAt
      email
      firstName
      fullName
      gender
      id
      imageUrl
      isApproved
      isPhoneVerified
      lastName
      nid
      organizerName
      otpValidTillDate
      owner
      phoneNo
      phoneVerificationOtp
      userName
      userType
    }
  }
  `;
};

export const createCompanyProfile = (
  userId,
  companyName,
  companyImageUrl,
  companyEmail,
  companyAddress,
  companyPhoneNumber,
  companyTradeLicenseNo,
  tradeLicenseUrl,
  companyTinNo,
  tinUrl,
  companyBinNo,
  binUrl
) => {
  return `mutation MyMutation {
    createCompanyProfile(input: {UserID: "${userId}",companyImageUrl:"${companyImageUrl}" binUrl: "${binUrl}", companyAddress: "${companyAddress}", companyBinNo: "${companyBinNo}", companyEmail: "${companyEmail}", companyName: "${companyName}", companyPhoneNumber: "${companyPhoneNumber}", companyTinNo: "${companyTinNo}", companyTradeLicenseNo: "${companyTradeLicenseNo}", tinUrl: "${tinUrl}", tradeLicenseUrl: "${tradeLicenseUrl}"}) {
      id
    }
  }
  
  `;
};
export const updateCompanyProfile = (
  id,
  companyName,
  companyImageUrl,
  companyEmail,
  companyAddress,
  companyPhoneNumber,
  companyTradeLicenseNo,
  tradeLicenseUrl,
  companyTinNo,
  tinUrl,
  companyBinNo,
  binUrl
) => {
  return `mutation MyMutation {
    updateCompanyProfile(input: {id: "${id}",companyImageUrl:"${companyImageUrl}" binUrl: "${binUrl}", companyAddress: "${companyAddress}", companyBinNo: "${companyBinNo}", companyEmail: "${companyEmail}", companyName: "${companyName}", companyPhoneNumber: "${companyPhoneNumber}", companyTinNo: "${companyTinNo}", companyTradeLicenseNo: "${companyTradeLicenseNo}", tinUrl: "${tinUrl}", tradeLicenseUrl: "${tradeLicenseUrl}"}) {
      id
    }
  }
  
  `;
};

export const createCompanyBankInfo = (
  userID,
  accountName,
  accountNumber,
  bankName,
  branchName,
  companyID,
  swiftCode
) => {
  return `mutation MyMutation {
    createCompanyBankInfo(input: {UserID: "${userID}", accountName: "${accountName}", accountNumber: "${accountNumber}", bankName: "${bankName}", branchName: "${branchName}", companyID: "${companyID}", swiftCode: "${swiftCode}"}){
      UserID
      accountName
      accountNumber
      bankName
      branchName
      companyID
      id
      owner
      swiftCode
    }
  }`;
};
export const updateCompanyBankInfo = (
  id,
  accountName,
  accountNumber,
  bankName,
  branchName,
  swiftCode
) => {
  return `mutation MyMutation {
    updateCompanyBankInfo(input: {accountName: "${accountName}", accountNumber: "${accountNumber}", bankName: "${bankName}", branchName: "${branchName}", id: "${id}", swiftCode: "${swiftCode}"}) {
      UserID
      accountName
      accountNumber
      bankName
      branchName
      companyID
      id
      swiftCode
    }
  }
  `;
};
export const createCompanyRepresentativeInfo = (
  userID,
  companyID,
  representativeEmail,
  representativeName,
  representativeOrganisation,
  representativePhoneNumber
) => {
  return `mutation MyMutation {
    createCompanyRepresentativeInfo(input: {UserID: "${userID}", companyID: "${companyID}", representativeEmail: "${representativeEmail}", representativeName: "${representativeName}", representativeOrganisation: "${representativeOrganisation}", representativePhoneNumber: "${representativePhoneNumber}"}) {
      UserID
      companyID
      id
      representativeEmail
      representativeName
      representativeOrganisation
      representativePhoneNumber
    }
  }
  
  `;
};
export const updateCompanyRepresentativeInfo = (
  id,
  representativeEmail,
  representativeName,
  representativeOrganisation,
  representativePhoneNumber
) => {
  return `mutation MyMutation {
    updateCompanyRepresentativeInfo(input: {id: "${id}", representativeEmail: "${representativeEmail}", representativeName: "${representativeName}", representativeOrganisation: "${representativeOrganisation}", representativePhoneNumber: "${representativePhoneNumber}"}) {
      representativePhoneNumber
      representativeOrganisation
      representativeName
      representativeEmail
      companyID
      UserID
    }
  }
  `;
};
export const deleteCompanyBankInfo = (id) => {
  return `mutation MyMutation {
    deleteCompanyBankInfo(input: {id: "${id}"}) {
      id
    }
  }`;
};
export const createPickupDeliveryAddress = (eventID, address) => {
  return `mutation MyMutation {
    createEventDeliveryAddress(input: {eventID: "${eventID}", isActive: true, address: "${address}"}) {
      address
      eventID
      id
      isActive
    }
  }
  `;
};
export const createEventPreRegistration = (
  eventID,
  audienceInfo,
  discountAmount,
  exclusiveTicket,
  exclusiveTicketDuration,
  hasDiscount,
  isPercent,
  preRegistrationEndsAt,
  preRegistrationStartAt,
  status
) => {
  const query = `mutation MyMutation {
    createEventPreRegistration(input: {AudienceInfo: ${audienceInfo}, discountAmount: ${discountAmount}, eventID: "${eventID}", exclusiveTicket: ${exclusiveTicket}, exclusiveTicketDuration: ${exclusiveTicketDuration}, hasDiscount: ${hasDiscount}, isActive: true, isPercent: ${isPercent}, preRegistrationEndsAt: "${preRegistrationEndsAt}", preRegistrationStartAt: "${preRegistrationStartAt}", status: "${status}"}) {
      updatedAt
      status
      preRegistrationStartAt
      preRegistrationEndsAt
      owner
      isPercent
      isActive
      id
      hasDiscount
      exclusiveTicket
      exclusiveTicketDuration
      eventID
      discountAmount
      createdAt
      AudienceInfo
    }
  }
  `;
  return query;
};
export const updateEventPreRegistration = (
  id,
  audienceInfo,
  discountAmount,
  exclusiveTicket,
  exclusiveTicketDuration,
  hasDiscount,
  isPercent,
  preRegistrationEndsAt,
  preRegistrationStartAt,
  status
) => {
  const query = `mutation MyMutation {
    updateEventPreRegistration(input: {AudienceInfo: ${audienceInfo}, discountAmount: ${discountAmount}, exclusiveTicket: ${exclusiveTicket}, exclusiveTicketDuration: ${exclusiveTicketDuration}, hasDiscount: ${hasDiscount}, id: "${id}", isPercent: ${isPercent}, preRegistrationEndsAt: "${preRegistrationEndsAt}", preRegistrationStartAt: "${preRegistrationStartAt}", status: "${status}"}) {
      AudienceInfo
      discountAmount
      eventID
      exclusiveTicket
      exclusiveTicketDuration
      hasDiscount
      id
      isPercent
      preRegistrationEndsAt
      preRegistrationStartAt
      status
    }
  }
  `;
  return query;
};
export const updateEventPreRegistrationStatus = (id, status) => {
  const query = `mutation MyMutation {
    updateEventPreRegistration(input: {id: "${id}", status: "${status}"}) {
      AudienceInfo
      discountAmount
      eventID
      exclusiveTicket
      exclusiveTicketDuration
      hasDiscount
      id
      isPercent
      preRegistrationEndsAt
      preRegistrationStartAt
      status
    }
  }
  `;
  return query;
};
export const deleteEventPreRegistration = (id) => {
  const query = `mutation MyMutation {
    deleteEventPreRegistration(input: {id: "${id}"}) {
      id
    }
  }
  `;
  return query;
};

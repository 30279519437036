import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EventTermsCondition = ({
  triggerSubmit,
  step,
  isSubmitForm,
  setIsSubmitForm,
  mainData,
  setMainData,
}) => {
  const [terms, setTerms] = useState(mainData.eventPolicy || "");
  useEffect(() => {
    if (isSubmitForm && step === 4) {
      triggerSubmit();
    }
    setIsSubmitForm(false);
  }, [isSubmitForm]);
  return (
    <>
      <div className="form_box_area_main event_information_form oflow-hd">
        <div className="form_title oflow-hd">
          <h2>Terms and Conditions</h2>
        </div>
        <div className="form_wrapper oflow-hd">
          <div className="row">
            <div className="col-12">
              <div className="single_input_box">
                <div className="single_input_box_editor">
                  {/* <textarea></textarea> */}
                  {mainData && (
                    <ReactQuill
                      value={terms}
                      onChange={(content, delta, source, editor) => {
                        setTerms(content);
                        setMainData({
                          ...mainData,
                          eventPolicy: content,
                        });
                      }}
                      placeholder="Write here...."
                      theme="snow" // Use snow theme
                      modules={{
                        toolbar: {
                          container: [
                            [{ header: [1, 2, 3, false] }],
                            ["bold", "italic", "underline", "strike"],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ["link", "image"],
                            ["clean"],
                          ],
                        },
                        clipboard: {
                          matchVisual: false,
                        },
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "color",
                        "background",
                        "align",
                        "link",
                        "image",
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventTermsCondition;

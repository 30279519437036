import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateUserByUserId } from "services/api-call";
import { setUser } from "store/slices/userSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const EditUser = ({ isEditUser, setIsEditUser, setRefetch }) => {
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.user.selectedUser);

  const handleClose = () => {
    setIsEditUser(false);
    dispatch(setUser(null));
  };

  const updateUser = () => {
    updateUserByUserId(
      selectedUser.id,
      selectedUser.userType,
      selectedUser.isApproved
    ).then((data) => {
      setIsEditUser(false);
      dispatch(setUser(null));
      setRefetch(true);
    });
  };
  return (
    <Modal
      open={isEditUser}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponentProps={{
        onClick: (event) => {
          event.preventDefault();
        },
      }}
    >
      <Box sx={style}>
        <div className="dash_content_main_center_with_padding addnewuserpage">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div
              style={{ padding: "5px", cursor: "pointer" }}
              onClick={handleClose}
            >
              <ClearIcon />
            </div>
          </div>
          <div className="form_wrapper oflow-hd">
            <div className="row">
              <div className="col-12">
                <div className="single_input_box">
                  <label htmlFor="#">Name</label>
                  <input type="text" value={selectedUser.fullName} readOnly />
                </div>
              </div>
              <div className="col-12">
                <div className="single_input_box">
                  <label htmlFor="#">Email Address</label>
                  <input type="text" value={selectedUser.email} readOnly />
                </div>
              </div>
              <div className="col-12">
                <div className="single_input_box">
                  <label htmlFor="#">Role</label>
                  <select
                    value={selectedUser.userType}
                    onChange={(e) => {
                      dispatch(
                        setUser({
                          ...selectedUser,
                          userType: e.target.value,
                        })
                      );
                    }}
                  >
                    <option value="Admin">Admin</option>
                    <option value="Moderator">Moderator</option>
                    <option value="Organizer">Organizer</option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="single_input_box">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={selectedUser.isApproved}
                            onChange={() =>
                              dispatch(
                                setUser({
                                  ...selectedUser,
                                  isApproved: !selectedUser.isApproved,
                                })
                              )
                            }
                          />
                        }
                        label="Is user active?"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <div
              className="addnewuserpage_btn oflow-hd"
              style={{ border: "none" }}
            >
              <Link
                to=""
                onClick={handleClose}
                style={{ backgroundColor: "red" }}
              >
                {/* <Link to="#" onClick={() => setAddUserRole(false)}> */}
                Cancel
              </Link>
            </div>
            <div className="addnewuserpage_btn oflow-hd">
              <Link to="#" onClick={updateUser}>
                Update User
              </Link>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EditUser;

import React, { useEffect, useState } from "react";
import EventBannerSingleItem from "./EventBannerSingleItem";
import { Link } from "react-router-dom";
import { GraphQLContentDelete } from "services/api-call";

const EventBannerComp = ({
  mainData,
  contentImageDatas,
  setContentImageDatas,
  contentImages,
  setContentImages,
  bannerImages,
  setBannerImages,
}) => {
  const [bannerImageList, setBannerImageList] = useState([]);
  const [isAddNewBanner, setIsAddNewBanner] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");

  useEffect(() => {
    if (contentImages.length > 0) {
      let newBannerList = contentImages?.filter((item) => {
        if (item.isSlider === true) {
          return item;
        }
      });
      setBannerImageList(newBannerList);
    }
  }, [contentImages]);
  useEffect(() => {
    if (isAddNewBanner) {
      let newArray = bannerImageList?.length > 0 ? bannerImageList : [];
      newArray.push({
        eventID: mainData.id,
        contentType: "image",
        contentUrl: "",
        isSlider: true,
        isMain: false,
        isActive: true,
      });
      setBannerImageList(newArray);
      setIsAddNewBanner(false);
    }
  }, [isAddNewBanner]);

  useEffect(() => {
    if (deleteIndex) {
      let selectedImage = bannerImageList[deleteIndex];
      GraphQLContentDelete(selectedImage?.id).then((data) => {
        let newArray = bannerImageList?.filter(
          (banner, index) => index !== parseInt(deleteIndex)
        );
        setBannerImageList(newArray);
        setDeleteIndex("");
      });
    }
  }, [deleteIndex]);
  return (
    <>
      <div className="col-12">
        <div className="single_input_box">
          <label htmlFor="avatar">Banner Images</label>
        </div>
        <div className="dash_main_main_table_form_up">
          {bannerImageList?.length > 0 &&
            bannerImageList.map(
              (currentImage, index) =>
                currentImage.isSlider && (
                  <div
                    key={index}
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: "7px" }}
                  >
                    <EventBannerSingleItem
                      index={index}
                      currentImage={currentImage}
                      mainData={mainData}
                      contentImageDatas={contentImageDatas}
                      setContentImageDatas={setContentImageDatas}
                      contentImages={contentImages}
                      setContentImages={setContentImages}
                    />
                    <Link
                      to=""
                      className="btn btn-danger py-2"
                      onClick={(e) => setDeleteIndex(`${index}`)}
                    >
                      Remove This
                    </Link>
                  </div>
                )
            )}
        </div>
        <ul>
          <li>
            <Link
              className="addMoreArtist"
              to=""
              onClick={() => setIsAddNewBanner(true)}
            >
              Add Banner Image +
            </Link>
          </li>
        </ul>
        <div className="single_input_box_des oflow-hd">
          <p>The size should be 1000x500 PX</p>
        </div>
      </div>
    </>
  );
};

export default EventBannerComp;

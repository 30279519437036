/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userName
      owner
      phoneNo
      events {
        nextToken
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      firstName
      lastName
      fullName
      userType
      email
      imageUrl
      gender
      nid
      dob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userName
      owner
      phoneNo
      events {
        nextToken
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      firstName
      lastName
      fullName
      userType
      email
      imageUrl
      gender
      nid
      dob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userName
      owner
      phoneNo
      events {
        nextToken
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      firstName
      lastName
      fullName
      userType
      email
      imageUrl
      gender
      nid
      dob
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMainSlider = /* GraphQL */ `
  mutation CreateMainSlider(
    $input: CreateMainSliderInput!
    $condition: ModelMainSliderConditionInput
  ) {
    createMainSlider(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      sliderName
      buttonName
      buttonUrl
      imageURL
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMainSlider = /* GraphQL */ `
  mutation UpdateMainSlider(
    $input: UpdateMainSliderInput!
    $condition: ModelMainSliderConditionInput
  ) {
    updateMainSlider(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      sliderName
      buttonName
      buttonUrl
      imageURL
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMainSlider = /* GraphQL */ `
  mutation DeleteMainSlider(
    $input: DeleteMainSliderInput!
    $condition: ModelMainSliderConditionInput
  ) {
    deleteMainSlider(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      sliderName
      buttonName
      buttonUrl
      imageURL
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      createdByID
      eventCategory
      organizerID
      organizer {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      eventSchedules {
        nextToken
        __typename
      }
      eventContents {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      eventSponsors {
        nextToken
        __typename
      }
      userInteresteStatus {
        nextToken
        __typename
      }
      ticketSaleMaster {
        nextToken
        __typename
      }
      title
      eventType
      isLocationVenueSame
      isSameArtist
      isUpComing
      isSameTicketForAllDay
      isSamePromotionForAllDay
      isSameCouponForAllDay
      minAge
      totalInterested
      eventWillBe
      eventStatus
      isActive
      eventPhoneNo
      eventSummery
      eventPolicy
      facebookUrl
      instaUrl
      tweeterUrl
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      hasReEntry
      isTicketActive
      ticketActivationDate
      ticketDeActivationDate
      isUserInfoRequiredForEach
      userRequiredInfo
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      createdByID
      eventCategory
      organizerID
      organizer {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      eventSchedules {
        nextToken
        __typename
      }
      eventContents {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      eventSponsors {
        nextToken
        __typename
      }
      userInteresteStatus {
        nextToken
        __typename
      }
      ticketSaleMaster {
        nextToken
        __typename
      }
      title
      eventType
      isLocationVenueSame
      isSameArtist
      isUpComing
      isSameTicketForAllDay
      isSamePromotionForAllDay
      isSameCouponForAllDay
      minAge
      totalInterested
      eventWillBe
      eventStatus
      isActive
      eventPhoneNo
      eventSummery
      eventPolicy
      facebookUrl
      instaUrl
      tweeterUrl
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      hasReEntry
      isTicketActive
      ticketActivationDate
      ticketDeActivationDate
      isUserInfoRequiredForEach
      userRequiredInfo
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      createdByID
      eventCategory
      organizerID
      organizer {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      eventSchedules {
        nextToken
        __typename
      }
      eventContents {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      eventSponsors {
        nextToken
        __typename
      }
      userInteresteStatus {
        nextToken
        __typename
      }
      ticketSaleMaster {
        nextToken
        __typename
      }
      title
      eventType
      isLocationVenueSame
      isSameArtist
      isUpComing
      isSameTicketForAllDay
      isSamePromotionForAllDay
      isSameCouponForAllDay
      minAge
      totalInterested
      eventWillBe
      eventStatus
      isActive
      eventPhoneNo
      eventSummery
      eventPolicy
      facebookUrl
      instaUrl
      tweeterUrl
      linkedinUrl
      maxTicketByUser
      maxTicketInSingleCheckout
      hasReEntry
      isTicketActive
      ticketActivationDate
      ticketDeActivationDate
      isUserInfoRequiredForEach
      userRequiredInfo
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEventContent = /* GraphQL */ `
  mutation CreateEventContent(
    $input: CreateEventContentInput!
    $condition: ModelEventContentConditionInput
  ) {
    createEventContent(input: $input, condition: $condition) {
      id
      eventID
      contentType
      contentUrl
      isSlider
      isMain
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEventContent = /* GraphQL */ `
  mutation UpdateEventContent(
    $input: UpdateEventContentInput!
    $condition: ModelEventContentConditionInput
  ) {
    updateEventContent(input: $input, condition: $condition) {
      id
      eventID
      contentType
      contentUrl
      isSlider
      isMain
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEventContent = /* GraphQL */ `
  mutation DeleteEventContent(
    $input: DeleteEventContentInput!
    $condition: ModelEventContentConditionInput
  ) {
    deleteEventContent(input: $input, condition: $condition) {
      id
      eventID
      contentType
      contentUrl
      isSlider
      isMain
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEventInterest = /* GraphQL */ `
  mutation CreateEventInterest(
    $input: CreateEventInterestInput!
    $condition: ModelEventInterestConditionInput
  ) {
    createEventInterest(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      UserID
      user {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEventInterest = /* GraphQL */ `
  mutation UpdateEventInterest(
    $input: UpdateEventInterestInput!
    $condition: ModelEventInterestConditionInput
  ) {
    updateEventInterest(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      UserID
      user {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEventInterest = /* GraphQL */ `
  mutation DeleteEventInterest(
    $input: DeleteEventInterestInput!
    $condition: ModelEventInterestConditionInput
  ) {
    deleteEventInterest(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      UserID
      user {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEventSchedule = /* GraphQL */ `
  mutation CreateEventSchedule(
    $input: CreateEventScheduleInput!
    $condition: ModelEventScheduleConditionInput
  ) {
    createEventSchedule(input: $input, condition: $condition) {
      id
      eventID
      tickets {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      eventDate
      scheduleTitle
      eventAgeGroup
      eventDuration
      hasReEntry
      eventStartTime
      gateOpenTime
      gateCloseTime
      eventCity
      location {
        lat
        lon
        __typename
      }
      address
      venue
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEventSchedule = /* GraphQL */ `
  mutation UpdateEventSchedule(
    $input: UpdateEventScheduleInput!
    $condition: ModelEventScheduleConditionInput
  ) {
    updateEventSchedule(input: $input, condition: $condition) {
      id
      eventID
      tickets {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      eventDate
      scheduleTitle
      eventAgeGroup
      eventDuration
      hasReEntry
      eventStartTime
      gateOpenTime
      gateCloseTime
      eventCity
      location {
        lat
        lon
        __typename
      }
      address
      venue
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEventSchedule = /* GraphQL */ `
  mutation DeleteEventSchedule(
    $input: DeleteEventScheduleInput!
    $condition: ModelEventScheduleConditionInput
  ) {
    deleteEventSchedule(input: $input, condition: $condition) {
      id
      eventID
      tickets {
        nextToken
        __typename
      }
      eventArtists {
        nextToken
        __typename
      }
      ticketPromotions {
        nextToken
        __typename
      }
      ticketCoupons {
        nextToken
        __typename
      }
      eventDate
      scheduleTitle
      eventAgeGroup
      eventDuration
      hasReEntry
      eventStartTime
      gateOpenTime
      gateCloseTime
      eventCity
      location {
        lat
        lon
        __typename
      }
      address
      venue
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEventArtist = /* GraphQL */ `
  mutation CreateEventArtist(
    $input: CreateEventArtistInput!
    $condition: ModelEventArtistConditionInput
  ) {
    createEventArtist(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      artistName
      artistTitle
      artistBio
      artistUrl
      artistImageUrl
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEventArtist = /* GraphQL */ `
  mutation UpdateEventArtist(
    $input: UpdateEventArtistInput!
    $condition: ModelEventArtistConditionInput
  ) {
    updateEventArtist(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      artistName
      artistTitle
      artistBio
      artistUrl
      artistImageUrl
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEventArtist = /* GraphQL */ `
  mutation DeleteEventArtist(
    $input: DeleteEventArtistInput!
    $condition: ModelEventArtistConditionInput
  ) {
    deleteEventArtist(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      artistName
      artistTitle
      artistBio
      artistUrl
      artistImageUrl
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEventSponsor = /* GraphQL */ `
  mutation CreateEventSponsor(
    $input: CreateEventSponsorInput!
    $condition: ModelEventSponsorConditionInput
  ) {
    createEventSponsor(input: $input, condition: $condition) {
      id
      eventID
      sponsorName
      sponsorType
      sponsorBio
      sponsorUrl
      sponsorImageUrl
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEventSponsor = /* GraphQL */ `
  mutation UpdateEventSponsor(
    $input: UpdateEventSponsorInput!
    $condition: ModelEventSponsorConditionInput
  ) {
    updateEventSponsor(input: $input, condition: $condition) {
      id
      eventID
      sponsorName
      sponsorType
      sponsorBio
      sponsorUrl
      sponsorImageUrl
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEventSponsor = /* GraphQL */ `
  mutation DeleteEventSponsor(
    $input: DeleteEventSponsorInput!
    $condition: ModelEventSponsorConditionInput
  ) {
    deleteEventSponsor(input: $input, condition: $condition) {
      id
      eventID
      sponsorName
      sponsorType
      sponsorBio
      sponsorUrl
      sponsorImageUrl
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      ticketType
      ticketSales {
        nextToken
        __typename
      }
      ticketPrice
      totalTicket
      totalSold
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      ticketType
      ticketSales {
        nextToken
        __typename
      }
      ticketPrice
      totalTicket
      totalSold
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      ticketType
      ticketSales {
        nextToken
        __typename
      }
      ticketPrice
      totalTicket
      totalSold
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTicketPromotion = /* GraphQL */ `
  mutation CreateTicketPromotion(
    $input: CreateTicketPromotionInput!
    $condition: ModelTicketPromotionConditionInput
  ) {
    createTicketPromotion(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      discountAmount
      ispersent
      isActive
      activationDate
      deActivationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTicketPromotion = /* GraphQL */ `
  mutation UpdateTicketPromotion(
    $input: UpdateTicketPromotionInput!
    $condition: ModelTicketPromotionConditionInput
  ) {
    updateTicketPromotion(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      discountAmount
      ispersent
      isActive
      activationDate
      deActivationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTicketPromotion = /* GraphQL */ `
  mutation DeleteTicketPromotion(
    $input: DeleteTicketPromotionInput!
    $condition: ModelTicketPromotionConditionInput
  ) {
    deleteTicketPromotion(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      discountAmount
      ispersent
      isActive
      activationDate
      deActivationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTicketCoupon = /* GraphQL */ `
  mutation CreateTicketCoupon(
    $input: CreateTicketCouponInput!
    $condition: ModelTicketCouponConditionInput
  ) {
    createTicketCoupon(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      discountAmount
      ispersent
      couponCode
      maxUsedbyUser
      totalUsed
      maxUsedInTotal
      isActive
      activationDate
      deActivationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTicketCoupon = /* GraphQL */ `
  mutation UpdateTicketCoupon(
    $input: UpdateTicketCouponInput!
    $condition: ModelTicketCouponConditionInput
  ) {
    updateTicketCoupon(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      discountAmount
      ispersent
      couponCode
      maxUsedbyUser
      totalUsed
      maxUsedInTotal
      isActive
      activationDate
      deActivationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTicketCoupon = /* GraphQL */ `
  mutation DeleteTicketCoupon(
    $input: DeleteTicketCouponInput!
    $condition: ModelTicketCouponConditionInput
  ) {
    deleteTicketCoupon(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      discountAmount
      ispersent
      couponCode
      maxUsedbyUser
      totalUsed
      maxUsedInTotal
      isActive
      activationDate
      deActivationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTicketUsedCoupon = /* GraphQL */ `
  mutation CreateTicketUsedCoupon(
    $input: CreateTicketUsedCouponInput!
    $condition: ModelTicketUsedCouponConditionInput
  ) {
    createTicketUsedCoupon(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      userID
      couponID
      ticketID
      ticketSaleID
      ticketSaleSingleID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTicketUsedCoupon = /* GraphQL */ `
  mutation UpdateTicketUsedCoupon(
    $input: UpdateTicketUsedCouponInput!
    $condition: ModelTicketUsedCouponConditionInput
  ) {
    updateTicketUsedCoupon(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      userID
      couponID
      ticketID
      ticketSaleID
      ticketSaleSingleID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTicketUsedCoupon = /* GraphQL */ `
  mutation DeleteTicketUsedCoupon(
    $input: DeleteTicketUsedCouponInput!
    $condition: ModelTicketUsedCouponConditionInput
  ) {
    deleteTicketUsedCoupon(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      userID
      couponID
      ticketID
      ticketSaleID
      ticketSaleSingleID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTicketSaleMaster = /* GraphQL */ `
  mutation CreateTicketSaleMaster(
    $input: CreateTicketSaleMasterInput!
    $condition: ModelTicketSaleMasterConditionInput
  ) {
    createTicketSaleMaster(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      eventScheduleID
      ticketSales {
        nextToken
        __typename
      }
      userID
      user {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      totalTicket
      totalPrice
      eventDate
      paymentMethod
      paymentData
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTicketSaleMaster = /* GraphQL */ `
  mutation UpdateTicketSaleMaster(
    $input: UpdateTicketSaleMasterInput!
    $condition: ModelTicketSaleMasterConditionInput
  ) {
    updateTicketSaleMaster(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      eventScheduleID
      ticketSales {
        nextToken
        __typename
      }
      userID
      user {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      totalTicket
      totalPrice
      eventDate
      paymentMethod
      paymentData
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTicketSaleMaster = /* GraphQL */ `
  mutation DeleteTicketSaleMaster(
    $input: DeleteTicketSaleMasterInput!
    $condition: ModelTicketSaleMasterConditionInput
  ) {
    deleteTicketSaleMaster(input: $input, condition: $condition) {
      id
      eventID
      event {
        id
        createdByID
        eventCategory
        organizerID
        title
        eventType
        isLocationVenueSame
        isSameArtist
        isUpComing
        isSameTicketForAllDay
        isSamePromotionForAllDay
        isSameCouponForAllDay
        minAge
        totalInterested
        eventWillBe
        eventStatus
        isActive
        eventPhoneNo
        eventSummery
        eventPolicy
        facebookUrl
        instaUrl
        tweeterUrl
        linkedinUrl
        maxTicketByUser
        maxTicketInSingleCheckout
        hasReEntry
        isTicketActive
        ticketActivationDate
        ticketDeActivationDate
        isUserInfoRequiredForEach
        userRequiredInfo
        createdAt
        updatedAt
        owner
        __typename
      }
      eventScheduleID
      ticketSales {
        nextToken
        __typename
      }
      userID
      user {
        id
        userName
        owner
        phoneNo
        firstName
        lastName
        fullName
        userType
        email
        imageUrl
        gender
        nid
        dob
        createdAt
        updatedAt
        __typename
      }
      totalTicket
      totalPrice
      eventDate
      paymentMethod
      paymentData
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTicketSale = /* GraphQL */ `
  mutation CreateTicketSale(
    $input: CreateTicketSaleInput!
    $condition: ModelTicketSaleConditionInput
  ) {
    createTicketSale(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      ticketSaleMasterID
      ticketSaleSingles {
        nextToken
        __typename
      }
      ticketID
      userID
      totalTicket
      totalPrice
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTicketSale = /* GraphQL */ `
  mutation UpdateTicketSale(
    $input: UpdateTicketSaleInput!
    $condition: ModelTicketSaleConditionInput
  ) {
    updateTicketSale(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      ticketSaleMasterID
      ticketSaleSingles {
        nextToken
        __typename
      }
      ticketID
      userID
      totalTicket
      totalPrice
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTicketSale = /* GraphQL */ `
  mutation DeleteTicketSale(
    $input: DeleteTicketSaleInput!
    $condition: ModelTicketSaleConditionInput
  ) {
    deleteTicketSale(input: $input, condition: $condition) {
      id
      eventID
      eventScheduleID
      ticketSaleMasterID
      ticketSaleSingles {
        nextToken
        __typename
      }
      ticketID
      userID
      totalTicket
      totalPrice
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTicketSaleSingle = /* GraphQL */ `
  mutation CreateTicketSaleSingle(
    $input: CreateTicketSaleSingleInput!
    $condition: ModelTicketSaleSingleConditionInput
  ) {
    createTicketSaleSingle(input: $input, condition: $condition) {
      id
      eventID
      ticketID
      userID
      ticketSaleID
      ticketCode
      ticketUser
      ticketPrice
      isEntryDone
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTicketSaleSingle = /* GraphQL */ `
  mutation UpdateTicketSaleSingle(
    $input: UpdateTicketSaleSingleInput!
    $condition: ModelTicketSaleSingleConditionInput
  ) {
    updateTicketSaleSingle(input: $input, condition: $condition) {
      id
      eventID
      ticketID
      userID
      ticketSaleID
      ticketCode
      ticketUser
      ticketPrice
      isEntryDone
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTicketSaleSingle = /* GraphQL */ `
  mutation DeleteTicketSaleSingle(
    $input: DeleteTicketSaleSingleInput!
    $condition: ModelTicketSaleSingleConditionInput
  ) {
    deleteTicketSaleSingle(input: $input, condition: $condition) {
      id
      eventID
      ticketID
      userID
      ticketSaleID
      ticketCode
      ticketUser
      ticketPrice
      isEntryDone
      isActive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const ticketSaleMutation = /* GraphQL */ `
  mutation TicketSaleMutation(
    $mode: String!
    $ticketSaleData: AWSJSON
    $ticketSaleSingleData: AWSJSON
    $otherData: AWSJSON
    $coupon: AWSJSON
  ) {
    ticketSaleMutation(
      mode: $mode
      ticketSaleData: $ticketSaleData
      ticketSaleSingleData: $ticketSaleSingleData
      otherData: $otherData
      coupon: $coupon
    )
  }
`;
export const ticketEventMutation = /* GraphQL */ `
  mutation TicketEventMutation($mode: String!, $eventData: AWSJSON) {
    ticketEventMutation(mode: $mode, eventData: $eventData)
  }
`;
export const ticketTicketMutation = /* GraphQL */ `
  mutation TicketTicketMutation($mode: String!, $ticketData: AWSJSON) {
    ticketTicketMutation(mode: $mode, ticketData: $ticketData)
  }
`;
export const ticketTicketGatePass = /* GraphQL */ `
  mutation TicketTicketGatePass($mode: String!, $ticketCode: String!) {
    ticketTicketGatePass(mode: $mode, ticketCode: $ticketCode)
  }
`;

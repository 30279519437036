// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project import
import auth from "./auth";
import calendar from "./calendar";
import cartReducer from "./cart";
import chat from "./chat";
import menu from "./menu";
import productReducer from "./product";
import snackbar from "./snackbar";

import counter from "../slices/testRSlice";
import companyInfo from "./companyInfo";
import eventData from "./eventData";
import preRegister from "./preRegister";
import user from "./userSlice";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth,
  chat,
  calendar,
  menu,
  snackbar,
  counter,
  eventData,
  user,
  companyInfo,
  preRegister,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "mantis-ts-",
    },
    cartReducer
  ),
  product: productReducer,
});

export default reducers;

import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useRef, useState } from "react";
import EventThumbnailComp from "./EventThumbnailComp";
import EventBannerComp from "./EventBannerComp";
import EventSponsorsComp from "./EventSponsorsComp";
import EventSummery from "./EventSummery";

const EventContent = ({
  triggerSubmit,
  step,
  isSubmitForm,
  setStep,
  setIsSubmitForm,
  mainData,
  setMainData,
  currentUser,
  eventDuration,
  artistList,
  setArtistList,
  schedules,
  setSchedules,
  thumbnailContent,
  setThumbnailContent,
  contentImageDatas,
  setContentImageDatas,
  contentImages,
  setContentImages,
  eventSponsors,
  setEventSponsors,
  sponsorsImages,
  setSponsorsImages,
}) => {
  const editorRef = useRef(null);
  useEffect(() => {
    if (isSubmitForm && step === 3) {
      triggerSubmit();
    }
    setIsSubmitForm(false);
  }, [isSubmitForm]);
  return (
    <>
      <div className="form_box_area_main event_information_form oflow-hd">
        <div className="form_title oflow-hd">
          <h2>Contents</h2>
        </div>
        <div className="form_wrapper oflow-hd">
          <div className="row">
            <EventThumbnailComp
              mainData={mainData}
              thumbnailContent={thumbnailContent}
              setThumbnailContent={setThumbnailContent}
              contentImageDatas={contentImageDatas}
              setContentImageDatas={setContentImageDatas}
              contentImages={contentImages}
              setContentImages={setContentImages}
            />
            <EventBannerComp
              mainData={mainData}
              contentImageDatas={contentImageDatas}
              setContentImageDatas={setContentImageDatas}
              contentImages={contentImages}
              setContentImages={setContentImages}
            />

            <div className="col-12">
              <div className="single_input_box">
                <label htmlFor="#">Event Summary</label>
                <div className="single_input_box_editor">
                  <EventSummery mainData={mainData} setMainData={setMainData} />
                </div>
              </div>
            </div>
            {mainData.eventCategory === "featuredEvents" && (
              <EventSponsorsComp
                mainData={mainData}
                eventSponsors={eventSponsors}
                setEventSponsors={setEventSponsors}
                sponsorsImages={sponsorsImages}
                setSponsorsImages={setSponsorsImages}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventContent;

import { lazy } from "react";
import { useRoutes } from "react-router-dom";

// project import
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import ComponentsRoutes from "./ComponentsRoutes";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// render - landing page
const PagesLanding = Loadable(lazy(() => import("pages/landing")));
const DashboardDefault = Loadable(
  lazy(() => import("pages/dashboard/default"))
);

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: "/",
      children: [
        {
          path: "/",
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: "/",
              children: [
                {
                  path: "default",
                  element: <DashboardDefault />,
                },
              ],
            },
          ],
        },
      ],
    },
    LoginRoutes,
    ComponentsRoutes,
    MainRoutes,
  ]);
}

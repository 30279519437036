import React, { useEffect, useState } from "react";
import EventMultiScheduleMultiVenueItem from "./EventMultiScheduleMultiVenueItem";

const EventMultiScheduleMultiVenue = ({
  schedules,
  setSchedules,
  isSubmitForm,
  setIsSubmitForm,
  isMultiEventVenueEmpty,
  setIsMultiEventVenueEmpty,
}) => {
  const handleChangeData = (scheduleId, dataObj) => {
    const newArray = schedules.map((item) => {
      if (item.id === scheduleId) {
        return {
          ...item,
          ...dataObj,
          location: {
            ...item.location,
            ...dataObj.location,
          },
        };
      } else {
        return item;
      }
    });
    setSchedules(newArray);
  };
  return (
    <>
      <div className="multifieldrow_wrapper ec_location_venue ec_location_venue2">
        {schedules.map((schedule, index) => (
          <EventMultiScheduleMultiVenueItem
            key={index}
            index={index}
            schedule={schedule}
            schedules={schedules}
            setSchedules={setSchedules}
            isSubmitForm={isSubmitForm}
            handleChangeData={handleChangeData}
            isMultiEventVenueEmpty={isMultiEventVenueEmpty}
            setIsMultiEventVenueEmpty={setIsMultiEventVenueEmpty}
            setIsSubmitForm={setIsSubmitForm}
          />
        ))}
      </div>
    </>
  );
};

export default EventMultiScheduleMultiVenue;

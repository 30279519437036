import PropTypes from "prop-types";
import { createContext, Fragment, useEffect, useState } from "react";

// third-party
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

// action - state management
// import { LOGIN, LOGOUT } from 'store/slices/actions';
// import authReducer from 'store/slices/auth';
// project imports
import Loader from "components/Loader";
import { Auth } from "aws-amplify";
import { loginReducer, logoutReducer } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { userInfo } from "../_api/user";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Typography,
} from "@mui/material";

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
  } else {
    localStorage.removeItem("serviceToken");
  }
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //

const AWSCognitoContext = createContext(null);

export const AWSCognitoProvider = ({ children }) => {
  // const [state, dispatch] = useReducer(authReducer, initialState);
  const dispatch = useDispatch();
  const [users, setUsers] = useState();
  const [access, setAccess] = useState(true);

  const state = useSelector((state) => state.auth);

  useEffect(() => {
    const init = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const serviceToken = user?.signInUserSession?.accessToken?.jwtToken;
        if (serviceToken) {
          setSession(serviceToken);

          const userData = await userInfo(user.username);
          setUsers(userData.data.getUser);
          // if (user) {
          if (userData.data.getUser.userType !== "Admin") {
            if (
              userData.data.getUser.userType === "Organizer" ||
              userData.data.getUser.userType === "Modarator"
            ) {
              if (userData.data.getUser.isApproved) {
                setAccess(true);
              } else {
                setAccess(false);
              }
            } else {
              setAccess(false);
            }
          } else {
            setAccess(true);
          }
          // } else {
          //   setAccess(true);
          // }

          dispatch(
            loginReducer({
              isLoggedIn: true,
              isInitialized: true,
              user: userData.data.getUser,
            })
          );
        } else {
          setAccess(true);
          dispatch(logoutReducer());
        }
      } catch (err) {
        console.error(err);
        dispatch(logoutReducer());
      }
    };

    init();
  }, [state.isLoggedIn]);

  const login = async (email, password) => {
    const usr = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authData = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    usr.authenticateUser(authData, {
      onSuccess: (session) => {
        setSession(session.getAccessToken().getJwtToken());

        dispatch(
          loginReducer({
            isLoggedIn: true,
            isInitialized: true,
            user: users,
          })
        );

        // dispatch({
        //   type: LOGIN,
        //   payload: {
        //     isLoggedIn: true,
        //     user: {
        //       email: authData.getUsername(),
        //       name: 'John AWS'
        //     }
        //   }
        // });
      },
      onFailure: () => {},
      newPasswordRequired: () => {
        // // User was signed up by an admin and must provide new
        // // password and required attributes, if any, to complete
        // // authentication.
        // // the api doesn't accept this field back
        // delete userAttributes.email_verified;
        // // unsure about this field, but I don't send this back
        // delete userAttributes.phone_number_verified;
        // // Get these details and call
        // usr.completeNewPasswordChallenge(password, userAttributes, requiredAttributes);
      },
    });
  };

  const register = (email, password, firstName, lastName) =>
    new Promise((success, rej) => {
      userPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: "email", Value: email }),
          new CognitoUserAttribute({
            Name: "name",
            Value: `${firstName} ${lastName}`,
          }),
        ],
        [],
        async (err, result) => {
          if (err) {
            rej(err);
            return;
          }
          success(result);
        }
      );
    });

  const logout = () => {
    const loggedInUser = userPool.getCurrentUser();
    if (loggedInUser) {
      setSession(null);
      loggedInUser.signOut();
      dispatch(logoutReducer());
    }
  };

  const resetPassword = async () => {};
  const updateProfile = () => {};
  // let state = {}
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }
  function welcomePage() {
    return (
      <Fragment>
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card sx={{ minHeight: "25vh" }}>
              <CardContent>
                <Typography
                  textAlign={"center"}
                  gutterBottom
                  variant="h3"
                  component="div"
                >
                  Welcome aboard!
                </Typography>
                <Typography textAlign={"center"} variant="h5">
                  Your registration is currently under review. Once approved,
                  you'll gain access to our platform.
                </Typography>
                <Typography textAlign={"center"} variant="h6">
                  Stay tuned for updates!
                </Typography>
                <Typography
                  textAlign={"center"}
                  sx={{ mt: 2 }}
                  gutterBottom
                  variant="body1"
                  component="div"
                >
                  For any queries, call: +8801724723612.
                </Typography>
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    Auth.signOut();
                    dispatch(logoutReducer());
                    setAccess(true);
                  }}
                >
                  Logout
                </Button>{" "}
                to login to another account
              </CardActions>
            </Card>
          </Box>
        </Container>
      </Fragment>
    );
  }
  return (
    <AWSCognitoContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {access ? children : welcomePage()}
    </AWSCognitoContext.Provider>
  );
};

AWSCognitoProvider.propTypes = {
  children: PropTypes.node,
};
0;

export default AWSCognitoContext;

import React, { useEffect, useState } from "react";
import { v4 } from "uuid";

const EventThumbnailComp = ({
  mainData,
  thumbnailContent,
  setThumbnailContent,
  contentImageDatas,
  setContentImageDatas,
  contentImages,
  setContentImages,
}) => {
  const [addArtist, setAddArtist] = useState(true);
  const [selectedImage, setSelectedImage] = useState({
    name: "",
    data: "",
  });
  const s3BucketUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const clearFileInput = () => {
    const fileInput = document.getElementById("artistAvatar");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      let fileName = file.name.split(".")[0];
      let ext = file.name.split(".")[1];
      fileName = v4();

      reader.onload = () => {
        const imageData = reader.result;
        setThumbnailContent({
          ...thumbnailContent,
          contentUrl: `${fileName}.${ext}`,
          eventID: mainData.id,
        });
        setSelectedImage({
          name: `${fileName}.${ext}`,
          data: imageData,
          file: file,
        });
      };

      reader.readAsDataURL(file);
    } else {
      console.error("Invalid file type. Please select an image.");
    }
  };

  useEffect(() => {
    if (selectedImage?.name) {
      setContentImageDatas((prevData) => [...prevData, selectedImage]);
    }
  }, [selectedImage]);
  return (
    <>
      {thumbnailContent?.id ? (
        <div className="col-12">
          <div className="single_input_box">
            <label htmlFor="#">Event Thumbnail Image</label>
          </div>
          <div className="dash_main_main_table_form_up">
            <img src={`${s3BucketUrl}${thumbnailContent.contentUrl}`} />
          </div>
        </div>
      ) : (
        <div className="col-12">
          <div className="single_input_box">
            <label htmlFor="#">Event Thumbnail Image</label>
          </div>
          <div className="dash_main_main_table_form_up">
            <input
              type="file"
              id="artistAvatar"
              name="cf_photo"
              onDrop={handleDrop}
              onDragOver={handleDrop}
              onChange={handleDrop}
              accept="image/*"
            />
          </div>
          <div className="single_input_box_des oflow-hd">
            <p>The size should be 1000x500 PX</p>
          </div>
        </div>
      )}
    </>
  );
};

export default EventThumbnailComp;

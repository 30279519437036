import React from "react";
import SingleTicketCom from "./SingleTicketCom";

const SingleTicket = ({ schedule, index, tickets }) => {
  return (
    <>
      <div className="form_left_col_ticket_wrapper_days_single oflow-hd">
        <h2>
          <span>Day {index + 1}</span>
        </h2>
        <div className="preview_s_single_ticket_box_wrapper oflow-hd">
          {tickets.length > 0 &&
            tickets.map((ticket, index) => (
              <>
                <SingleTicketCom key={index} ticket={ticket} />
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default SingleTicket;

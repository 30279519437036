// import { PDFViewer } from "@react-pdf/renderer";
import CustomButton from "components/shared/Button";
// import MyDocument from "components/shared/MyDocument";
import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GraphQLGetTicketByEventId, ticketDetails } from "services/api-call";
import { S3BucketFileList } from "services/s3BucketFIleList";
import SinglePDFItem from "./SinglePDFItem";

const sortByTimeDesc = (a, b) => {
  const timeA = a.Key.split("/")[2].split("#")[1];
  const timeB = b.Key.split("/")[2].split("#")[1];
  return timeB.localeCompare(timeA);
};

// const ref = createRef();

const PrintTicketContainer = ({
  selectedEvent,
  selectedSchedule,
  selectedTicket,
  setDisplayTickets,
}) => {
  const [ticketSales, setTicketSales] = useState([]);
  const [nextToken, setNextToken] = useState("");
  const [searchedEmail, setSearchedEmail] = useState(null);
  const [searchedUser, setSearchedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPdfList, setIsLoadingPdfList] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [pdfList, setPdfList] = useState([]);

  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    // GraphQLGetTicketByEventId(selectedEvent?.id).then((ticket) => {

    // });
    ticketDetails(20, selectedEvent?.id, "").then((r) => {
      setTicketSales(r?.data?.byEventIDUserIDTicketSaleSingle?.items);
      // setTicketSales(
      //   arrayModifier(r?.data?.byEventIDUserIDTicketSaleSingle?.items)
      // );
      setNextToken(r?.data?.byEventIDUserIDTicketSaleSingle?.nextToken);
    });
  }, [selectedEvent]);
  // const handleSearch = (email) => {
  //   setSearchedEmail(email);
  //   // Perform search action with the provided email
  //   userDetailsByEmail(email).then((r) => {
  //     let user = r?.data?.byEmailUser?.items[0];
  //     setSearchedUser(r?.data?.byEmailUser?.items[0]);
  //     ticketDetails(0, selectedEvent?.id, user?.id, "").then((eventData) => {
  //       setTicketSales(eventData?.data?.byEventIDUserIDTicketSaleSingle?.items);
  //     });
  //   });
  // };

  const getPDFList = () => {
    S3BucketFileList(selectedEvent?.id)
      .then((data) => {
        // if (data === undefined) getPDFList();
        let newPdfList = data?.sort(sortByTimeDesc);
        newPdfList = newPdfList.filter(
          (pdf) =>
            pdf.Key.split("/")[2].split("#")[2].split(".")[0] ===
            selectedTicket.ticketType
        );
        setPdfList(newPdfList);
        setShowMessage(false);
        setIsLoadingPdfList(false);
      })
      .catch((error) => {
        console.error("error test: ", error);
      });
  };

  const downloadPdf = () => {
    setIsLoading(true);
    GraphQLGetTicketByEventId(selectedEvent?.id, user.id, selectedTicket)
      .then((ticket) => {
        getPDFList();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("error: ", err);
        if (err.errors[0].errorType === "Lambda:ExecutionTimeoutException")
          setShowMessage(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPDFList();
  }, [selectedEvent?.id]);

  return (
    <>
      <div className="dash_content_main_head oflow-hd">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div className="backbtnwithtxt oflow-hd">
              <Button variant="text" onClick={() => setDisplayTickets(false)}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.50004 12C8.4284 12.0001 8.35745 11.986 8.29127 11.9586C8.22509 11.9311 8.16499 11.8909 8.1144 11.8402L2.65987 6.38565C2.60919 6.33503 2.56898 6.27492 2.54155 6.20875C2.51412 6.14258 2.5 6.07165 2.5 6.00002C2.5 5.92839 2.51412 5.85746 2.54155 5.79129C2.56898 5.72512 2.60919 5.665 2.65987 5.61438L8.1144 0.159852C8.32754 -0.0532839 8.67267 -0.0532839 8.88567 0.159852C9.09867 0.372988 9.09881 0.718123 8.88567 0.931122L3.81678 6.00002L8.88567 11.0689C8.93635 11.1195 8.97656 11.1796 9.00399 11.2458C9.03142 11.312 9.04554 11.3829 9.04554 11.4545C9.04554 11.5262 9.03142 11.5971 9.00399 11.6633C8.97656 11.7294 8.93635 11.7896 8.88567 11.8402C8.83509 11.8909 8.77498 11.9311 8.7088 11.9586C8.64262 11.986 8.57167 12.0001 8.50004 12Z"
                    fill="black"
                  />
                </svg>
                <span>Back</span>
              </Button>
            </div>
            <h2>Ticket Report</h2>
          </div>
          {/* <div>
            <SearchByEmail onSearch={handleSearch} />
          </div> */}
          <CustomButton
            customVariant="primaryContained"
            onClick={downloadPdf}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <CircularProgress size={15} /> Generating...
              </>
            ) : (
              <>Generate PDF</>
            )}
          </CustomButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ margin: "15px 0" }}>
          <h3>
            Event Title: <span>{selectedEvent.title}</span>
          </h3>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {showMessage && (
            <p style={{ color: "#bb2124" }}>
              Generating pdf may take time. Please refresh after some time
            </p>
          )}
          <CustomButton
            customVariant="successContained"
            onClick={() => {
              setIsLoadingPdfList(true);
              getPDFList();
            }}
            disabled={isLoadingPdfList}
          >
            {isLoadingPdfList ? (
              <>
                <CircularProgress size={15} /> Fetching List...
              </>
            ) : (
              <>Refresh PDF List</>
            )}
          </CustomButton>
        </div>
      </div>
      {pdfList?.length > 0 ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            gap: "10px",
          }}
        >
          {pdfList?.map((pdf, index) => (
            <SinglePDFItem
              key={index}
              pdf={pdf}
              selectedEvent={selectedEvent}
              getPDFList={getPDFList}
            />
          ))}
        </div>
      ) : (
        "No pdf found"
      )}

      {/* <div className="ticketContainer" ref={ref}>
        {ticketSales?.length > 0 &&
          ticketSales?.map((ticket, index) => (
            <PrintTicketSingle
              key={index}
              selectedEvent={selectedEvent}
              selectedSchedule={selectedSchedule}
              selectedTicket={selectedTicket}
              ticket={ticket}
              index={index}
            />
          ))}
      </div> */}
    </>
  );
};

export default PrintTicketContainer;

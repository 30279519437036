import moment from "moment";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { GraphQLGetArtistListByEventId } from "services/api-call";
import { sortDates } from "services/sortDate";
import { v4 as uuidv4 } from "uuid";
import EventConfigFirstStep from "./EventConfigFirstStep";
import EventConfigSecondStep from "./EventConfigSecondStep";

const EventConfig = ({
  triggerSubmit,
  step,
  isSubmitForm,
  setStep,
  setIsSubmitForm,
  mainData,
  setMainData,
  currentUser,
  eventDuration,
  artistList,
  setArtistList,
  schedules,
  setSchedules,
  artistImages,
  setArtistImages,
  sameScheduleData,
  setSameScheduleData,
  isMultiEventVenueEmpty,
  setIsMultiEventVenueEmpty,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [multiDaysErrors, setMultiDaysErrors] = useState([]);
  const [isSameArtistForMultSchedule, setIsSameArtistForMultSchedule] =
    useState("YesSame");
  const [isSameArtistSwitchable, setIsSameArtistSwitchable] = useState(true);

  useEffect(() => {
    if (mainData.id) {
      GraphQLGetArtistListByEventId(mainData.id).then((data) => {
        if (data?.data?.byEventIDSortOrderArtists?.items?.length > 0) {
          setArtistList(data?.data?.byEventIDSortOrderArtists?.items);
          setIsSameArtistSwitchable(false);
        }
      });
    }
  }, [mainData.id]);

  useEffect(() => {
    if (eventDuration && schedules) {
      const newData = [...schedules];
      for (let i = schedules.length; i < parseInt(eventDuration, 10); i++) {
        let newSchedule = {
          id: uuidv4(),
          eventID: mainData.id,
          eventDate: moment(new Date()).add(i, "days").format("YYYY-MM-DD"),
          scheduleTitle: `Day ${i + 1}`,
          eventAgeGroup: "",
          eventDuration: `${eventDuration}`,
          eventStartTime: moment(new Date()).add(i, "days").format("HH:mm"),
          gateOpenTime: "",
          gateCloseTime: "",
          eventCity: "",
          location: {
            lat: 23.804193153061725,
            lon: 90.36392211914062,
          },
          address: "",
          venue: "",
          isActive: true,
        };

        newData.push(newSchedule);
      }
      setSchedules(newData);
    }
  }, [eventDuration]);

  useEffect(() => {
    if (isMultiEventVenueEmpty) {
      setIsSubmitForm(false);
      return;
    } else {
      if (isSubmitForm && step === 2) {
        triggerSubmit();
      }
      setIsSubmitForm(false);
    }
  }, [isSubmitForm]);

  useEffect(() => {
    if (schedules.length > 1) {
      let newData = sortDates(schedules);
      setSchedules(newData);
    }
  }, [schedules]);

  return (
    <>
      {schedules?.length > 1 ? (
        <EventConfigSecondStep
          setIsSubmitForm={setIsSubmitForm}
          isSubmitForm={isSubmitForm}
          schedules={schedules}
          setSchedules={setSchedules}
          step={step}
          triggerSubmit={triggerSubmit}
          mainData={mainData}
          setMainData={setMainData}
          artistList={artistList}
          setArtistList={setArtistList}
          artistImages={artistImages}
          setArtistImages={setArtistImages}
          multiDaysErrors={multiDaysErrors}
          setMultiDaysErrors={setMultiDaysErrors}
          isSameArtistForMultSchedule={isSameArtistForMultSchedule}
          setIsSameArtistForMultSchedule={setIsSameArtistForMultSchedule}
          isSameArtistSwitchable={isSameArtistSwitchable}
          sameScheduleData={sameScheduleData}
          setSameScheduleData={setSameScheduleData}
          isMultiEventVenueEmpty={isMultiEventVenueEmpty}
          setIsMultiEventVenueEmpty={setIsMultiEventVenueEmpty}
        />
      ) : (
        schedules?.length > 0 &&
        schedules.map((schedule, index) => (
          <EventConfigFirstStep
            key={index}
            setIsSubmitForm={setIsSubmitForm}
            isSubmitForm={isSubmitForm}
            step={step}
            triggerSubmit={triggerSubmit}
            schedule={schedule}
            schedules={schedules}
            setSchedules={setSchedules}
            mainData={mainData}
            setMainData={setMainData}
            artistList={artistList}
            setArtistList={setArtistList}
            artistImages={artistImages}
            setArtistImages={setArtistImages}
          />
        ))
      )}
    </>
  );
};

export default EventConfig;
